@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css');

:root{
    --primary__color: #0077C7;
    --black__color: #000;
    --white_color: #FFF;
    --text_color: #707070;
    --blue_color: #012F4E;
    --lightblue_color: #0078C8;
    --lightgrey_color: #777777;
}
*{font-family: 'Poppins';}
/* width */
::-webkit-scrollbar{width: 5px;}
/* .login-page{background: linear-gradient(270deg, rgba(1,106,183,1) 0%, rgba(0,69,133,1) 100%) !important;} */
.img-block{display: none;}
/* Track */
::-webkit-scrollbar-track{background: #F2F2F280;}
/* Handle */
::-webkit-scrollbar-thumb{background: var(--black__color); cursor: pointer;}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover{background: var(--black__color);}
.pl-6{padding-left: 60px !important;}
.ant-form-item-label>label, .ant-table{color: var(--black__color) !important;}
.justify-center{justify-content: center;}
.align-self{align-self: center;}
/* body{overflow: hidden;} */
body input:focus, body textarea:focus, body select:focus, body button:focus{outline: none !important; box-shadow: none !important; border-color: var(--text_color) !important;}
body a{text-decoration: none; color: var(--text_color);}
body a:hover{color: var(--blue_color);}
body{background: var(--white_color);}
body .ant-col{padding-left: 12px; padding-right: 12px;}
body form .form-group{margin-bottom: 20px;}
body form .form-control{border: 3px solid var(--text_color); border-radius: 30px; text-align: left; letter-spacing: 0px; color: var(--text_color); padding: 15px 40px; font-size: 16px; line-height: 1;}
body .login-img{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box; height: 100vh; display: flex; align-items: flex-end;}
body .login-btn{margin-top: 40px;}
body .login-img img{margin: 0 0 0 11%; max-width: 100%;}
.ant-btn.linkButton:hover{color: var(--blue_color);}
body .login-form{display: flex; flex-direction: column; max-width: 396px; margin: auto; align-items: center; justify-content: center; height: auto;}
body .login-form .password{position: relative;}
body .login-form .password .icon{position: absolute; cursor: pointer; right: 40px; top: 20px; font-size: 20px;}
body .login-pages{margin: 0 0 0 -12px;}
body .login-pages>.row{margin: 0;}
body .login-form h1{margin-top: auto; margin-bottom: 92px; font-size: 60px; letter-spacing: 0px; line-height: 1; position: relative; color: #404756; font-weight: 600;}
body .login-form>img{margin-top: auto; margin-bottom: 40px;}
body .footer-link{margin-top: 30px; margin-bottom: auto; display: flex; margin: 30px auto auto; align-items: center; flex-direction: column; justify-content: center; max-width: 70%; bottom: 30px; position: absolute; text-align: center;}
.cms-page .footer-link{padding-top: 0; margin-bottom: 0; position: fixed; bottom: 30px; width: 100%; background: var(--white_color);}
body .footer-link a{font-size: 16px; line-height: 26px;}
/* body .footer-link a:after{content: '|'; margin: 0 5px;} */
body .footer-link a:last-child:after{display: none;}
.sitelogo{display: flex; align-items: flex-end; grid-gap: 5px; color: var(--white_color);}
.login-form .sitelogo{color: var(--primary__color); font-size: 15px; font-weight: 500; margin-bottom: 20px;}
.login-form .sitelogo img{width: 300px; filter: brightness(100);}
.login-form .sitelogo span{padding-bottom: 13px; line-height: 1; color: var(--white_color);}
body .login-form h1:before{width: 118px; height: 118px; background: #dedede; border-radius: 50%; position: absolute; content: ''; left: -30px; transform: translateY(-50%); top: 50%; z-index: -1;}
body .login-form-block{background: linear-gradient(270deg, rgba(1,106,183,1) 0%, rgba(0,69,133,1) 100%); display: flex; justify-content: center; flex-direction: column; align-items: center; min-width: 54%; height: 100vh; color: var(--white_color);}
body .login-form-block p a{color: var(--white_color);}
body .login-form-block button[type=submit]{background: #032c5d; border: #FFF 2px solid; min-width: inherit; font: normal normal normal 18px/27px Poppins; padding: 0; min-width: 143px; height: 51px; min-height: inherit; text-transform: none;}
body .login-form-block .linkButton{color: #FFF !important;}
body header{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; width: 100%; display: flex; padding: 0 15px; min-height: 65px; align-items: center; z-index: 2; position: relative;}
body header img{height: 50px; filter: brightness(100);}
@media(min-width: 992px){
    body header .header-nav{margin: 0 auto; padding: 0; display: flex; align-items: center; grid-gap: 47px; position: absolute; margin: 0; left: 50%; transform: translateX(-50%);}
    body header .header-nav li{list-style: none; font-size: 18px; color: var(--white_color);}
    body header .header-nav li .dropdown{display: flex; align-items: center;}
    body header .header-nav li a{font-size: 18px; color: var(--white_color);}
    body header .header-nav li a:hover{opacity: 0.8;}
    body header .header-nav li ul{display: none;}
    body header .header-nav li .dropdown-toggle{min-width: inherit; height: auto; padding: 0; font-size: 18px; text-transform: none; background: none !important; box-shadow: none !important;}
    body header .header-nav li .dropdown-toggle:after{width: 0; height: 0; border-left: 5px solid transparent; border-right: 5px solid transparent; content: ''; border-top: 5px solid #FFF; position: absolute; top: 50%; right: -15px;}
    body header .header-nav li .dropdown-menu{background: var(--primary__color); border: none; border-radius: 0 0 10px 10px; box-shadow: none; padding: 0; top: 45px;}
    body header .header-nav li .dropdown-menu a{font-size: 14px; border-bottom: #FFFFFF20 1px solid; padding: 5px 10px;}
    body header .header-nav li .dropdown-menu a:last-child{border: none;}
    body header .header-nav li .dropdown-menu a:hover{background: none; color: var(--white_color);}
}
body header h1, .info-page .info-logo h1{font-size: 25px; color: var(--white_color); font-weight: 600; margin: 0; position: relative; padding-left: 15px;}
.info-page .info-logo{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; padding: 8px 0; text-align: center;}
.info-page .info-logo img{text-align: center; z-index: 1; height: 50px; filter: brightness(100);}
body header h1:before, .info-page .info-logo h1:before{width: 49px; height: 49px; background: #B5AFAF; border-radius: 50%; position: absolute; content: ''; left: 0; transform: translateY(-50%); top: 50%; z-index: -1;}
.info-page .info-logo h1:before{left: 50%; transform: translate(-50%, -50%); margin-left: -20px; background: #001727;}
body header .userprofile{margin-left: auto; display: flex; align-items: center; min-height: 65px;}
body header .userprofile .ant-image{background: var(--white_color); width: 49px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-right: 5px;}
body header .userprofile img{height: 49px; filter: none; border-radius: 50%; width: auto; margin-right: 5px;}
body header .userprofile .ant-image-mask{display: none;}
body header .userprofile .btn{min-width: 120px; /*background: url(../images/user-icon.png) no-repeat right center !important;*/ background: none; box-shadow: none !important; height: auto; min-height: inherit; border-radius: 0; font-size: 14px; padding: 0 10px; display: flex; align-items: center; border: none;}
body header .userprofile .btn[aria-expanded="true"]{background: transparent; display: flex !important;}
body header .userprofile .btn span{background: var(--white_color); font-size: 36px; font-weight: 500; width: 49px; height: 49px; min-width: 49px; border-radius: 50%; color: var(--primary__color); text-transform: uppercase; display: flex; padding: 0; margin-right: 8px; line-height: 49px; font-weight: 700; text-align: center; align-items: center; justify-content: center;}
body header .userprofile .btn:after{float: right; margin-right: 0; font-size: 18px; position: absolute; right: 10px; top: 50%; transform: translateY(-50%);}
body header .userprofile .dropdown{display: flex; min-height: 65px;}
body header .userprofile .show.dropdown{background: var(--primary__color);}
body header .userprofile .dropdown-menu{background: var(--primary__color); border: 1px solid var(--primary__color); border-radius: 0 0 10px 10px; box-shadow: none; padding: 5px 10px; min-width: inherit; width: 100%; margin-top: -2px; text-align: center; color: var(--white_color); font-size: 14px;}
body header .userprofile .dropdown-menu p{margin: 0 -10px; border-bottom: rgba(255, 255, 255, 0.1) 1px solid; padding: 0 5px 5px;}
body header .userprofile .dropdown-menu a{font-size: 14px; padding: 5px 0; color: var(--white_color); display: block;}
body header .userprofile .dropdown-menu a:hover{background: none; color: var(--white_color); opacity: 0.8;}
body .btn, body .ant-btn.ant-btn-primary{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; border-radius: 30px; /*font: normal normal normal 15px/27px Poppins;*/ border: none; text-transform: uppercase; padding: 8px 15px 7px; min-width: inherit; line-height: 1;}
body .ant-btn-round.ant-btn-lg{min-height: 64px; font-size: 20px; padding: 0;}
body .btn:hover, body .ant-btn:hover{background: transparent linear-gradient(180deg, #06488B 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;}
body .form-control{border: 3px solid var(--text_color); border-radius: 30px; text-align: left; font: normal normal normal 20px/30px 'Poppins'; letter-spacing: 0px; color: var(--text_color); opacity: 1; padding: 17px 40px; line-height: 1;}
body .login-form p, .login-form form small{font-size: 20px; text-align: center; margin-bottom: 20px; display: inline-block; width: 100%;}
.login-form form .auth-text{background: url(../images/auth-mobile.png) no-repeat left center; filter: brightness(100); min-height: 63px; font-size: 16px; color: var(--white_color); text-align: center; padding: 6px 0 6px 45px; margin: 0 auto 28px; width: 396px;}
.login-form form.ant-form .ant-form-item{border: none; border: none; padding: 0; margin: 0; text-align: center;}
.login-form form.ant-form .ant-form-item.login-btn{padding-top: 20px; padding-bottom: 10px;}
.login-form form.ant-form .ant-form-item input{border: 3px solid var(--text_color); border-radius: 30px; text-align: left; letter-spacing: 0px; color: var(--text_color); padding: 15px 40px; font-size: 16px; line-height: 1; min-width: 396px; margin-bottom: 5px;}
.login-form form.ant-form .ant-form-item input{border-color: var(--white_color);}
.login-form form.ant-form .ant-form-item input:hover{border-right-width: 3px !important;}
.login-form form.ant-form .ant-form-item.confirmButton button{/* background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; border-radius: 80px; opacity: 1; text-align: center; font: normal normal normal 18px/27px Poppins; letter-spacing: 0.25px; color: var(--white_color); min-width: 396px; min-height: 64px; padding: 0; border: none; text-transform: uppercase; margin-bottom: 10px; margin-top: 10px; */ background: #032c5d; border: #FFF 2px solid; min-width: inherit; font: normal normal normal 18px/27px Poppins; padding: 0; min-width: 143px; height: 51px; min-height: inherit; text-transform: none;}
/* .login-form form.ant-form .ant-form-item.confirmButton button:hover{background: transparent linear-gradient(180deg, #06488B 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;} */
body .sidebar-nav{width: auto; float: left; height: calc(100vh - 114px); margin: 0; padding: 0; background: #005B98; position: relative; z-index: 2; width: 58px;}
body .sidebar-nav.open{width: auto;}
body .sidebar-nav body .sidebar-nav .dropdown{display: flex; flex-direction: row-reverse;}
body .sidebar-nav .sidebtn{font-size: 0; width: 26px; height: 26px; background: #001727 !important; border: none; border-radius: 0; box-shadow: none !important; min-width: inherit; margin-right: 0; padding: 0; position: absolute; color: var(--white_color); font-style: normal; line-height: 26px; right: -26px; text-align: center; cursor: pointer;}
body .sidebar-nav .sidebtn:before{content: '\f101'; font-family: 'FontAwesome'; font-size: 14px;}
body .sidebar-nav .dropdown-menu{position: relative !important; background: none; border: none; padding: 0; transform: none !important; min-width: 60px; display: block;}
body .sidebar-nav ul{margin: 0; padding: 0; list-style: none; height: calc(100vh - 115px); /* overflow: auto; */}
body .sidebar-nav ul li a{font-size: 12px; color: var(--white_color); font-weight: normal; background: #004C8040; display: block; min-height: 60px; display: flex; align-items: center; margin: 1px 0 0; padding: 0 15px; font-size: 0;}
body .sidebar-nav.open ul li a{font-size: 12px;}
body .sidebar-nav ul li a img{max-width: 27px;}
body .sidebar-nav.open ul li a img{margin-right: 10px;}
body .sidebar-nav ul li a:hover{background: none;}
.defaultshow{display: block !important;}
body .full-content-block{margin-left: 15px; display: flex; align-items: center; /* justify-content: center; */ flex-direction: column; /*overflow: hidden;*/ margin-right: 15px; padding-bottom: 0;}
body .info-page .full-content-block{margin-left: 0;}
body .info-page .full-content-block h1{text-align: center; font-size: 38px; text-align: center;}
body .full-content-block h2{font-size: 26px; margin: 30px 0 0; font-weight: 600;}
body .full-content-block>.col-md-12{height: calc(100vh - 170px);}
body .full-content-block .page-title{position: absolute; left: 15px; margin: 0; top: 64px; font-size: 20px; font-weight: 500; display: flex; align-items: center; height: 50px;}
body .full-content-block .page-title img{height: 30px; margin-left: 10px; margin-top: -4px;}
body .full-content-block .page-title p{margin-right: 25px; cursor: pointer; color: var(--primary__color); margin-bottom: 0;}
body .full-content-block .page-title p:hover{color: var(--black__color);}
body .header-bottom-block{background: var(--white_color); min-height: 49px; box-shadow: 0px -2px 30px #00000029; display: flex; align-items: center;}
body .header-bottom-block .searchbar{margin: 0 30px 0 auto; position: relative;}
body .header-bottom-block .searchbar form{display: flex;}
body .header-bottom-block .searchbar form .ant-select-selector{border: none; padding: 0;}
body .header-bottom-block .searchbar form::before{content: ''; width: 270px; height: 100%; position: absolute; z-index: 1;}
body .header-bottom-block .searchbar form .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input{width: 500px; height: 33px; border: 1px solid #E2E2E2; border-radius: 30px; color: var(--black__color); font-size: 12px; padding: 0 20px; float: right; margin: 0 -15px;}
.ant-select-item-option-content i{width: 25%; font-style: normal; display: inline-block;}
.ant-select-item-option-content span{opacity: 0.8;}
body .header-bottom-block .searchbar form .ant-select-selection-placeholder{float: right; line-height: 33px; margin-left: auto; display: table-row; display: flex; margin-left: 290px;}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{box-shadow: none !important;}
.editor-class.rdw-editor-main{border: 1px solid #F1F1F1; padding: 15px; border-top: none; margin: -10px 0 30px;}
.integration-card{display: flex; flex-flow: wrap; grid-gap: 30px;}
.integration-card h3{width: 100%; font-size: 20px; font-weight: 600; color: var(--primary__color);}
.integration-card .card{width: calc(25% - 22.5px); border-radius: 20px; margin-top: 20px; box-shadow: #00000009 0 20px 34px; padding: 20px; background: #FFF; border: #F1F1F1 1px solid;}
.integration-card .card-body{padding: 0; display: flex; align-items: center; flex-direction: column; min-height: 170px; justify-content: center; width: 100%;}
.integration-card .card-body .crm-img{margin: -50px 0 40px; box-shadow: #00000016 0 3px 20px; border-radius: 50%; width: 120px; height: 120px; background: var(--white_color); overflow: hidden; text-align: center; position: relative;}
.integration-card .card-body .crm-img:before{width: 100%; height: 100%; border: 7px solid #FFF; border-radius: 50%; content: ''; position: absolute;}
.integration-card .card-body .crm-img img{width: 100px; height: 100px; margin: 10px;}
.integration-card .card-body p{font-size: 16px; font-weight: 500; text-align: center; margin-bottom: 50px;}
.integration-card .card-body .btn{height: auto; min-width: inherit; color: var(--white_color); font-size: 14px; line-height: 1; padding: 12px 20px; opacity: 1 !important; visibility: visible !important; display: block !important; background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box;}
body .header-bottom-block .searchbar form .btn{padding: 0; min-width: inherit; font-size: 12px; background: url(../images/search-icon.png) no-repeat center; font-size: 0; width: 20px; position: absolute; right: 10px; /* z-index: 1; */ height: 33px;}
body .header-bottom-block .global-time-period{margin-right: 15px;}
body .header-bottom-block .global-time-period form{display: flex; align-items: center;}
body .header-bottom-block .global-time-period form label{font-size: 14px; color: var(--black__color); white-space: nowrap; margin-right: 5px; margin-bottom: 0;}
body .header-bottom-block .global-time-period form select{height: 33px; padding: 0 15px; border-radius: 20px; border: 1px solid #E2E2E2; min-width: 135px; font-size: 14px;}
.content-details{width: 100%; background: rgba(242, 242, 242, 0.5); padding: 30px; box-sizing: border-box; margin: 30px 15px; border-radius: 30px; /*height: calc(100vh - 174px);*/ overflow: auto;}
#supplier_modal .content-details{height: 100%; overflow: hidden;}
form#supplierForm{max-height: calc(100vh - 174px); overflow: auto;}
#supplier_modal ::-webkit-scrollbar{width: 6px !important;}
#supplier_modal .action-box{clear: both; display: flex; align-items: center !important; justify-content: flex-end !important; margin-bottom: 0;}
#supplier_modal  .modal-body .action-box button{margin-right: 0 !important; right: 0; bottom: 0; margin-left: 15px;}
#supplier_modal .action-box button{border-radius: 30px;}
.small-modal .modal-dialog .modal-body .content-details{margin-bottom: 0;}
.mid-modal .modal-dialog{max-width: 768px;}
.ant-table{border-radius: 20px !important;}
.ant-pagination-item{background: none !important;}
.content-details .table-top-btn{display: flex; align-items: center; margin: 0 0 10px;}
.content-details .table-top-btn .search-section .ant-select{min-width: 350px !important;}
.content-details .table-top-btn .table-btn{margin-left: auto; display: flex; align-items: center; flex-flow: wrap; row-gap: 10px; justify-content: flex-end; column-gap: 8px;}
.content-details .table-top-btn .table-btn .border-btn{border: #CCC 2px solid; margin: 0; border-radius: 30px; padding: 3px; display: flex;}
/* .content-details .table-top-btn .table-btn .border-btn button{height: auto !important; font-size: 16px; line-height: 1.2;} */
.content-details .table-top-btn .table-btn .border-btn button.btn-link{padding: 0; margin: 0 5px 0 15px;}
.content-details .table-top-btn h2{color: var(--primary__color); margin: 0; font-size: 20px;}
.content-details .table-top-btn .table-btn .btn-link, .download-template.btn.btn-link{font-size: 16px; text-decoration: underline; background: unset; color: var(--primary__color); text-transform: none; padding: 0 25px; border-radius: 30px; height: 48px; line-height: 46px; margin-left: 5px; font-weight: 500; display: inline-block; min-width: inherit;}
.content-details .table-top-btn .table-btn .btn-link:hover{background: unset !important;}
.content-details .table-top-btn .table-btn button, .content-details .table-top-btn .table-btn .button{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box; font-size: 14px; color: var(--white_color); text-transform: uppercase; padding: 8px 15px 7px; border-radius: 30px; height: auto; line-height: 1; margin-left: 0; font-weight: 500; display: inline-block; min-width: inherit;}
.content-details .table-top-btn .table-btn a:hover, .content-details .table-top-btn .table-btn button:hover{background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;}
.content-details .search-section{display: flex; align-items: center;}
.content-details .search-section .ant-picker-range{border-radius: 30px;}
.content-details .search-section a.action{font-size: 12px; /* border: #E2E2E2 1px solid; */ background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box; color: var(--white_color); padding: 0 25px; border-radius: 30px; height: 33px; line-height: 31px; margin-left: 5px; font-size: 16px;}
.content-details .search-section a.action:hover{background: transparent linear-gradient(180deg, var(--blue_color) 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;}
.content-details .search-section form{position: relative; margin-right: auto;}
.content-details .search-section form input{min-width: 464px; height: 33px; border: 1px solid #E2E2E2; border-radius: 5px; color: var(--black__color); font-size: 12px; padding: 0 20px; background: #FFF !important;}
/* .content-details .search-section form input{min-width: 765px;} */
.content-details .search-section form .btn{padding: 0; min-width: inherit; font-size: 12px; background: url(../images/search-icon.png) no-repeat center; font-size: 0; width: 20px; position: absolute; right: 10px; z-index: 1; height: 33px; top: 0;}
.business-section .content-details .search-section form .btn{padding: 0; min-width: inherit; font-size: 12px; background: url(../images/search-black.png) no-repeat center; font-size: 0; width: 20px; position: absolute; right: 10px; z-index: 1; height: 33px; top: 0; border: none;}
.content-details .grid-section{/* height: calc(100vh - 285px) !important; */ margin: 30px 0 0;}
.ag-center-cols-container, .ag-header-container, .ag-floating-top-container, .ag-floating-bottom-container{width: 100% !important;}
.ag-theme-alpine .ag-header{background: #F1F1F1; border-radius: 20px 20px 0 0;}
.content-details .entry-card{border-radius: 30px; width: calc(50% - 15px); float: left; margin-bottom: 30px;}
.content-details .entry-card.full-card{width: 100%; margin-bottom: 20px;}
.content-details .entry-card:nth-child(2), .content-details .entry-card:nth-child(3){float: right;}
.content-details .entry-card:nth-child(4){/* clear: left; */ float: right;}
/* .audit-statement .ant-table-container table table tr th:nth-child(3), .audit-statement .ant-table-container table table tr td:nth-child(3){display: none;} */
.content-details .entry-card h3{background: #EEE; color: var(--black__color); font-size: 20px; font-weight: 500; border-radius: 30px 30px 0 0; line-height: 1; padding: 15px 30px; margin: 0;}
.content-details .entry-card .form-box{padding: 30px; display: flex; flex-flow: wrap; gap: 15px; border-radius: 0 0 30px 30px; background: var(--white_color);}
.content-details .entry-card .form-box
.content-details .entry-card .form-box .ant-form-item-control{padding-left: 0; padding-right: 0;}
.content-details .entry-card .form-box .address-box{width: 100%; border: #EEE 2px solid; border-radius: 5px; padding: 10px; display: flex; flex-flow: wrap; grid-gap: 15px;}
.content-details .entry-card .form-box .address-box button{min-width: inherit; padding: 0; padding: 5px 20px; height: auto; border: var(--lightgrey_color) 2px solid; color: var(--black__color); background: none;}
.content-details .entry-card .form-box .address-box .ant-form-item{width: calc(25% - 11.25px); padding: 0 !important;}
.content-details .entry-card .form-box .address-box.firm-address .tetra-width{width: calc(25% - 15px);}
.content-details .entry-card .form-box .address-box.firm-address h5{margin-bottom: 0;}
.content-details .entry-card .form-box .address-box h5{width: 100%; margin: 0;}
/* .content-details .entry-card .form-box .address-box .half-width{padding-top: 10px;} */
.ant-btn.linkButton{box-shadow: none;}
.date_field{white-space: nowrap;}
.content-details .entry-card .form-box .image-box{width: auto; display: flex; align-items: center; position: relative; justify-content: center; height: 124px; flex-direction: column; margin: 0;}
.content-details #customerForm .entry-card .form-box .image-box{margin-right: auto; width: auto;}
.content-details #customerForm .entry-card .form-box .crop-block{font-size: 14px; color: var(--black__color); font-weight: 400; text-transform: capitalize; margin-left: 0;}
.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-picture-card-wrapper{border: var(--text_color) 2px solid; border-radius: 10px; padding: 10px; display: block;}
.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-picture-card-wrapper .ant-upload-list{display: flex; grid-gap: 10px; justify-content: flex-start; margin-left: -10px;}
.ant-upload-list-item-card-actions-btn.ant-btn-sm{background: none; border: none;}
.content-details #customerForm .entry-card .form-box .crop-block .ant-upload.ant-upload-select-picture-card{width: auto; height: 70px; border: var(--black__color) 1px solid; background: none; margin: 0; padding: 0 5px;}
.content-details #customerForm .entry-card .form-box .crop-block .ant-upload-list-picture-card .ant-upload-list-item, .content-details #customerForm .entry-card .form-box .crop-block .ant-upload-list-picture-card-container{padding: 0; height: 70px; width: 100%; margin: 0;}
.content-details .entry-card .form-box .image-box input[type=file]{width: 120px; height: 120px; border-width: 3px; border-radius: 50%; -webkit-appearance: none; appearance: none;}
.content-details .entry-card .form-box .image-box button{/* height: 20px; font-size: 12px; padding: 0; width: 120px; border: none; background: rgba(0,119,199,0.47); color: var(--white_color); */ position: absolute; bottom: 0; background: none !important;}
.content-details .entry-card .form-box .input-boxes{width: 75%; display: flex; flex-flow: wrap; grid-gap: 30px;}
.content-details .entry-card .form-box .input-boxes .auth-form{width: 100%; border: #dedede 1px solid; border-radius: 20px; padding: 30px; background: #F1f1f1;}
.content-details .entry-card .form-box .input-boxes .auth-form .auth-title{display: flex; align-items: center;}
.content-details .entry-card .form-box .input-boxes .auth-form .auth-title h2{margin: 0;}
.content-details .entry-card .form-box .input-boxes .auth-form .auth-title .ant-form-item{margin: 0 0 0 auto !important; width: auto;}
.content-details .entry-card .form-box .input-boxes .auth-form .auth-title .ant-btn{margin-left: auto; min-width: inherit; padding: 0 15px; color: var(--white_color); font-size: 14px; text-transform: none;}
.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item.change-email{margin-top: 5px !important;}
.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item.change-email button{margin-left: auto; min-width: inherit; padding: 0 15px; color: var(--white_color); font-size: 14px; text-transform: capitalize; background: #0078C890;}
.content-details .entry-card .form-box .input-boxes .auth-form .ant-form-item{width: 100%; margin: 20px 0 0 !important;}
.content-details .entry-card .form-box .input-boxes .auth-form h4{margin: 5px 0 0; text-transform: uppercase; font-weight: 700; font-size: 20px;}
.content-details .entry-card .form-box .form-label{font-size: 14px; margin: 0 0 5px; color: var(--black__color);}
.content-details .entry-card .form-box .form-control, .content-details .entry-card .form-box .form-select{border: var(--text_color) 1px solid; border-radius: 30px; color: var(--lightgrey_color); font-size: 14px; padding: 5px 15px;}
.content-details .entry-card .form-box textarea.form-control{padding: 15px; border-radius: 20px;}
.content-details .entry-card .form-box .half-width{width: calc(50% - 7.5px); grid-gap: 2%; margin: 0;}
.content-details .entry-card .form-box .ant-picker{border: 1px solid #d9d9d9; border-radius: 30px; width: 100%;}
.content-details .entry-card .form-box .tetra-width{width: calc(25% - 12px);}
.content-details .entry-card .form-box .tri-width{width: 33.33%;}
.content-details .entry-card .form-box .full-width{width: 100%; display: flex; flex-flow: wrap; position: relative;}
.content-details .entry-card .form-box .full-width.address_block{grid-column-gap: 10px;}
.content-details .entry-card .form-box .full-width.address_block label{width: 100%;}
.content-details .entry-card .form-box .full-width.address_block input, .content-details .entry-card .form-box .full-width.address_block select{width: calc(25% - 7.5px);}
.action-box{clear: both; display: flex; align-items: flex-end; justify-content: flex-end;}
.upload-btn-div{clear: both; display: flex; align-items: flex-end; justify-content: flex-end; margin-top: 10px;}
.action-box button{color: white; font-size: 14px; text-transform: uppercase; font-weight: 500; margin-left: 5px; margin-right: 0;}
.modal-body .action-box button{margin-right: 148px; margin-left: 0;}
.action-box button.reset, .modal-body button.reset{border: var(--lightgrey_color) 1px solid; color: var(--black__color); background: none; /*min-height: 49px; padding: 0; min-width: 143px;*/}
.action-box button.upload, .modal-body button.upload{border: var(--lightgrey_color) 2px solid; min-height: 49px; padding: 0; min-width: 143px; text-transform: none; font-size: 20px; margin: 0 10px 0 0;}
.modal-body .upload-btn-div .upload{border: none;}
.upload-div{margin: 20px;}
.upload-btn-div button.cancel-btn{margin: 0; border: var(--lightgrey_color) 2px solid; min-height: 49px; color: var(--black__color); background: none; padding: 0; min-width: 143px; text-transform: none; font-size: 20px; margin: 0 10px 0 0;}
.ant-modal-body{padding: 0 !important; min-height: 60px;}
.modal-body button.reset{position: absolute; right: 30px; bottom: 30px;}
.action-box button.reset:hover, .modal-body button.reset:hover{background: var(--lightgrey_color); color: var(--white_color) !important; border-color: var(--lightgrey_color);}
body .ag-theme-alpine .ag-paging-panel{border: none; padding: 0;}
body .ag-theme-alpine .ag-root-wrapper{border: none; background: transparent;}
body .ag-theme-alpine .ag-header{background: #EEE; color: var(--primary__color); font-size: 16px; font-weight: 500; border-radius: 30px 30px 0 0; line-height: 1; margin: 0; border-color: #ECECEC;}
body .ag-theme-alpine .ag-filter-toolpanel-header, body .ag-theme-alpine .ag-filter-toolpanel-search, body .ag-theme-alpine .ag-status-bar, body .ag-theme-alpine .ag-header-row, body .ag-theme-alpine .ag-panel-title-bar-title, body .ag-theme-alpine .ag-multi-filter-group-title-bar{color: var(--primary__color); font-weight: 500;}
body .ag-theme-alpine .ag-row{border-color: #ECECEC;}
body .ag-theme-alpine .ag-row .ag-cell{border-right: #ECECEC 1px solid;}
body .ag-theme-alpine .ag-row .ag-cell:first-child{border-left: #ECECEC 1px solid;}
.content-details .customer-summary{display: flex; align-items: center; padding: 20px; box-shadow: 0px 20px 34px #00000017; border-radius: 20px; justify-content: center; margin-bottom: 20px; background: var(--white_color);}
.content-details .summary-filter{display: flex; justify-content: flex-end; align-items: center; color: #005B98; text-transform: capitalize; font-weight: 500; width: auto; float: right; margin: 10px 0 20px; border: #00000017 solid 1px; padding: 10px 15px; line-height: 1; border-radius: 20px; cursor: pointer; background: var(--white_color);}
.content-details .summary-filter svg{margin-left: 5px;}
.content-details .customer-summary .balance{margin: 0 auto;}
.content-details .customer-summary h2{font-size: 30px; color: var(--primary__color); margin: 0; padding: 0; font-weight: 500; text-transform: capitalize;}
.content-details .customer-summary h6{color: var(--primary__color); font-size: 18px; font-weight: 500; margin: 4px 0 0;}
.content-details .customer-summary .print-btn button{font-size: 20px; text-transform: none; padding: 0; min-width: 205px; min-height: 49px;}
/* firm trust account balance card */
.firm-details{width: calc(100% - 10px); /* background: rgba(242, 242, 242, 0.5); */ padding: 0; box-sizing: border-box; /* margin: 30px 15px; */ border-radius: 30px; /* height: calc(100vh - 174px); */ /* overflow: auto; */}
.firm-details .firm-summary{padding: 20px; box-shadow: 0px 20px 34px #00000017; border-radius: 20px; margin-bottom: 20px; background: var(--white_color); gap: 30px; flex-flow: wrap;}
.firm-details .firm-summary .account-wrapper{width: calc(100% - 15px) !important; display: flex !important; flex-flow: wrap; border: #CCC 1px solid; padding: 15px; border-radius: 10px; margin: 0 7.5px;}
.firm-details .firm-summary .reconciliation-block{margin-left: auto; display: flex; align-items: flex-start; grid-gap: 15px;}
.firm-details .firm-summary .reconciliation-block .btn{font-size: 20px; text-transform: none; padding: 0; min-width: 205px; min-height: 49px; color: var(--white_color);}
.firm-details .firm-summary .balance{margin: 0 auto;}
.firm-details .firm-summary h2{font-size: 30px; color: var(--primary__color); margin: 0; padding: 0; font-weight: 500; text-transform: capitalize;}
.firm-details .firm-summary h6{color: var(--primary__color); font-size: 16px; font-weight: 500; margin: 0 0 8px;}
.firm-details .firm-summary h5{color: var(--primary__color); font-size: 28px; font-weight: 500; margin: 0;}
.firm-details .firm-summary .print-btn button{font-size: 20px; text-transform: none; padding: 0; min-width: 205px; min-height: 49px;}
/* .ag-theme-alpine .ag-header-icon{display: none;} */
body .ag-theme-alpine .ag-header-cell, body .ag-theme-alpine .ag-header-group-cell, body .ag-theme-alpine .ag-cell, body .ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group{padding-left: 30px; padding-right: 30px;}
body .ag-theme-alpine .ag-header-cell .ag-header-cell-resize{display: none;}
/* body .ag-theme-alpine .ag-header-cell .ag-header-cell-resize{display: none;} */
.ag-theme-alpine .ag-row{color: var(--black__color); font-size: 16px;}
.rag-green, .rag-green::first-letter{color: #0DA500;}
.rag-red, .rag-red::first-letter{color: #F00;}
body .modal-backdrop{display: none;}
body.modal-open .modal{background: rgba(0, 119, 199, 0.47);}
body .modal-dialog.large-popup{width: 1024px;}
body .modal form{padding: 15px;}
body .modal form .form-box{display: flex; gap: 15px; flex-flow: wrap;}
body .modal form .form-box .form-group{margin: 0;}
body .modal form .form-box .form-group.half-width{width: calc(50% - 7.5px);}
body .modal form .form-box .form-group.full-width{width: 100%;}
body .modal form .form-box .form-group.tri-width{width: 32%;}
body .modal form .form-box .address-box{width: 100%; display: flex; flex-flow: wrap; gap: 15px; border: #F0F0F0 2px solid; border-radius: 30px; padding: 15px; margin: 15px 0;}
body .modal form .form-box .address-box h5{width: 100%; margin: 0;}
body .detailpopup .modal-dialog .modal-header{background: none; padding: 0; margin: 0; position: relative; z-index: 1;}
body .modal-dialog.close-popup{max-width: 500px;}
body .modal-dialog.close-popup .modal-content{border-radius: 15px; padding: 15px;}
body .modal-dialog.close-popup .modal-content{padding: 30px;}
body .modal-dialog.close-popup .modal-header{background: none; padding: 0; margin: 0;}
body .modal-content{border-radius: 10px; border: none; box-shadow: none;}
body .modal-dialog .modal-header{background: #EEE; border-radius: 10px 10px 0 0; border: none; text-align: left; justify-content: flex-start; color: var(--black__color); font-size: 20px; font-weight: 500; padding: 10px 15px; margin-bottom: 0; display: flex;}
body .modal-dialog .modal-header .close{padding: 0; margin: 0; position: absolute; right: 15px; background: var(--primary__color); opacity: 1; line-height: 1px; color: var(--white_color); width: 36px; height: 36px; border-radius: 50%; background-size: 10px; top: 11px;}
body .modal-dialog.close-popup .modal-header .btn-close{right: -18px; top: -18px; background: var(--blue_color);}
body .modal-dialog .modal-header .btn-close:hover{background: #001727;}
body .modal-dialog .modal-action{text-align: center;}
body .modal-dialog .modal-action .btn{min-width: inherit; font-size: 14px; line-height: 1; border-radius: 5px; padding: 7px 15px; margin: 0 3px;}
body .modal-dialog .modal-action .btn.cancel{background: #999;}
body .modal-dialog .modal-action .btn.cancel:hover{background: #001727;}
.review-popup .btn{min-width: inherit; font-size: 14px; line-height: 1; border-radius: 5px; padding: 7px 15px; color: var(--white_color); margin: 0 3px; border-radius: 30px;}
.review-popup .btn:hover{color: var(--white_color);}
body .modal-dialog .modal-body{padding: 0;}
body .modal-dialog.profile-popup .modal-body{padding: 25px 0 0;}
body .modal-dialog.profile-popup .modal-body .modal-content{box-shadow: none; border-radius: 0 0 30px 30px;}
body .modal-dialog .modal-header .btn-close .btn{min-width: inherit; font-size: 16px; border-radius: 5px; padding: 7px 15px; line-height: 1; float: right;}
body .modal-dialog .modal-header .btn-close:before{content: '\f00d'; font-family: 'FontAwesome'; font-weight: normal; font-size: 16px;}
body .icon{font-family: 'FontAwesome';}
body .modal-body{padding: 0;}
body .modal-body .content-details{background: transparent; padding: 0 30px 30px; height: auto; margin: 0; width: 100%;}
body .modal-body .content-details .entry-card.full-card{width: 100%; margin-bottom: 10px;}
body .modal-body .content-details .entry-card.full-card .form-box{padding: 0 0 20px; grid-column-gap: 2%;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width{width: 49%;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width div{justify-content: flex-start !important;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width div p{margin: 0;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width div a{color: #005B98; margin-left: 5px; font-weight: 500;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width a.action{background: #0078C885; color: var(--white_color); font-size: 12px; border-radius: 15px; padding: 8px 13px; display: inline-block; line-height: 1; margin-top: 5px;}
body .modal-body .content-details .entry-card.full-card .form-box .half-width a.action:hover{background: var(--lightblue_color);}
body .ant-modal-wrap{padding: 20px 0;}
body .modal-body .modal-form{padding: 30px; display: flex; flex-flow: wrap; grid-gap: 30px;}
body .modal-body .remove-content{text-align: center;}
body .modal-body .remove-content p{font-size: 16px;}
.remove-popup .ant-modal-footer button:first-child{border: var(--lightgrey_color) 2px solid; background: none; color: var(--black__color);}
body .modal-body .modal-form input, body .modal-body .modal-form select{border: var(--text_color) 1px solid; border-radius: 30px; color: var(--lightgrey_color); font-size: 14px; padding: 5px 15px;}
body .modal-body .modal-form .half-width{width: calc(50% - 15px);}
body .modal-body .modal-form .action-box{clear: both; display: flex; align-items: flex-end; justify-content: flex-end; width: 100%;}
body .modal-body .modal-form .action-box button{min-height: 49px; min-width: 143px; padding: 0; font-size: 20px; text-transform: none; margin-left: 5px;}
body .modal-body .modal-form .action-box button.reset{border: var(--lightgrey_color) 2px solid; color: var(--black__color); background: none; ;}
body .modal-body .modal-form .action-box button.reset:hover{background: var(--lightgrey_color); color: var(--white_color);}
body .modal-body .modal-content-details{padding: 15px 30px; display: flex; flex-flow: wrap; align-items: center; /* margin-top: 40px; */ /* align-items: center; justify-content: center; */}
/* body .modal-body>.modal-content-details{margin-top: 40px;} */
body .modal-body .modal-content-details .action-block{display: flex; align-items: center;}
body .modal-body .modal-content-details .action-block .btn.update-customer{margin-right: auto;}
body .modal-body .modal-content-details .customer-account{margin-left: auto; align-items: flex-end; /* display: flex; flex-direction: column; */ text-align: right; width: calc(100% - 150px); padding: 0; align-items: flex-end;}
body .modal-body .modal-content-details .profile{display: flex; align-items: center; flex-direction: column; margin-top: -20px; width: 150px; text-align: center;}
body .modal-body .modal-content-details .profile.full{width: auto;}
body .modal-body .modal-content-details .profile h5{font-size: 18px; font-weight: 600; line-height: 1; margin: 12px 0 0; color: var(--primary__color); text-transform: capitalize;}
body .modal-body .modal-content-details .profile .ant-image{width: 114px; height: 114px; border-radius: 50%; border: var(--text_color) 2px solid;}
body .modal-body .modal-content-details .profile>span{width: 114px; height: 114px; background: var(--primary__color); border-radius: 50%; color: var(--white_color); font-size: 80px; display: flex; justify-content: center; align-items: center; text-transform: capitalize;}
body .modal-body .modal-content-details .profile img{width: 110px; height: 110px; border-radius: 50%; max-width: 100%; border: #333 2px solid;}
body .modal-body .modal-content-details .profile img:after{content: '';}
.content-details .role-table .ant-table-content table thead th{width: auto;}
.ant-image-mask-info{display: flex; align-items: center;}
body .modal-body .modal-content .modal-content-details{padding-bottom: 5px; align-items: flex-start;}
body .modal-body .modal-content .modal-content-details .modal-content-left, body .modal-body .modal-content .modal-content-details .modal-content-right{width: 50%;}
/* .page-content-block{height: calc(100vh - 110px); overflow: auto;} */
body .modal-body .modal-content-details p{font-size: 16px; margin-bottom: 10px; width: 50%; font-weight: 500; display: flex; grid-gap: 5px;}
body .modal-body .modal-content-details p i{float: right; font-style: normal; margin-left: auto;}
body .modal-body .modal-content-details p span{float: right; display: flex; align-items: center; margin-left: auto;}
/* body .modal-body .modal-content-details p span.refund_block:before{width: 16px; height: 16px; border: var(--black__color) 2px solid; content: ''; margin-right: 5px; opacity: 0.85; border-radius: 50%;} */
body .modal-body .modal-content-details p strong, body .modal-body .modal-content-details p.deceased:first-letter{font-weight: 500; color: var(--primary__color); white-space: nowrap;}
body .modal-body .modal-content-details p.address-box strong{min-width: 165px;}
body .modal-body .modal-content h3{background: #F9F9F9; color: var(--black__color); font-size: 20px; line-height: 1; padding: 10px 30px; margin: 0;}
body .modal-body .modal-content-details a{color: var(--primary__color); font-size: 18px; line-height: 1; margin-bottom: 20px; text-decoration: underline; display: inline-block;}
body .modal-body .modal-content-details a.text-link{margin: 0 20px 20px;}
body .modal-body .modal-content-details a.authority-link{font-size: 12px; text-decoration: underline; color: var(--black__color);}
body .modal-body .modal-content-details a:hover{color: var(--black__color);}
body .modal-body .modal-content-details .btn{min-width: 162px; height: 38px; font-size: 16px; padding: 0 15px; text-transform: none; line-height: 38px; color: #FFF !important; text-decoration: none; margin-left: 20px; margin-bottom: 0;}
body .modal-body .modal-content-details .btn.small-btn{font-size: 12px; border: #E2E2E2 1px solid; background: rgba(0, 119, 199, 0.47); color: var(--white_color); text-transform: uppercase; padding: 0 15px; border-radius: 30px; height: 33px; line-height: 31px; margin-left: 5px; min-width: inherit;}
body .modal-body .modal-content-details .btn.small-btn:hover{background: rgba(0, 119, 199, 1);}
body .modal-body .modal-content-details .btn.update-customer{float: left; margin-left: 0;}
body .modal-body .modal-content-details h4{font-size: 22px; margin: 0 0 10px 0; color: var(--primary__color); line-height: 1;}
.customer_list .ag-body-viewport .ag-row .ag-cell:nth-child(2)::first-letter, .user_list .ag-body-viewport .ag-row .ag-cell:nth-child(1)::first-letter, .deleted-clients .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(2)::first-letter, .fullname::first-letter{background: rgba(0, 69, 116, 0.4); font-size: 14px; font-weight: 500; width: 20px; height: 20px; min-width: 20px; border-radius: 50%; color: var(--white_color); text-transform: uppercase; display: block; padding: 0 5px; margin-right: 8px;}
.ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(3) .ag-cell-label-container .ag-header-cell-label, .tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(4) .ag-cell-label-container .ag-header-cell-label, .ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(1) .ag-cell-label-container .ag-header-cell-label, .tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:nth-child(1) .ag-cell-label-container .ag-header-cell-label{justify-content: center;}
.ato-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:last-child .ag-cell-label-container .ag-header-cell-label, .tax-grid .ag-header .ag-header-viewport .ag-header-container .ag-header-cell:last-child .ag-cell-label-container .ag-header-cell-label{justify-content: flex-end;}
.error{color: red;}
.ant-select-dropdown{z-index: 99999 !important;}
body .full-content-block .ant-tabs{width: 100%; margin: 30px 0 0 !important;}
body .full-content-block .aba-tabs .ant-tabs{margin-top: 0 !important;}
body .full-content-block .ant-tabs .ant-table-thead>tr>th{color: var(--primary__color); font-size: 16px; background: #F1F1F1;}
body .full-content-block .ant-tabs .ant-tabs-content-holder{background: #F2F2F280 0% 0% no-repeat padding-box; border-radius: 0px 30px 30px 30px; padding: 30px; overflow: auto; height: calc(100vh - 231px);}
body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-table-wrapper{margin-top: 0;}
body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder{padding: 0; background: none;}
body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder .task-table{padding: 0; margin: 0;}
.fa-edit, .fa-trash{cursor: pointer;}
body .full-content-block .ant-tabs .ant-tabs .ant-tabs-content-holder .task-table .ant-table-content table thead tr th:first-child{border-radius: 0;}
body .full-content-block .ant-tabs .ant-tabs-content-holder .cms-page .table-btn{text-align: right; margin-bottom: 30px;}
.ant-table-tbody>tr:last-child>td{border: none;}
body .full-content-block .ant-tabs .ant-tabs-content-holder .cms-page .table-btn .btn{min-width: 205px; min-height: 49px; padding: 0; text-transform: none; position: relative; z-index: 1;}
body .full-content-block .ant-tabs .ant-tabs-nav{margin: 0;}
body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab{margin: 0; background: var(--white_color); border: 1px solid #F1F1F1; border-radius: 20px 20px 0px 0px; padding: 0 15px; min-width: 149px; text-align: center; height: 50px; justify-content: center; font-size: 16px; color: var(--black__color); font-weight: 500; margin-right: 5px;}
body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs{margin-top: -30px !important;}
body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs-tab{border-radius: 10px 10px 0 0; margin-right: 5px;}
body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover, body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active{background: #88BFE5; color: var(--white_color);}
body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover .ant-tabs-tab-btn, body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{color: var(--white_color);}
body .ant-tabs-ink-bar{display: none;}
body .ant-tabs-top>.ant-tabs-nav::before{display: none;}
body .ant-table-row-expand-icon{border: none;}
body .ant-table-row-expand-icon:before{font-family: 'FontAwesome'; content: '\f105'; transform: none; top: 50%; height: auto; background: none; left: 50%; right: auto; font-size: 18px; transform: translate(-50%, -50%); transform-origin: 0 0;}
body .ant-table-row-expand-icon.ant-table-row-expand-icon-expanded:before{transform: rotate(90deg) translate(-3px, -8px);}
body .ant-table-row-expand-icon:after{display: none;}
body .ant-table-tbody>tr>td{border-color: #ECECEC;}
body .ant-table-tbody>tr>td:last-child svg{font-size: 16px;}
body .ant-pagination-prev .ant-pagination-item-link, body .ant-pagination-next .ant-pagination-item-link{background: none;}
body .ant-pagination-prev .ant-pagination-item-link .anticon, body .ant-pagination-next .ant-pagination-item-link .anticon{display: flex; align-items: center;}
body .ant-pagination-next .ant-pagination-item-link .anticon-right::before{content: 'Next'; display: block; padding-right: 5px;}
body .ant-pagination-prev .ant-pagination-item-link .anticon-left::after{content: 'Prev'; display: block; padding-left: 5px;}
body .firm-form{display: flex;}
body .firm-form .ant-upload-list-picture:before{border: none;}
body .firm-form .ant-upload-list-picture .ant-upload-list-item{border-radius: 5px;}
body .firm-form .ant-upload-list-picture .ant-upload-list-item img{border-radius: 0; padding: 5px;}
body .firm-form .image-box span.ant-upload{border-radius: 0 0 5px 5px;}
body .firm-form .image-box{margin-right: 50px; text-align: center; position: relative;}
body .firm-form .input-boxes{width: calc(100% - 180px);}
.ant-tabs-tabpane .content-details{background: var(--white_color); margin: 0; height: auto; padding: 30px; width: 100%; max-height: inherit;}
/* .ant-pagination-disabled{display: none !important;} */
.authority-letter .ant-image-mask{display: none;}
.ant-input-number{width: 100% !important; border-radius: 20px !important;}
.centeralign{text-align: center !important;}
.spin-padding{padding-top: 20px;}
.ant-input-number-handler{border: none !important;}
.ant-input-number{overflow: hidden;}
body .ant-btn.undo_btn:hover{background: var(--white_color);}
/* .ant-table-column-title{white-space: nowrap;} */
[data-title]:after{content: attr(data-title); background: var(--white_color) 0% 0% no-repeat padding-box; box-shadow: 3px 6px 10px #00000029; white-space: nowrap; font: normal normal normal 14px/23px 'Poppins'; color: var(--black__color); z-index: 9999999; padding: 5px 15px; line-height: 1; display: none; position: absolute; left: 100%;}
[data-title]:hover:after{transition: all 0.1s ease 0.5s; display: block;}
[data-title]{position: relative;}
[data-title]:after{content: attr(data-title); background: var(--white_color) 0% 0% no-repeat padding-box; box-shadow: 3px 6px 10px #00000029; white-space: nowrap; font: normal normal normal 14px/23px 'Poppins'; color: var(--black__color); z-index: 9999999; padding: 5px 15px; line-height: 1; display: none; position: absolute; left: 100%;}
[data-title]:hover:after{transition: all 0.1s ease 0.5s; display: block;}
[data-title]{position: relative;}
.content-details .search-section.process-search{margin-bottom: 30px;}
.content-details .search-section.process-search .ant-input, .ant-select:not(.ant-select-customize-input) .ant-select-selector{border-radius: 30px !important;}
.content-details .search-section.process-search .ant-input-search-button{padding: 0; min-width: inherit; font-size: 12px; background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAABHNCSVQICAgIfAhkiAAAAXFJREFUOE+lUz1LxEAUnNX6asFetNBSi4ug2Ahi4UdnY6FgI1h4FxuttIp3iNYKFrZ+gCDYiAdZCwUrEfUXmFbru3U2u0n2zEU4DGwg783Mm/f2ReAfj8hxK3IKPRjO4uoWwfh7pxoZWZMEDnkcoqUoNCDU2m8RQ/Yf5gB1maorvBD8AQiPsT7HxZArIOCH/QQ9W1CEFpZQ8+5Sgh8uM38af2vRfW8kyQlU5Tat7saBJsZQ955y/bkCEPMIylcao8n3fE9Q9oKWFguH78tP406dELdqyL5U1tIOLe0Vk8NzwhdovUHcZEI2igp1BiuF5A4O9cCOqbhCUkRLA7T03aHnQWLebHwDgXdkKm/KUfTi0SSyfpxpl6DEtZlLjEmvy95zWl1ntYMtNMUrN22apHUzqPhpc5eQS0wcWPuFbdvB6gUq6/badzvetNasIxJxkGc8N3Qwo5t0BfI/xl91q7LmCnRH1sKZQNQ9WQvEN6S+fgCxB4Tq+23mqwAAAABJRU5ErkJggg==) no-repeat center; font-size: 0; width: 20px; position: absolute; right: 10px; z-index: 1; height: 33px; top: 0; border: none;}
.content-details .search-section.process-search .ant-input-search-button span{opacity: 0;}
.ant-select-dropdown{padding: 0 !important;}
.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-group{background: #F1F1F1; color: var(--primary__color); font-size: 14px; font-weight: 500; padding: 10px 15px; line-height: 1;}
.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped{color: var(--black__color); font-size: 14px; padding: 7px 15px; border-bottom: #F1F1F1 1px solid;}
/* .ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped.optionlist .ant-select-item-option-content::first-letter{background: rgba(0, 69, 116, 0.4); font-size: 14px; font-weight: 500; width: 20px; height: 20px; min-width: 20px; border-radius: 50%; color: var(--white_color); text-transform: uppercase; display: block; padding: 0 5px;} */
.ant-select-dropdown .rc-virtual-list-holder .ant-select-item-option-grouped.ant-select-item-option-active{background: rgba(0, 119, 199, 0.19);}
.ant-select-dropdown .rc-virtual-list-holder .viewmore{color: var(--primary__color);}
.dashboard{width: 100%; text-align: center; position: relative;}
.dashboard .dashboard-pager{position: absolute; right: 120px; top: 29px; color: var(--black__color); font-size: 14px; text-transform: capitalize; font-weight: 500; display: flex; align-items: center;}
.dashboard .dashboard-pager .ant-select-selector{border-radius: 20px !important; min-width: 140px; min-height: 36px; border: #E2E2E2 1px solid !important; margin-left: 5px;}
.dashboard .dashboard-pager .ant-select-selector .ant-select-selection-item{/* text-indent: -10px; */ line-height: 34px; color: var(--black__color); text-align: justify; font-weight: 400;}
.dashboard .ant-tabs>.ant-tabs-nav{position: absolute; right: 0; top: 25px;}
body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab{min-width: 41px; height: 41px; margin: 0; border-radius: 0; background: var(--white_color) 0% 0% no-repeat padding-box; border: 1px solid #ECECEC; text-indent: -9999em;}
body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab::before{font-family: 'FontAwesome'; content: '\f03a'; line-height: 1; font-size: 18px; text-indent: 0em;}
body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab:first-child:before{content: '\f00a';}
body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab:hover, body .full-content-block .dashboard .ant-tabs>.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active{background: #ECECEC 0% 0% no-repeat padding-box; color: var(--primary__color);}
body .full-content-block .dashboard .ant-tabs .ant-tabs-content-holder{padding: 0; background: none; border-radius: 0;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item{border: none; margin-bottom: 1px;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:first-child .accordion-button{border-radius: 10px 10px 0 0;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:last-child .accordion-collapse, body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion .accordion-item:last-child .accordion-button.collapsed{border-radius: 0 0 10px 10px;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-header{margin: 0;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-header button{background: #F2F2F280; color: var(--primary__color); border-radius: 0; font-weight: 700; border: none;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse{margin: 0 0 -1px; border: #F2F2F280 1px solid; border-radius: 0; border-top: none;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body{padding: 0;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul{list-style: none; padding: 0; margin: 0;}
.accordion-button::after{background-color: var(--white_color); background-size: 18px;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li{border-bottom: #E7F1FF 1px solid; padding: 10px 15px; display: flex; flex-flow: wrap;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .crn{min-width: 320px;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .crn span:last-child{margin-left: 0px !important;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li:hover{background: #F0F0F0;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li:last-child{border-bottom: none;}
body .full-content-block .dashboard .ant-tabs-content-holder .list-view .accordion-item .accordion-collapse .accordion-body ul li .card-footer{margin-left: auto; padding: 0 20px 0 0; background: none; border: none; margin: 0 0 0 auto;}
body .full-content-block .dashboard h2{margin-bottom: 30px;}
.dashboard .grid-view{display: flex; grid-gap: 30px;}
.dashboard .grid-view .dashboard-grid{background: #F2F2F280 0% 0% no-repeat padding-box; border-radius: 30px; width: 25%; position: relative;}
.dashboard .grid-view .dashboard-grid.nodata:after{content: ''; background: url(../images/empty-board.svg) no-repeat center center; width: 60%; height: 241px; opacity: 0.2; position: absolute; top: 50px; left: 50%; background-size: 100%; transform: translateX(-50%);}
.dashboard .grid-view .dashboard-grid:nth-of-type(1):before{background-color: rgb(249, 202, 25);}
.dashboard .grid-view .dashboard-grid:nth-of-type(2):before{background-color: rgb(62, 168, 243);}
.dashboard .grid-view .dashboard-grid:nth-of-type(3):before{background-color: rgb(236, 29, 36);}
.dashboard .grid-view .dashboard-grid:nth-of-type(4):before{background-color: rgb(81, 218, 110);}
.dashboard .grid-view .dashboard-grid:before{content: ''; width: calc(100% - 60px); height: 2px; position: absolute; left: 0; right: 0; margin: 0 auto;}
.dashboard .grid-view h3{font: normal normal 600 18px/27px 'Poppins'; letter-spacing: 0px; color: var(--lightblue_color); text-align: center; margin: 25px 0 15px;}
.dashboard .grid-view ul{padding: 15px; list-style: none; height: calc(100vh - 315px); overflow: auto;}
.dashboard .grid-view ul li{background: var(--white_color) 0% 0% no-repeat padding-box; border-radius: 20px; padding: 15px; margin-bottom: 15px;}
.dashboard .grid-view ul li:hover{box-shadow: 0px 20px 34px #00000017;}
.dashboard ul li p{text-align: left; letter-spacing: 0px; color: var(--black__color); display: flex; margin: 0 0 10px; align-items: center;}
.dashboard ul li p.refund-p{font: normal normal normal 14px/23px 'Poppins'; letter-spacing: 0px; color: var(--primary__color); margin: 0;}
.dashboard ul li p.comments{margin: 5px 0;}
.dashboard ul li p.username{margin: 0;}
.dashboard ul li p.username svg{margin-right: 8px;}
.dashboard ul li .card-footer{background: var(--white_color); border-top: var(--text_color) 1px solid; padding: 10px 0 0; margin: 10px 0 0; background: none; border-radius: 0; display: flex; align-items: center; position: relative;}
.dashboard ul li .card-footer .dropdown{position: absolute; right: 0; top: 0;}
.dashboard ul li .card-footer .dropdown .dropdown-toggle{min-width: inherit; padding: 0;}
.dashboard ul li .card-footer .dropdown .dropdown-toggle:after{display: none;}
.dashboard ul li .card-footer .dropdown .dropdown-menu{background: var(--white_color) 0% 0% no-repeat padding-box; box-shadow: 5px 5px 20px #00000038; border: 2px solid #99B5C7; border-radius: 10px; padding: 0; width: 140px;}
.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item{padding: 7px 10px; font-size: 14px; line-height: 1; border-bottom: #99B5C7 2px solid;}
.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item:last-child{border: none;}
.dashboard ul li .card-footer .dropdown .dropdown-menu .dropdown-item:hover{color: var(--primary__color); background: none;}
.dashboard ul li .card-footer .dropdown .fa-ellipsis-v{cursor: pointer; position: absolute; right: 0; font-size: 18px; color: var(--primary__color); z-index: 1;}
.dashboard ul li p.refund-p span{margin-left: auto; font-weight: 500;}
.dashboard ul li .card-footer svg{margin-right: 5px;}
.dashboard ul li .card-footer .firstchar{background: rgba(0, 69, 116, 0.4); font-size: 14px; font-weight: 500; width: 20px; height: 20px; min-width: 20px; border-radius: 50%; color: var(--white_color); text-transform: uppercase; display: block; padding: 0 5px; display: flex; align-items: center; justify-content: center; margin-left: 5px; margin-right: 8px;}
.dashboard .list-view ul li p{margin: 0; min-width: 12%; padding: 0 20px 0 0;}
.dashboard .list-view ul li p.username{text-transform: capitalize;}
.dashboard .list-view ul li .card-footer .dropdown .fa-ellipsis-v{margin: 0; top: 3px;}
.dashboard .list-view ul li .card-footer .firstchar{margin-right: 0;}
.dashboard .list-view ul li p.refund-p span{margin-left: 10px;}
.ant-upload-list-item .ant-upload-list-item-card-actions-btn{opacity: 1;}
.consent-form.ant-form-vertical .ant-form-item{width: 100%;}
.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control{padding: 0;}
.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper{border: 1px solid #F1F1F1;}
.consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper .rdw-editor-toolbar{border: none; border-bottom: #F1F1F1 1px solid; display: none;}
.authority-letter{padding: 15px;}
.authority-letter.letter-page{max-width: 1170px; margin: 0 auto 30px; border: 1px solid #CCC;}
.authority-letter .auth-head{display: flex; flex-flow: wrap;}
.authority-letter .auth-head h2{color: #005B98; font-size: 26px; text-transform: uppercase; position: relative; font-weight: 600;}
.authority-letter .auth-head .auth-logo{margin: 0 50px 0 auto; color: var(--black__color); font-weight: 700; text-transform: uppercase; font-size: 30px; line-height: 1;}
.authority-letter.letter-page h2{margin: 0;}
.authority-letter.letter-page .auth-logo{margin-right: 0;}
.authority-letter .auth-head .auth-logo{height: 80px; width: auto;}
.authority-letter .auth-head .auth-logo .ant-image-mask{display: none;}
.authority-letter .auth-head p{font-weight: 700; margin: 0 0 30px; width: 100%;}
.authority-letter .auth-client-details{margin-bottom: 20px;}
.authority-letter .auth-client-details .client-detail-table{border: #333 1px solid; border-bottom: none;}
.authority-letter .auth-client-details .client-detail-table p{margin: 0; display: flex; flex-flow: wrap; border-bottom: #333 1px solid; color: #333; line-height: 30px; padding: 0 10px;}
.authority-letter .auth-client-details .client-detail-table p label{width: 32%; text-align: right; border-right: #333 1px solid; padding: 0 5px 0 0; margin-right: 5px; font-weight: 600;}
.authority-letter .auth-client-details .client-detail-table div{float: left; width: 100%;}
.authority-letter .auth-client-details .client-detail-table div p{float: left; display: block; width: 32%;}
.authority-letter .auth-client-details .client-detail-table div p:first-child{width: 55%;}
.authority-letter .auth-client-details .client-detail-table div p:last-child{width: 13%;}
.authority-letter .auth-client-details .client-detail-table div p:last-child label{width: auto;}
.authority-letter .auth-client-details .client-detail-table div p:first-child label{width: 59.1%;}
.authority-letter .auth-client-details .client-detail-table div.account-div p:first-child{width: 60%;}
.authority-letter .auth-client-details .client-detail-table div.account-div p:first-child label{width: 54%;}
.authority-letter .auth-client-details .client-detail-table div.account-div p:last-child{width: 40%;}
.authority-letter .office-use-only{margin-top: 50px;}
.authority-letter .office-use-only .client-detail-table{border: #333 1px solid; border-bottom: none;}
.authority-letter .office-use-only .client-detail-table p{margin: 0; display: flex; flex-flow: wrap; border-bottom: #333 1px solid; color: #333; line-height: 30px; padding: 0 10px;}
.authority-letter .office-use-only .client-detail-table p label{width: 40%; text-align: right; border-right: #333 1px solid; padding: 0 10px 0 0;}
.authority-letter .office-use-only .client-detail-table div{width: 100%; display: flex;}
.authority-letter .office-use-only .client-detail-table div p{width: 33.33%;}
.authority-letter .office-use-only .client-detail-table div p label{width: auto;}
.authority-letter h4, .authority-letter .auth-client-details h4{color: #001727; font-size: 20px; font-weight: 700; margin: 0 0 5px; line-height: 1; text-transform: uppercase;}
.authority-letter h4 strong{font-weight: 700;}
.authority-letter .signature-section{display: flex; flex-flow: wrap; grid-gap: 15px; margin-top: 50px;}
.authority-letter .signature-section .signature{width: calc(30% - 15px);}
.authority-letter .signature-section .print-name{width: calc(50% - 15px);}
.authority-letter .signature-section .date{width: calc(20% - 15px);}
.authority-letter .signature-section p{width: 100%; margin: 10px 0 0;}
.authority-letter .signature-section .signature-block i{font-style: normal; border-top: #333 1px solid; display: block; font-weight: 600;}
.authority-letter .signature-section .signature-block p{margin: 0; min-height: 23px;}
.authority-letter .authority-footer{margin-top: 30px; padding-top: 30px; border-top: #001727 2px solid; display: flex; flex-flow: wrap; grid-gap: 30px;}
.authority-letter .authority-footer p{margin: 0;}
.authority-letter .authority-footer div{width: calc(25% - 30px);}
.authority-letter .authority-footer .company-logo{width: auto; margin: 0 0 0 auto;}
.authority-letter .authority-footer .company-logo img{height: 50px;}
.printbtn{min-width: inherit !important; height: auto !important; line-height: 1 !important; width: 140px !important; font-size: 15px !important; display: inline-block !important; margin: 0 5px;}
.dashboard .list-view{height: calc(100vh - 230px); overflow: auto;}
.address-block{width: 100%; display: flex; flex-flow: wrap; grid-gap: 2%;}
.address-block h5{width: 100%;}
.popup-without-header .ant-modal-body{padding: 0 30px 30px !important;}
.popup-without-header .ant-modal-body h4{padding: 25px 0 10px; font-size: 18px;}
.popup-without-header .ant-modal-body .ant-picker-range{width: 100%; border-radius: 30px;}
body .modal-dialog.small-popup{max-width: 500px;}
body .modal-dialog.small-popup .modal-body{padding: 15px 0 0; /* min-height: 305px; */ text-align: center;}
body .modal-dialog.small-popup .modal-body .modal-body{padding: 0 15px 15px;}
body .modal-dialog.small-popup .modal-body .upload-div{padding: 0 20px 20px; width: 100%; text-align: center; margin: 0;}
body .modal-dialog.small-popup .modal-body .upload-div .upload-btn-div{justify-content: center !important;}
body .modal-dialog.small-popup .modal-body .or{position: relative; font-weight: 700; font-style: italic; width: calc(100% - 40px); text-align: center; z-index: 1;}
body .modal-dialog.small-popup .modal-body .or::before{width: 100%; background: #00000029; height: 1px; content: ''; position: absolute; left: 0; top: 50%; transform: translateY(-50%); z-index: -2;}
body .modal-dialog.small-popup .modal-body .or::after{content: ''; background-color: var(--white_color); height: 100%; z-index: -1; position: absolute; width: 40px; top: 0; height: 100%; left: calc((100% - 40px)/2);}
body .ant-input-affix-wrapper{border-radius: 30px;}
.authority-letter ul{margin: 0; padding: 0 0 0 15px;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-toggle{background: none; color: #00000080; padding: 0 0 0 15px; margin: 0;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-toggle::after{font-family: 'FontAwesome'; content: '\f05a'; border: none; background: none;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu{width: 340px; left: 0; background: #005B98; border: none;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu::before{font-family: 'FontAwesome'; content: '\f0d8'; position: absolute; top: -24px; font-size: 26px; left: 15px; color: #005B98;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a{background: none !important; font-size: 14px; white-space: normal; letter-spacing: normal; font-weight: 500; color: var(--white_color);}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a p{margin-bottom: 5px;}
.content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu a p:last-child{margin-bottom: 0;}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon{line-height: 14px;}

/***** 2FA Setup *****/
.content .ant-steps{padding: 50px 30px 30px; max-width: 600px; margin: 0 auto;}
.step-page-btn{text-align: center;}
.step-page-btn button{background: none !important; border: none; color: var(--black__color) !important; padding: 0; height: auto; text-transform: uppercase; font-weight: 700; line-height: 1;}
.step-page-btn button span{display: flex; grid-gap: 5px;}
.step-page-btn button span:after{content: '\f105'; font-family: 'FontAwesome';}
.content .steps-content{border-radius: 20px; max-width: 600px; margin: 0 auto 30px; padding: 30px; display: flex; box-shadow: #00000016 0 0 10px; flex-flow: wrap; align-items: center; justify-content: center; grid-column-gap: 10px;}
.content .steps-content .steps-content{margin: 0 !important; background: none; box-shadow: none; padding: 0;}
.content .steps-content h5{font-size: 16px; font-weight: 700; white-space: nowrap; margin: 0 0 15px; line-height: 1.2;}
.step1, .step2, .step3{align-items: stretch; grid-gap: 5px; margin-bottom: 15px;}
.content .steps-content p{color: var(--black__color); padding: 0; line-height: 1.2; margin: 0 0 15px; font-size: 16px;}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{display: flex; align-items: center; justify-content: center; height: 30px;}
.content .steps-content .ant-input{margin-bottom: 15px; border-radius: 30px;}
.content .steps-content button{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; border-radius: 30px; font: normal normal normal 18px/27px Poppins; border: none; text-transform: uppercase; padding: 0; font-size: 16px; padding: 10px 25px; height: auto; line-height: 1; text-transform: none; letter-spacing: normal; color: var(--white_color); font-weight: 500; min-width: inherit;}
.content .steps-content .ant-image-mask{display: none;}
.content .steps-content button:hover{background: transparent linear-gradient(180deg, #06488B 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;}
.firm-form .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail{display: flex; align-items: center; justify-content: center;}
.firm-form .ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail img{max-height: 120px; height: auto; width: auto; max-width: 120px;}
header .sitelogo{font-size: 12px; line-height: 1;}
header .sitelogo span{position: relative; bottom: 5px;}
/***** 2FA Setup *****/

@media(max-width: 1600px){
    body .full-content-block .ant-tabs .ant-tabs-content-holder .ant-tabs{margin-top: 30px !important;}
}
@media(max-width: 1366px){
    .content-details .entry-card .form-box{padding: 15px;}
    body .full-content-block .ant-tabs .ant-tabs-nav .ant-tabs-tab{min-width: inherit; height: 40px; border-radius: 15px 15px 0 0;}
}
@media(max-width: 992px){
    /* body header .header-nav{grid-gap: 20px; position: inherit; left: auto; transform: none;} */
    body .header-bottom-block .searchbar form input{min-width: 50%;}
    .content-details{padding: 20px;}
    body .login-img{height: auto;}
    .login-pages .ant-row{flex-direction: column-reverse;}
    .login-pages .ant-row .ant-col, body .login-form-block{width: 100%; min-width: 100%;}
}
@media print{
    .authority-letter .ant-image-mask, .ant-btn.btn, .header-blocks, .sidebar-nav, .ant-tabs-nav, .action-box, .page-title, .consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper .rdw-editor-toolbar{display: none !important;}
    .ant-tabs-content-holder, .content-details{padding: 0 !important;}
    .authority-letter.letter-page{max-width: 100% !important; margin: 0 !important; border: none !important; padding: 0 !important; overflow: visible !important; transform-origin: center top;}
    body .full-content-block{padding: 0 !important; margin: 0 !important;}
    .consent-form.ant-form-vertical .ant-form-item .ant-form-item-control .rdw-editor-wrapper{border: none !important;}
    .auth-head{display: none;}
    .authority-letter ul{padding: 0 !important; margin: 0 !important;}
}
body .modal-dialog.medium-popup{max-width: 480px;}
body .modal-dialog.medium-popup .content-details{text-align: center; padding-bottom: 30px;}
body .modal-dialog.medium-popup .action-box{align-items: center; justify-content: center; padding-bottom: 0;}
body .modal-dialog.medium-popup .action-box button{margin-right: 0;}
body .modal-dialog.medium-popup.review-popup .half-width{width: 100% !important; text-align: left;}
body .ant-form label{color: var(--black__color);}
body .ant-pagination-item, body .ant-pagination-prev, body .ant-pagination-next, body .ant-pagination-jump-prev, body .ant-pagination-jump-next{height: auto; line-height: 1;}
body .ant-table-pagination.ant-pagination a{line-height: 1;}
#customerForm .entry-card, #loginForm .entry-card{margin-bottom: 30px;}
.form-box.photos-section .ant-upload-list-picture:before, #loginForm .form-box .ant-upload-list-picture:before{background: #7DB5DB url(../images/blank-profile.webp) no-repeat center; background-size: 100%; width: 100px; height: 100px;}
.form-box.photos-section .ant-upload-list-picture span.ant-upload{width: 100%;}
.ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail{opacity: 1 !important; background: var(--white_color); border-radius: 50%;}
.firm-form .ant-form-vertical .ant-form-item{width: 100%;}
body .login-form.firm-form .input-boxes{width: 100%;}
body .login-form.firm-form form.ant-form .ant-form-item input[type=text]{position: relative; background-color: var(--white_color); border: 1px solid #d9d9d9; border-radius: 2px; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); width: 100%; height: 32px; padding: 0 11px; font-size: 14px; border-radius: 30px !important;}
body .login-form.firm-form form.ant-form .ant-form-item input[type=text]:hover{border-width: 1px !important;}
body .login-form.firm-form form.ant-form .ant-form-item input[type=text]:focus{border-color: #40a9ff !important;}
body .login-form.firm-form{min-width: 60%;}
body .login-form.firm-form .ant-form{width: 100%; width: 60%;}
body .login-form.firm-form .ant-form .firm-form-box{grid-gap: 15px; border: 2px solid #eee; border-radius: 10px; display: flex; flex-flow: wrap; padding: 15px; width: 100%; margin-bottom: 15px;}
body .login-form.firm-form .ant-form .firm-form-box h3{font-size: 1.25rem; width: 100%; margin: 0;}
body .login-form.firm-form .ant-form .firm-form-box .ant-form-item{text-align: left; width: calc(50% - 7.5px);}
body .login-form.firm-form .ant-form .firm-form-box .ant-form-item .ant-col{padding: 0;}
body .login-form.firm-form .ant-form .firm-form-box .ant-form-item .ant-col input[type=text]{min-width: 100%;}
.subscription-plan .col-lg-6 button{min-width: inherit; line-height: 1; padding: 10px 30px; color: var(--white_color); font-size: 14px;}
.subscription-plan .col-lg-6 button{background: #0077C770; clear: left; border-radius: 10px; width: 100%; padding: 15px; line-height: 1; font-size: 17px; box-shadow: 0 7.84314px 19.6078px rgb(73 204 249 / 30%); text-transform: none; margin: 0 0 25px; font-weight: 500;}
.subscription-plan .col-lg-6 button:hover{color: var(--white_color); background: var(--primary__color);}
.subscription-plan .payment-btn{display: flex; align-items: center; justify-content: center; grid-gap: 10px;}
.subscription-plan .payment-btn button{color: var(--white_color); height: auto; min-width: inherit; line-height: 1;}
.subscription-plan .payment-btn button:disabled{color: var(--black__color); border: #666 1px solid; font-weight: 500;}
.credit-card .ant-form-item-control{border: 1px solid #d9d9d9 !important; border-radius: 30px; padding: 0 10px !important;}
.congrats-block{display: flex; align-items: center; flex-direction: column; height: 100vh; justify-content: center;}
.congrats-block h3{background: url(../images/congrats.gif) no-repeat center top; padding: 190px 0 0;}
.congrats-block .btn{min-width: inherit; height: auto; padding: 10px 30px; line-height: 1; color: #FFF !important; font-size: 16px;}
.card-div{width: calc(100% - 30px); margin-top: 30px; box-shadow: rgba(0,0,0,0.35) 0px 5px 15px; border-radius: 10px;}
.card-right-content{float: right;}
.card{display: flex;}
.tab-div{display: flex;}
.subscription-plan .list-group-item{font-size: 13px; color: var(--black__color); border: none; font-weight: 500; padding: 3px 0;}
/* .subscription-plan .list-group-flush{min-height: 210px;} */
.subscription-plan .col-lg-6{background: var(--white_color); box-shadow: 0 15px 35px rgb(16 30 54 / 40%); border-radius: 12px; height: auto; width: 260px; border: none; margin: 0 15px 0; -webkit-box-flex: 0; border-top: none; border-bottom: 4px solid var(--primary__color);}
.subscription-plan .col-lg-6 .card-body{position: relative; padding-top: 3rem;}
.subscription-plan .col-lg-6 .card-body .coupon-title{position: absolute; top: 0; width: auto; background: var(--primary__color); margin: 0; color: var(--white_color); font-weight: 700; border-radius: 0 0 5px 5px; left: 50%; padding: 5px 15px; transform: translateX(-50%);}
.custom-tabs{display: flex; flex-flow: wrap; justify-content: center;}
.custom-tabs h6.monthly-tab::before{border: #FFF 1px solid; border-radius: 60px; height: 49px; content: ''; width: 235px; display: block; position: absolute; left: 0;}
.custom-tabs h6{position: relative; width: auto; max-width: inherit; margin: 0; cursor: pointer; padding: 0; font-size: 18px; width: 117px; height: 47px; text-align: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; align-items: center; display: flex; color: var(--white_color); border-radius: 60px;}
.custom-tabs h6.active{background: var(--white_color); color: #005B98;}
.custom-tabs > div{width: 100%; margin-top: 30px; /* position: absolute; */ top: 200px; float: left; /* flex-flow: wrap; */ max-width: 1200px; display: flex; text-align: center; justify-content: center;}
.custom-tabs > div p{width: 100%; margin-top: 15px; color: var(--black__color);}
.yearly{display: none;}
.supplier-modal .action-box{margin: 20px 0; justify-content: flex-end !important;}
.supplier-modal .modal-body button.reset{position: relative; right: 20px;}
.mid-modal .input-boxes{display: flex; flex-flow: wrap; grid-gap: 15px;}
.mid-modal .input-boxes .half-width{width: calc(50% - 7.5px);}
.mid-modal .input-boxes .full-width{width: 100%;}
.mid-modal .input-boxes .ant-row .ant-col{padding: 0;}
.promo-features{display: flex; align-items: center; justify-content: space-between; flex-direction: column;}
.promo-features p{margin: 0; text-transform: capitalize; display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 10px 0; border-top: #CCC 1px solid;}
.apply-block{font-weight: 700; font-size: 21px; margin-bottom: 5px; display: flex; align-items: center; justify-content: space-between; width: 100%; border-top: #CCC 1px solid;}
.apply-block strong{font-weight: 700;}
.promo-field{display: flex; align-items: center; width: 100%;}
.promo-field input{width: 73%;}
.promo-field button{padding: 9px 25px !important; font-size: 16px !important; height: auto; line-height: 1 !important; color: #FFF !important; font-weight: 500; background: #0077C770 !important; min-width: 25% !important; float: right;}
.promo-field button:disabled{font-size: 16px !important; font-weight: 500; cursor: not-allowed;}
.promo-field button:hover{color: var(--white_color);}
body .ant-btn.ant-btn-primary:disabled{color: #FFF !important;}
body .ant-btn.ant-btn-primary:disabled:hover{color: rgba(0,0,0,0.35) !important;}
.subscription-plan p{margin-bottom: 5px; font-weight: 400; color: var(--black__color);}
.subscription-plan p label{font-weight: 600;}
.subscription-plan h6{font-weight: 700;}
.firm-login .firm-form-box{background: var(--white_color); box-shadow: #00000073 0 10px 30px;}
.subscription-plan{background: url(../images/subscription-bg.png) no-repeat center top;}
.subscription-plan .ant-spin-dot-item{background-color: var(--white_color);}
.subscription-plan .spinloader{display: flex; width: 100vw; height: 50vh; align-items: center; justify-content: center;}
.subscription-plan .spinloader .ant-spin{transform: scale(2);}
/* .firm-login:after{background: url(../images/subscription-bg.png) no-repeat center top; position: absolute; right: 0; bottom: 0; content: ''; width: 50%; height: 40%; background-size: 100%; z-index: -1; transform: rotate(180deg);} */
.firm-login .ant-form-item .ant-checkbox-wrapper span{color: var(--white_color);}
.firm-login .ant-form-item .ant-checkbox-wrapper span a{color: var(--white_color); text-decoration: none; border-bottom: 1px dotted var(--white_color); position: relative; z-index: 1;}
.firm-login .action-box button{background: none; border: #FFF 2px solid; display: flex; align-items: center; justify-content: center;}
.firm-login .action-box button svg{margin-left: 10px;}
.custom-tabs > div.notice{background: #0077C720; font-weight: 500; color: var(--black__color); padding: 5px 10px; border: #0077C7 1px solid; border-radius: 5px; border-left-width: 5px; text-align: left; justify-content: flex-start;}
.content-details.description-block{padding-top: 10px;}
.content-details.description-block h3{color: var(--primary__color); font-size: 18px; font-weight: 700; margin-top: 20px;}
.content-details.description-block li h5{font-size: 16px; font-weight: 600; margin: 0;}
.refund-process .bank-reconcile-grid .refund-process-box .container .refund-top-details{margin-bottom: 0;}
.solid-btn{background: #0078C885 !important; color: var(--white_color); font-size: 12px; border-radius: 15px !important; padding: 8px 13px; display:  inline-block; line-height: 1;}
.ant-form-item-control-input .ant-select{width: 100%;}
.ant-pagination-jump-next .ant-pagination-item-container, .ant-pagination-jump-prev .ant-pagination-item-container{display: flex; align-items: center; justify-content: center;}
body .login-form-block.trans-login{background: none; color: var(--black__color) }
body .login-form-block.trans-login p a{color: var(--black__color) }
body .login-form-block.trans-login .login-form .sitelogo img{filter: none;}
body .login-form-block.trans-login .login-form .sitelogo span{color: var(--primary__color);}
body .login-form-block.trans-login .login-form form.ant-form .ant-form-item input{border-color: var(--text_color);}
body .login-form-block.trans-login button[type=submit], .trans-login .login-form form.ant-form .ant-form-item.confirmButton button{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, #06488B 100%) 0% 0% no-repeat padding-box; padding: 17px; min-width: 396px; height: auto; border: none; font-size: 20px; line-height: 1;}
body .login-form-block.trans-login button[type=submit]:hover, .trans-login .login-form form.ant-form .ant-form-item.confirmButton button:hover{background: transparent linear-gradient(180deg, #06488B 0%, var(--lightblue_color) 100%) 0% 0% no-repeat padding-box;}
body .login-form-block.trans-login .linkButton{color: var(--primary__color) !important;}
.trans-login .login-form form .auth-text{filter: none; color: var(--black__color) }
body .login-img.white-site{align-items: flex-start; justify-content: center; padding-top: 200px;}
body .login-img.white-site img{filter: brightness(100); margin: 0; max-width: 600px !important;}
.audit-statement .ant-table a{color: var(--primary__color);}
div#basiq-control{max-height: 420px; overflow: auto;}
body .full-content-block .firm-trust .ant-tabs .ant-tabs-content-holder{height: calc(100vh - 441px);}
div#basiq-control{max-height: 70vh;}
.review-popup .cdi-page-content, .cdi-subtitle{display: none;}
#basiq-control .vc-container{width: 100%;}
.content-details .setting-tabs .ant-table-content table thead tr th:first-child{border-top-left-radius: 0 !important;}
.content-details .setting-tabs .ant-table-content table{border-radius: 0 20px 20px 20px;}
body .full-content-block .ant-tabs.setting-tabs .ant-tabs-content-holder{height: calc(100vh - 380px);}
.icon-info{margin-left: 10px;}
.icon-info:before{display: none;}
.icon-info .nav-link{padding: 0 ;}
.icon-info .nav-link:after{border: none; float: left; margin: -4px 0 0 5px !important; background: url(../images/bulb-icon.jpg) no-repeat center; text-indent: -9999em; background-size: 100%; width: 30px; height: 40px;}
.icon-info .dropdown-menu{width: 25vw; padding: 0; margin-top: 5px;}
.icon-info .dropdown-menu .close{float: right; position: absolute; right: 10px; top: 10px;}
.icon-info .dropdown-item:active{background: #e9ecef;}
.icon-info .dropdown-item.information{white-space: normal !important; max-height: calc(100vh - 160px) !important; overflow: auto;}
.icon-info .dropdown-item p{color: #000 !important; font-size: 14px;}
.content-details .table-top-btn.trial-btn .dropdown-item:hover{color: #FFF;}
body .full-content-block .ant-tabs.trial-balance .ant-tabs-content-holder{height: calc(100vh - 382px);}
.table-top-btn .pdf-btn{margin-left: auto;}
.table-top-btn .pdf-btn a{min-width: inherit; font-size: 14px; color: #FFF; padding: 12px 25px; line-height: 1; text-transform: none; line-height: 1; margin-left: 10px;}
/**** ADMIN STYLE ****/
.admin-dashboard{display: flex; flex-flow: wrap; grid-gap: 30px; padding: 30px; background: #F0F2F9; height: calc(100vh - 114px); align-items: flex-start;}
.admin-dashboard .halfwidth{width: calc(33.33% - 10px);}
.admin-dashboard .admin-box{border-radius: 20px; padding: 30px; background: #FFF; box-shadow: #CCC 0 0 1px; position: relative; display: flex; flex-flow: wrap;}
.admin-dashboard .admin-box a{display: flex; flex-flow: wrap;}
.admin-dashboard .admin-box .icon{position: absolute; right: 30px; top: 50%; transform: translateY(-50%);}
.admin-dashboard .admin-box .icon svg{height: 7em; width: auto;}
/* .admin-dashboard .admin-box .icon svg path{fill: #ff6301;} */
.admin-dashboard .admin-box h4{color: #005B98; font-size: 18px; opacity: 0.3; width: 100%;}
.admin-dashboard .admin-box h2{width: 100%;}
.admin-dashboard .admin-box p{margin: auto 0 0;}
.table-btn .dropdown-menu{padding: 0;}
.content-details .table-top-btn .table-btn .dropdown-menu button{display: block; width: 100%; padding: 0; clear: both; font-weight: 400; color: #212529; text-align: inherit; text-decoration: none; white-space: nowrap; background: transparent; border: 0; line-height: normal; height: auto; font-size: 16px; margin: 0;}
.content-details .table-top-btn .table-btn .dropdown-menu .dropdown-item:hover button{color: #FFF;}
.add-all-clients{float: right; margin: 0 0 10px;}
.add-all-clients button{font-size: 12px; border: #E2E2E2 1px solid; background: rgba(0, 119, 199, 0.47); color: var(--white_color); text-transform: uppercase; padding: 0 15px; border-radius: 30px; font-weight: 600; height: 33px; line-height: 31px; margin-left: 5px; min-width: inherit;}
.add-all-clients button:hover{background: rgba(0, 119, 199, 1); color: #FFF;}
body .full-content-block.admin-table .ant-tabs .ant-tabs-content-holder{height: auto;}
.partial-registered th, .partial-registered td{white-space: nowrap;}
th.wrapword, td.wrapword{white-space: inherit !important;}
th.breakcolumn, td.breakcolumn{white-space: inherit !important;}
body .full-content-block.admin-table .content-details{padding: 30px;}
body .modal-dialog.medium-popup-bank{max-width: 850px;}
.medium-popup-bank .remove-content table{border: #CCC 1px solid; margin: 0 15px 15px; width: calc(100% - 30px); border-radius: 10px; border-collapse: inherit;}
.medium-popup-bank .remove-content tbody tr td{padding: 10px; border-right: #CCC 1px solid; border-bottom: #CCC 1px solid;}
.medium-popup-bank .remove-content tbody tr td.nodata{font-size: 18px; padding: 30px; border: none;}
.medium-popup-bank .remove-content thead tr th{padding: 10px; background: #0077C7; color: #FFF; color: #FFF; font-weight: 500; border-right: #FFF 1px solid;}
.medium-popup-bank .remove-content thead tr th:last-child{border-right: 0; border-top-right-radius: 10px;}
.medium-popup-bank .remove-content thead tr th:first-child{border-top-left-radius: 10px;}
.medium-popup-bank .remove-content tbody tr td:first-child{border-bottom-left-radius: 10px;}
.medium-popup-bank .remove-content tbody tr td:last-child{border-bottom-right-radius: 10px; border-right: none;}
.medium-popup-bank .remove-content tbody tr:last-child td{border-bottom: none;}
.medium-popup-bank .remove-content tbody tr{cursor: pointer;}
.medium-popup-bank .remove-content tbody tr:nth-child(2n) td{background: #F0F0F0;}
div[aria-labelledby="contained-modal-title-vcenter removebank-popup"]{background: rgba(0, 0, 0, 0.5);}
.react-bootstrap-table table thead th.expand-cell-header{font-size: 0; width: 50px; padding: 0;}
.react-bootstrap-table svg, .react-bootstrap-table i{cursor: pointer;}
.react-bootstrap-table table tbody tr td.reset-expansion-style{padding: 0;}
.react-bootstrap-table table tbody tr td.reset-expansion-style .content-details{padding: 10px; background: #EBEBEB !important; margin: 0; border-radius: 0;}
.react-bootstrap-table table tbody tr td.reset-expansion-style .content-details .table-top-btn{margin-bottom: 15px;}
.react-bootstrap-table table tbody tr td.reset-expansion-style .content-details table{margin: 0;}
/* .card-icons {
    position: relative;
    width: 100%;
} */

.card-icons{position: relative; width: 100%; display: flex; align-items: center; justify-content: center; gap: 15px;}
.card-icons svg{width: 20px !important; height: 20px; cursor: pointer;}