.disable-tfa-content .steps-content {
  border-radius: 20px;
  max-width: 600px;
  margin: 0 auto 30px;
  padding-top: 30px;
  padding: 30px;
  display: flex;
  box-shadow: #00000016 0 0 10px;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
}

.tfa-content .steps-content .ant-input, .tfa-change-content .change-steps-content .ant-input {
  margin-bottom: 15px;
  border-radius: 30px;
}

.tfa-content .ant-steps , .tfa-change-content .ant-steps{
  padding: 50px 30px 30px;
  max-width: 600px;
  margin: 0 auto;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #1890ff;
  color: white;
}

.tfa-content .steps-content h5 {
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  margin: 0 0 15px;
  line-height: 1.2;
}

.tfa-change-content .change-steps-content{
  border-radius: 20px;
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 30px;
  display: flex;
  box-shadow: #00000016 0 0 10px;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px
}

.tfa-change-content .change-steps-content:has(.disable-tfa-content){
  background: none;
  padding-top: 0px;
  box-shadow: none;
}

.tfa-change-content .change-steps-content .disable-tfa-content .steps-content{
  margin-top: 0px !important;
}

.change-steps-content {
  /* min-height: 200px; */
  margin-top: 16px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  background-color: #fafafa;
  /* border: 1px dashed #e9e9e9;
  border-radius: 2px; */
}


.tfa-content .steps-content {
  border-radius: 20px;
  max-width: 600px;
  margin: 0 auto 30px;
  padding: 30px;
  display: flex;
  box-shadow: #00000016 0 0 10px;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  grid-column-gap: 10px;
}

.steps-content {
  /* min-height: 200px; */
  margin-top: 16px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  background-color: #fafafa;
  /* border: 1px dashed #e9e9e9;
  border-radius: 2px; */
}

.AntContent {
  justify-content: center;
  border-radius: 25px;
}

.step1,
.step2,
.step3 {
  display: flex;
  justify-content: center;
}

.heading-step {
  padding-right: 5px;
}

.content-step1 {
  justify-content: center;
  align-items: center;
}
.steps-action {
  margin-top: 24px;
}

.primmary-btn {
  display: flex;
  /* width: 100%; */
  margin: 0 auto;
  border: none;
  background-color: #04aa6d;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

