@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
:root{--fontsize: 14px; --gap: 15px; --smallgap: 7px; --primary-color: #0078c8; --tabs-color: #88bfe5; --secondary-color: #012f4e; --white-color: #fff; --black-color: #000; --offwhite-color: #ccc; --text-color: #333; --fadebgcolor: #f2f2f2; --businessprimary-color: #42275a; --businesssecondary-color: #734b6d; --businesssupporting-color0: #af1763; --businesssupporting-color1: #0d6efd; --businesssupporting-color2: #198754; --businesssupporting-color3: #0dcaf0; --businesssupporting-color4: #ab2e3c; --businesssupporting-color5: #ffc107; --lime-color: #d4f717; --bodyfont: "Roboto", sans-serif; --businessfont: "Roboto", sans-serif;}
*, body .form-control{font-family: 'Roboto';}
*:focus-visible{outline: none;}
body{margin: 0; padding: 0; font-size: var(--fontsize) !important; width: 100%; height: 100%; font-family: var(--bodyfont) !important;}
body header .userprofile .show.dropdown{background: none;}
/* body header .userprofile .dropdown-menu{background: hsla(272, 40%, 25%, 1); border-color: ;} */
h1, .h1{font-size: calc(var(--fontsize) * 3);}
h2, .h2{font-size: calc(var(--fontsize) * 2.5);}
h3, .h3{font-size: calc(var(--fontsize) * 2.25);}
h4, .h4{font-size: calc(var(--fontsize) * 2);}
h5, .h5{font-size: calc(var(--fontsize) * 1.5);}
h6, .h6{font-size: calc(var(--fontsize) * 1.25);}
.form-control{border: var(--text-color) 2px solid; text-align: left; border-radius: 15px; line-height: 1; letter-spacing: normal; font-size: var(--fontsize); background: none !important; box-shadow: none; padding: 0 15px; height: auto;}
.form-control:focus{box-shadow: none; border-color: var(--text-color);}
button{background: transparent linear-gradient(180deg, var(--primary-color) 0, var(--secondary-color) 100%) 0 0 no-repeat padding-box; color: var(--white-color); border-radius: 30px; border: none; font-size: var(--fontsize); padding: var(--smallgap) var(--gap); line-height: 1; text-transform: uppercase; font-weight: 500; text-transform: uppercase;}
button:hover{background: transparent linear-gradient(180deg, var(--secondary-color) 0, var(--primary-color) 100%) 0 0 no-repeat padding-box;}
.btn-nolink{background: none; color: var(--primary-color); padding: 0;}
.btn-nolink:hover{background: none; color: var(--text-color);}
.login-screen{display: flex; flex-flow: wrap;}
.login-screen .welcome-block{background: transparent linear-gradient(180deg, var(--primary-color) 0, var(--secondary-color) 100%) 0 0 no-repeat padding-box; display: flex; height: 100vh; align-items: center; justify-content: center; width: 50%; position: relative;}
.login-screen .welcome-block h1{color: var(--white-color);}
.login-screen .form-block{width: 50%; align-items: center; display: flex; justify-content: center; flex-direction: column;}
.login-screen .form-block form{max-width: 396px; text-align: center; width: 100%;}
.login-screen .form-block form .form-control{padding: 15px 40px 15px 15px; border-radius: 10px; border-width: 3px; font-size: 14px;}
.login-screen .form-block .form-group > div{margin-bottom: var(--smallgap);}
.login-screen .form-block form button{margin-bottom: 10px; width: auto; border-radius: 50px; font-size: calc(var(--fontsize) * 1.25); margin-top: 0; padding: 10px 22px; text-transform: uppercase; line-height: 1;}
.login-screen .form-block form button[type="submit"]{color: white;}
.login-screen .form-block form .btn-nolink{font-size: var(--fontsize); margin-top: var(--gap);}
.login-screen .form-title-para{margin-bottom: var(--gap); background: url(../newimages/auth-mobile.png) no-repeat left center; min-height: 63px; padding-left: 50px; font-size: 18px; display: flex; align-items: center;}
.login-screen .form-block .footer-link{margin-top: auto; text-align: center; position: absolute; bottom: var(--gap);}
.login-screen .form-block .footer-link p{margin: 0 0 5px; font-size: 12px;}
.login-screen .form-block .footer-link p a{font-size: 12px; color: #000;}
*:focus, a:focus{outline: none;}
.Toastify .Toastify__toast-body{display: flex; font-size: 14px; gap: 7px;}
.Toastify .Toastify__toast-body .Toastify__toast-icon{width: 14px;}
.Toastify .Toastify__toast{display: flex; align-items: center; gap: 10px;}
.Toastify .Toastify__toast .Toastify__progress-bar{position: absolute; width: 100%; bottom: 0; left: 0;}
.Toastify .Toastify__close-button{background: no-repeat; padding: 0; width: 15px; float: right;}
.Toastify .Toastify__close-button:hover{background: no-repeat; color: #000; border: none; border-color: white !important; padding: 0px;}
.Toastify .Toastify__close-button svg{fill: #000;}
.h5, h5{font-size: 20px;}
.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th, .table-bordered{border-color: #ececec; border: none;}
.table{border-collapse: inherit; border-radius: 10px;}
.react-bootstrap-table table thead th{background: #f1f1f1; border-right: 1px solid #ececec; color: #0077c7; font-size: 14px; font-weight: 600; padding: 5px 10px; border-bottom: #ececec 1px solid;}
.react-bootstrap-table table thead th span.order{display: inline-flex; flex-direction: column-reverse; line-height: 0.4; position: relative; top: -4px; margin-left: 3px;}
.react-bootstrap-table table thead th .order .caret{cursor: pointer;}
.react-bootstrap-table table thead tr th:first-child{border-top-left-radius: 10px; border-left: #ececec 1px solid;}
.react-bootstrap-table table thead tr th:last-child{border-top-right-radius: 10px;}
.react-bootstrap-table table tbody tr td{border-bottom: #ececec 1px solid; border-right: 1px solid #ececec; padding: 5px 10px; word-break: break-word; background: #fff; font-size: 12px; color: #000; vertical-align: middle;}
.react-bootstrap-table table tbody tr:nth-child(2n) td{background: #FAFAFA;}
.react-bootstrap-table table tbody tr td:last-child{white-space: nowrap;}
.react-bootstrap-table table tbody tr td .transaction-report-heading{text-align: left; font-weight: 600; font-size: 14px;}
.react-bootstrap-table table tbody tr td:has(.report_blank_data.report-total){font-weight: 600; font-size: 14px;}
.react-bootstrap-table table tbody tr td .general-ledger-report-heading{text-align: left; font-weight: 600; font-size: 14px;}
.react-bootstrap-table table tbody tr td:has(.general_ledger_report_blank_data){border-right: none;}
.react-bootstrap-table table tbody tr td:has(.pl-report-type-heading){font-weight: 600; font-size: 15px;}
.react-bootstrap-table table tbody tr td a{color: #000;}
.react-bootstrap-table table tbody tr:hover td{background: #fafafa;}
.react-bootstrap-table table tbody tr td:first-child{border-left: #ececec 1px solid;}
.react-bootstrap-table table tbody tr:last-child td:first-child{border-bottom-left-radius: 10px;}
.react-bootstrap-table table tbody tr:last-child td:last-child{border-bottom-right-radius: 10px;}
.react-bootstrap-table-pagination-list{text-align: right;}
.table-pagination{display: flex; justify-content: space-between; align-items: center;}
.table-pagination .limiter{font-size: 12px;}
.table-pagination .limiter select{background: none; border: none; font-size: 12px;}
.table-pagination .pager{margin: 0; border: none; border-radius: 0; display: flex; gap: 15px;}
.table-pagination .pager ul{display: flex; gap: 15px;}
.table-pagination .pager:before, .table-pagination .pager:after{display: none;}
.table-pagination .pager ul{order: 0;}
.table-pagination .pager button:last-child{order: 1;}
.table-pagination .pager ul > li > button, .table-pagination .pager button{border: none; border-radius: 0; color: #999; font-size: 14px; background: none !important; padding: 0;}
.table-pagination .pager ul{margin: 0; padding: 0;}
.table-pagination .pager button:hover, .table-pagination .pager ul > li.active > button, .table-pagination .pager ul > li:hover > button{color: #000 !important; background: none !important;}
.table-pagination .pager ul li{line-height: 1;}
body form .form-group{margin: 0;}
.spinner{width: 30px; height: 30px; background-size: 200px; margin: 0 auto; display: block; border: 3px solid var(--black-color); border-radius: 50%; border-top: 3px solid var(--white-color); -webkit-animation: spin 2s linear infinite; animation: spin 2s linear infinite;}
@-webkit-keyframes spin{0%{-webkit-transform: rotate(0deg);}
  100%{-webkit-transform: rotate(360deg);}
}
@keyframes spin{0%{transform: rotate(0deg);}
  100%{transform: rotate(360deg);}
}
body form .form-group input, body form .form-group select, body form .form-group textarea{background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-radius: 2px; box-sizing: border-box; color: rgba(0, 0, 0, 0.85); display: inline-block; font-size: 12px; line-height: 1; list-style: none; margin: 0; min-width: 0; padding: 7px 10px; position: relative; transition: all 0.3s; width: 100%; border-radius: 5px;}
body form .form-group input[type="checkbox"], body form .form-group input[type="radio"]{width: auto;}
.form-group .selectbox__control{background-color: #fff; background-image: none; border: 1px solid #d9d9d9; border-radius: 2px; color: rgba(0, 0, 0, 0.85); box-sizing: border-box; font-size: 12px; line-height: 1; list-style: none; margin: 0; height: 30px; min-width: 0; padding: 0 !important; position: relative; transition: all 0.3s; width: 100%; border-radius: 5px; min-height: 30px;}
.content-block{width: 100%; background: white; border: 1px solid black; box-sizing: border-box; margin: 30px 15px; border-radius: 10px;}
.invoice-detail-body{padding: 0px 20px 20px 20px;}
.content-details-header{width: 100%; background: rgba(242, 242, 242, 0.5); padding: 7px 20px; box-sizing: border-box; margin-bottom: 20px; border-top-left-radius: 10px; border-top-right-radius: 10px;}
.content-details-header h6{margin: 0;}
.invoice-btn{font-size: 14px; padding: 20px 3px 20px 3px; height: 30px;}
.invoice-apply-btn{font-size: 14px !important; padding: 9px 25px !important; height: 33px; margin-left: 20px;}
.invoice-gst-title{text-align: right; color: grey; font-size: 18px;}
.invoice-payable-title{text-align: right; font-size: 20px; font-weight: 600;}
.invoice-payment-amount{width: fit-content; border-radius: 30px; border: 1px solid #d9d9d9; padding: 7px 11px; margin-left: 10px;}
.invoice-discount-body{display: flex; align-items: flex-end; justify-content: space-between; margin-top: 10px;}
.invoice-discount-form{display: flex; align-items: flex-end;}
.invoice-body{display: flex; align-items: center; margin-bottom: 30px !important;}
.invoice-discount{text-align: right; font-weight: 600;}
.invoice-discount-input{display: flex; flex-direction: column; margin-right: 20px;}
.invoice-data-space{margin-right: 10px;}
.invoice-add-payment{text-decoration: underline; cursor: pointer; color: var(--businessprimary-color); font-weight: 600;}
.invoice-title-body{display: flex; align-items: center;}
.invoice-preview-eye{color: var(--businessprimary-color);}
.invoice-preview-template{margin-left: 8px; font-weight: 600; color: var(--businessprimary-color);}
.invoice-title{color: var(--businessprimary-color); font-weight: 600;}
.invoice-preview-title{color: var(--businessprimary-color); font-weight: 500; margin-right: 5px;}
.invoice-modal-body{padding: 20px;}
.invoice-modal-data{display: flex; flex-wrap: wrap; gap: 15px;}
.invoice-save{text-align: right; width: 100%; margin-bottom: 20px;}
.modal-footer{text-align: center;}
.modal-footer button{color: #fff;}
.modal-footer button.btn-secondary{background: #333 !important; border: 1px solid #333;}
.modal-footer button.btn-secondary:hover{background: none !important; color: #333 !important; border: 1px solid #333;}
body form .form-group label{align-items: center; color: #000; display: inline-flex; font-size: 14px; max-width: 100%; line-height: 1; position: relative; font-weight: normal; font-weight: 500;}
@media (min-width: 992px){
  .header-nav .navbar{margin: 0; border: none; border-radius: 0; display: flex; align-items: center;}
  .navbar-nav{display: flex; padding: 0; column-gap: 47px;}
  .navbar .navbar-toggler{display: none;}
}
.content-details{overflow: inherit; padding: 0; background: none;}
.content-details .entry-card h3{background: none; border-radius: 0; color: var(--businessprimary-color); border-left: var(--businessprimary-color) 3px solid; padding: 10px 15px; margin-bottom: 15px;}
.content-details .entry-card .form-box{gap: 10px; padding: 0;}
.content-details .entry-card .form-box .half-width.profile-photo{display: flex; width: 122px; height: auto; flex-flow: wrap; position: relative;}
.content-details .entry-card .form-box .half-width.profile-photo input[type="file"]{width: 122px; height: 122px; border-radius: 50%; cursor: pointer; padding: 0; background: url(../newimages/blank-profile.7a6a6c54279a54d1977c.webp) no-repeat !important; background-size: 100% !important; -webkit-appearance: none; appearance: none; -moz-appearance: none;}
.content-details .entry-card .form-box .half-width.profile-photo.logo input[type="file"]{width: 122px; height: 122px; border-radius: 50%; cursor: pointer; padding: 0; background: url(../newimages/no-image.png) no-repeat !important; background-position: center !important; -webkit-appearance: none; appearance: none; -moz-appearance: none;}
.content-details .entry-card .form-box .half-width.profile-photo input[type="file"]::-webkit-file-upload-button{visibility: hidden;}
.content-details .entry-card .form-box .half-width.profile-photo .previewImage img{width: 122px; height: 122px; border-radius: 50%; position: relative; left: 0; top: 0; background: #fff; border: 1px solid #ccc; background-size: 100% !important;}
.content-details .entry-card .form-box .half-width.profile-photo .guide_leb{margin-top: 3px; font-size: 13px; width: 100%; text-align: center; width: 122px; height: 122px; background: var(--primary-color); color: var(--white-color); display: flex; align-items: center; justify-content: center; font-size: 13px; border-radius: 50%;}
.business-section .content-details .entry-card .form-box .half-width.profile-photo .guide_leb{background: var(--businessprimary-color);}
.spinner-border.full-width{background: #ffffff10; position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 5; display: flex; align-items: center; justify-content: center;}
.content-details .entry-card .form-box .half-width.profile-photo .remove-image{background: var(--businesssupporting-color4); color: #fff; display: flex; font-size: 12px; align-items: center; justify-content: center; cursor: pointer; gap: 5px; padding: 5px 7px; border-radius: 4px; margin-top: 5px;}
.nav.nav-tabs{border: none; overflow: auto; display: flex;}
.nav.nav-tabs > li{margin-bottom: 0;}
.nav.nav-tabs li a{background: var(--white_color); border: 1px solid #f1f1f1; border-radius: 10px 10px 0 0; color: var(--black__color); font-size: 14px; font-weight: 500; height: 35px; justify-content: center; margin: 0 5px 0 0; min-width: 149px; padding: 0 15px; text-align: center; display: flex; align-items: center; cursor: pointer;}
.nav.nav-tabs li:hover a, .nav.nav-tabs li.active a{color: var(--white-color); background: var(--tabs-color);}
.tab-content{background: var(--fadebgcolor); padding: var(--gap); border-radius: var(--gap); border-top-left-radius: 0;}
.tab-content .tab-pane *:first-child{margin-top: 0; padding-top: 0;}
.tab-content .tab-pane *:last-child{margin-bottom: 0; padding-bottom: 0;}
.business-section header{background: transparent linear-gradient(180deg, var(--businessprimary-color) 0%, var(--businesssecondary-color) 100% ) 0% 0% no-repeat padding-box;}
.business-section button{background: var(--businessprimary-color); color: var(--white-color); border: none; line-height: 1; border: 1px solid transparent; border-radius: 30px;}
.business-section button.headerbtn{font-size: 12px; padding: 9px 15px; border-radius: 5px; line-height: 1;}
.business-section button:hover, .business-section button:focus, .business-section button:visited, .business-section button:active{background: var(--white-color); color: var(--businessprimary-color) !important ; border-color: var(--businessprimary-color);}
.business-section button.reset:hover, .business-section button.reset:focus, .business-section button.reset:visited, .business-section button.reset:active{background: var(--white-color); color: var(--businessprimary-color) !important ; border-color: var(--businessprimary-color);}
body.business-section header .userprofile .dropdown-menu{background: var(--businesssecondary-color); border-color: var(--businesssecondary-color);}
body.business-section header .userprofile .show.dropdown{background: transparent;}
.business-section .content-details .table-top-btn .table-btn button, .business-section .content-details .table-top-btn .table-btn .button{background: var(--businessprimary-color); border: 1px solid transparent;}
.business-section .content-details .report-header-row .summary-filter{margin-top: 0px !important; margin-bottom: 0px;}
.business-section .content-details .report-header-row .report-name{font-size: 20px; font-weight: 500;}
.business-section .content-details .report-header-row .report-period-name{font-size: 20px; font-weight: 500; margin-left: 10px;}
.business-section .content-details .table-top-btn .table-btn button:active, .business-section .content-details .table-top-btn .table-btn .button:active, .business-section .content-details .table-top-btn .table-btn button:focus, .business-section .content-details .table-top-btn .table-btn .button:focus, .business-section .content-details .table-top-btn .table-btn button:hover, .business-section .content-details .table-top-btn .table-btn .button:hover{background: var(--white-color); color: var(--businessprimary-color); border-color: var(--businessprimary-color);}
.business-section .react-bootstrap-table table thead th{color: var(--businessprimary-color);}
.react-bootstrap-table table tbody tr td.reset-expansion-style .content-details
  .react-bootstrap-table
  table
  thead
  th{border-radius: 0 !important; padding: 7px 10px 5px; background: #fff; border-top: #ececec 1px solid;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .content-details
  .react-bootstrap-table
  table
  tbody
  tr
  td{border-radius: 0 !important;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .content-details
  .react-bootstrap-table
  table
  tbody
  tr
  td
  p{margin: 0;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .table-pagination{margin: 15px 0 5px;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .table-btn
  button{height: 30px; line-height: 1; font-size: 14px;}
.business-section header .header-nav li .dropdown-menu{background: var(--businesssecondary-color); border-color: var(--businesssecondary-color);}
.react-bootstrap-table table tbody tr td.react-bs-table-no-data{text-align: center; font-size: 18px;}
.react-bootstrap-table table tbody tr td.react-bs-table-no-data:before{color: var(--businesssupporting-color4); content: "\f071"; font-family: "FontAwesome"; font-size: 18px; display: block; width: 100%;}
.add-business-client .form-box{align-items: flex-start;}
.add-business-client .form-box .form-box-half{width: calc(50% - 5px); display: flex; flex-flow: wrap; gap: 10px;}
body.business-section .modal-dialog .modal-header .close{background: var(--businessprimary-color); border: var(--businessprimary-color) 1px solid;}
body.business-section .modal-dialog .modal-header .close:hover{background: none; color: var(--businessprimary-color);}
body.modal-open .business-section .modal{background: rgba(0, 0, 0, 0.85);}
body.business-section .modal-body .modal-content-details p strong, body
  .business-section
  .modal-body
  .modal-content-details
  p.deceased:first-letter{color: var(--businessprimary-color);}
.business-section .nav.nav-tabs li:hover a, .business-section .nav.nav-tabs li.active a{background: var(--businesssecondary-color); cursor: pointer; border-color: var(--businessprimary-color);}
.text-danger{color: #a94442 !important; font-size: 14px !important;}
body.business-section .full-content-block .page-title p{color: var(--businessprimary-color);}
.integration-card h3{color: var(--businessprimary-color);}
.integration-card .card-body{justify-content: flex-start;}
.integration-card .card-body .btn{height: auto; min-width: inherit; color: var(--white_color); font-size: 12px; line-height: 1; padding: 9px 10px; opacity: 1 !important; visibility: visible !important; display: block !important; background: var(--businessprimary-color); border: 1px solid transparent;}
.integration-card .card-body .btn:hover{background: var(--white-color); color: var(--businessprimary-color); border-color: var(--businessprimary-color);}
.integration-card .card-body .crm-img{display: flex; align-items: center; justify-content: center;}
.integration-card .card-body .crm-img img{width: 80px; height: 80px;}
body.business-section .modal-body .modal-content-details .profile h5{color: var(--businessprimary-color);}
body.business-section .modal-body .modal-content-details .profile > span{background: var(--businessprimary-color);}
.content-details .table-top-btn{border: none;}
.content-details .firm-details + .table-top-btn{border: none; border-bottom: #f1f1f1 1px solid; margin: 0 0 15px;}
.content-details .table-top-btn .nav.nav-tabs{/* margin-bottom: -20px; */ margin-bottom: -1px;}
.content-details .tab-table .table-top-btn{margin-bottom: 10px; border: none;}
.content-details .table-top-btn .nav.nav-tabs::-webkit-scrollbar{height: 5px;}
.content-details .table-top-btn + div.tab-table{background: var(--white-color); border-radius: 0 10px 10px 10px; padding: 0; /* border: 1px solid #f1f1f1; */
  border: none; float: left; width: 100%; margin-top: -1px;}
.content-details .table-top-btn + div.tab-table .form-box{padding: 0; background: none;}
.myprofile-card{display: flex; gap: 30px; margin-bottom: 0;}
.content-details .search-section + .table-btn button{/* height: 33px; */
  /* line-height: 31px; */
  /* font-size: 14px; */
  text-transform: uppercase; font-weight: 500; /* padding: 0 20px; */
}
.close{text-shadow: none;}
.modal-open .modal{opacity: 1;}
.modal-open .modal .small-review{left: 50%; top: 50%; transform: translate(-50%, -50%); margin: 0;}
.modal-open .modal .small-review button.btn-close{width: 30px; height: 30px; background: var(--black-color); padding: 0; position: absolute; right: 15px; color: var(--white-color); border: none; border-radius: 8px; display: flex; align-items: center; justify-content: center;}
.te-divider{margin: 10px 0;}
.required{color: var(--businesssupporting-color0);}
label .required{font-weight: bold;}
body .modal-dialog .modal-body #container-fastlink{overflow: auto; height: 75vh;}
.insecure-content{padding: 3em;}
.insecure-content svg{height: 30px; fill: #333;}
.insecure-content .icon{border: 3px solid #333; width: 70px; height: 70px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-bottom: 20px;}
.insecure-content h2{font-size: 30px;}
.insecure-content p{font-size: 14px; max-width: 580px;}
.insecure-content a{min-width: inherit; color: var(--white_color); border-radius: 7px; text-transform: none; background: var(--blue_color); line-height: 1; padding: 12px 20px; font-size: 14px; text-shadow: none;}
.insecure-content a:hover{background: var(--primary__color); color: var(--white_color);}
body.business-section header .userprofile .btn{background: transparent;}
body.business-section header .userprofile .btn span{color: var(--businessprimary-color);}
body.business-section header .userprofile .btn:hover, body.business-section header .userprofile .btn[aria-expanded="true"]{background: var(--businesssecondary-color); color: var(--white-color) !important;}
.dashboard-container{background: #f2f2f2; padding: 20px 15px; margin: 0 -15px;}
.dashboard-container .row{display: flex; gap: 15px; flex-flow: wrap; margin: 0;}
.dashboard-container .row::before, .dashboard-container .row::after{display: none;}
.dashboard-container .col-md-6{width: calc(50% - 7.5px); padding: 0;}
.dashboard-container .col-md-8{width: calc(66.66% - 7.5px);}
.dashboard-container .col-md-4{width: calc(33.33% - 7.5px);}
.dashboard-container .card{background: var(--white-color); border-radius: 10px; box-shadow: #00000016 0 3px 6px; padding: 10px; float: none;}
.dashboard-container .active-cards{padding: 0;}
.dashboard-container .active-cards .card:hover{/* background: var(--businessprimary-color); */
  background: transparent linear-gradient( 180deg, var(--businessprimary-color) 0%, var(--businesssecondary-color) 100% ) 0% 0% no-repeat padding-box; /* background: hsla(272, 40%, 25%, 1);

background: linear-gradient(90deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%);

background: -moz-linear-gradient(90deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%);

background: -webkit-linear-gradient(90deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); */
}
.dashboard-container .card h5{font-weight: 500; font-size: 24px; margin: 0; line-height: 1;}
.dashboard-container .card .card-content{width: 100%;}
.dashboard-container .card .card-content .card-header{width: 100%; display: flex; align-items: center; margin-bottom: 20px;}
.dashboard-container .card select{border: #dfdfdf 1px solid; border-radius: 5px; font-size: 14px; width: 133px; padding: 2px 15px 2px 5px; line-height: 1; margin: 0 10px 0 auto; color: var(--black-color);}
.dashboard-container .card .rs-picker .rs-picker-toggle{display: flex; border: #dfdfdf 1px solid; border-radius: 5px; font-size: 14px; padding: 0 10px; line-height: 1; align-items: center; height: 28px; background: #fff !important; color: var(--businessprimary-color);}
/* .dashboard-container .card .rs-picker .rs-picker-toggle span{display: none;} */
.dashboard-container .card .rs-picker .rs-picker-toggle input{border: none; font-size: 14px; color: var(--black-color); background: none;}
.rs-anim-fade.rs-picker-daterange-menu{position: absolute;}
.content-details.refund-process{margin: 0; overflow: inherit;}
.rs-picker-menu{background-color: #fff; border-radius: 6px; -webkit-box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%); box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%); overflow: hidden; position: absolute; text-align: left; -webkit-transition: none; transition: none; z-index: 7;}
.rs-picker-toolbar{border-top: 1px solid #e5e5ea; padding: 12px;}
.rs-picker-menu .rs-picker-toolbar{max-width: 100%;}
.rs-picker-daterange-menu .rs-picker-toolbar{margin-top: 4px; display: flex; align-items: center;}
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-ranges{display: inline-block;}
.rs-picker-toolbar-right{float: right; margin-left: auto;}
.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month{opacity: 0.5;}
.rs-btn{background: #f7f7fa; border: none; border-radius: 6px; color: #575757; cursor: pointer; display: inline-block; font-size: 14px; font-weight: 400; line-height: 20px; margin-bottom: 0; overflow: hidden; padding: 8px 12px; position: relative; text-align: center; text-decoration: none; -webkit-transition: color 0.2s linear, background-color 0.3s linear; transition: color 0.2s linear, background-color 0.3s linear; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; vertical-align: middle; white-space: nowrap; border: 1px solid transparent;}
.business-section .rs-btn{background: #f7f7fa; border: none; border-radius: 5px; color: #000; cursor: pointer; display: inline-block; font-size: 12px; font-weight: 400; line-height: 1; margin-bottom: 0; overflow: hidden; padding: 8px 12px; position: relative; text-align: center; text-decoration: none; -webkit-transition: color 0.2s linear, background-color 0.3s linear; transition: color 0.2s linear, background-color 0.3s linear; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; vertical-align: middle; white-space: nowrap; border: 1px solid transparent;}
.rs-btn:hover{background: var(--white-color); color: var(--businessprimary-color); border-color: var(--businessprimary-color);}
.business-section .rs-btn-link:hover{border: none;}
.content-details .summary-filter > div, .rs-btn.rs-picker-toggle{border: none; background: var(--businesssecondary-color); display: flex; align-items: center; gap: 10px; font-weight: 500; color: var(--white-color); padding: 5px 10px; height: 30px;}
.___SCalendarUnit_w6cba_gg_{padding: 5px;}
.___SButton_1c1ei_gg_._theme_tertiary-muted_1c1ei_gg_{background: none; border: var(--businessprimary-color) 1px solid;}
.___SPeriod_yu978_gg_{gap: 5px;}
.___SText_1c1ei_gg_._size_l_1c1ei_gg_{margin: 0 !important; width: 30px; height: 30px; justify-content: center; align-items: center;}
.___SButton_1c1ei_gg_._theme_tertiary-muted_1c1ei_gg_{width: auto; height: auto;}
.___SCalendarUnit_w6cba_gg_.__endSelected_w6cba_gg_, .___SCalendarUnit_w6cba_gg_.__startSelected_w6cba_gg_{background: var(--businesssecondary-color) !important;}
.___SCalendarUnit_w6cba_gg_.__selected_w6cba_gg_{background: var(--businessprimary-color) !important; color: var(--white-color) !important;}
.___SHeader_yu978_gg_{margin-bottom: 10px;}
.___SButton_1c1ei_gg_._theme_primary-info_1c1ei_gg_{background: var(--businessprimary-color) !important; border: var(--businessprimary-color) 1px solid;}
.___SButton_1c1ei_gg_._theme_primary-info_1c1ei_gg_:hover, .___SCalendarUnit_w6cba_gg_.__highlighted_w6cba_gg_{background: var(--white-color) !important; color: var(--businessprimary-color) !important;}
.___SText_1dsbi_gg_.__placeholder_1dsbi_gg_{color: var(--white-color) !important;}
.___SBaseTrigger_1dsbi_gg_._size_m_1dsbi_gg_{height: auto !important; padding: 8px !important;}
.rs-btn.rs-picker-toggle .rs-picker-toggle-textbox{display: none;}
.rs-btn-sm{font-size: 14px; line-height: 20px; padding: 5px 10px;}
.rs-picker-daterange-header{border-bottom: 1px solid #e5e5ea; font-size: 14px; line-height: 1.42857143; padding: 8px 12px; color: #FFF; background: var(--businesssecondary-color); font-weight: 700;}
.rs-btn-link{background: none !important; border: none; color: var(--businessprimary-color); font-weight: 500; text-transform: none; padding: 0; margin: 0 15px 0 0;}
.rs-btn-link:hover{text-decoration: underline;}
.rs-picker-daterange-calendar-group{height: auto; display: flex; min-width: inherit;}
.rs-calendar{min-height: inherit; overflow: hidden; padding-top: 12px; position: relative;}
.rs-picker-menu .rs-calendar{display: block; margin: 0 auto; min-width: 200px;}
.rs-picker-daterange-menu .rs-calendar{display: inline-block; height: auto; padding: 10px;}
.rs-picker-daterange-menu .rs-calendar:first-child{border-right: 1px solid #e5e5ea;}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time){margin: 0 auto; padding-left: 12px; padding-right: 12px;}
.rs-picker-daterange-menu .rs-calendar-header{text-align: center; width: 100%; padding: 0 0 10px;}
.rs-calendar-view{padding: 4px 12px 12px;}
.rs-calendar-table{display: table; table-layout: fixed; width: 100%;}
.rs-picker-menu .rs-calendar-table{width: unset;}
.rs-calendar-table-row{display: table-row;}
.rs-calendar-table-cell, .rs-calendar-table-header-cell{display: table-cell; padding: 1px 0; text-align: center; vertical-align: middle; width: 1%;}
.rs-calendar-table-cell.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content{background: var(--businessprimary-color); color: var(--white-color);}
.rs-calendar-table-cell.rs-calendar-table-cell-in-range
  .rs-calendar-table-cell-content{color: var(--white-color); background: var(--businesssecondary-color);}
.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-start
  .rs-calendar-table-cell-content{border-radius: 5px 0 0 5px !important;}
.rs-calendar-table-cell.rs-calendar-table-cell-selected.rs-calendar-table-cell-selected-end
  .rs-calendar-table-cell-content{border-radius: 0 5px 5px 0 !important;}
.rs-calendar-table-cell span, .rs-calendar-table-header-cell span{font-size: 12px;}
.rs-calendar-header-month-toolbar{display: flex;}
.rs-calendar-header-month-toolbar .rs-calendar-header-title{margin: 0 auto; font-weight: 500;}
.rs-calendar-header-month-toolbar button{background: none !important;}
.rs-calendar-table-row.rs-calendar-table-header-row span{font-weight: 700;}
.active-cards .active-date{margin: 0; font-size: 14px; border-top: #707070 1px solid; color: var(--businessprimary-color); font-weight: 500; padding: 10px 0 0; line-height: 1;}
.dashboard-container .active-cards .card:hover .active-date{color: var(--white-color); border-color: var(--white-color);}
.active-cards .card-header{flex-flow: wrap;}
.dashboard-container .active-cards .card:hover{cursor: pointer;}
.active-cards .card-header h6{font-size: 21px; color: #727272; font-weight: 400; margin: 0 0 10px;}
.active-cards .card-header h4{color: var(--black-color); font-size: 43px; display: block; width: 100%; margin: 0;}
.active-cards .card-header i{background: url(../newimages/feather-users.png) no-repeat center #e5e2e2; width: 55px; height: 55px; position: absolute; right: 10px; top: 10px; border-radius: 5px;}
.firm-details .firm-summary h5, .firm-details .firm-summary h6{color: var(--businessprimary-color);}
.dashboard-container .active-cards .card:hover h6, .dashboard-container .active-cards .card:hover h4{color: var(--white-color);}
.dashboard-container .active-cards .card:hover .card-header i{background-color: var(--fadebgcolor);}
.refund-process .table-top-btn h2{font-size: 20px; color: #000; font-weight: 500;}
.refund-process .form-check{margin-bottom: 0; display: flex; align-items: center;}
.refund-process .form-check input[type="checkbox"]{margin-right: 10px; width: 16px; height: 16px; margin-top: 0;}
.refund-process .form-check label{font-size: 14px; margin: 0;}
.refund-process .form-box{padding-bottom: 10px;}
.refund-process .refund-process-box{background: #42275a05; border-radius: 10px; padding: 30px; margin-bottom: 20px; border: var(--businessprimary-color) 1px solid;}
.refund-process .refund-process-box.matched{border-color: #0da500;}
.refund-process .refund-process-box.red{border-color: #f00;}
.refund-process .refund-process-box .container{padding: 0; margin: 0; max-width: 100%; width: 100%;}
.refund-process .refund-process-box .container .table-top-btn{background: #eee; margin-top: 20px; padding: 10px;}
.refund-process
  .refund-process-box
  .container
  .table-top-btn
  input[type="text"]{border: none; min-height: 34px; min-width: 700px; padding: 0 10px; background: #fff url(../../assets/images/search-black.png) no-repeat 98% center;}
.refund-process .refund-process-box .container .table-top-btn .btn{background: #777; min-width: 90px; height: 34px; line-height: 1; padding: 0; font-size: 14px;}
.refund-process .refund-process-box .container .table-top-btn .btn:hover{background: #012f4e;}
.refund-process .refund-process-box .container .ant-table-wrapper{margin: 0;}
.refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table{border-radius: 0; border: none;}
.refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  thead
  th{background: #fff; padding: 10px 20px; width: auto;}
.refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  thead
  tr
  th:nth-child(4){width: 30%;}
.refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  thead
  tr
  th:nth-child(5), .refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  tbody
  tr
  td:nth-child(5){text-align: right;}
.refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  thead
  tr
  th:first-child, .refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  thead
  tr
  th:last-child, .refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  tbody
  tr:last-child
  td:first-child, .refund-process
  .refund-process-box
  .container
  .ant-table-wrapper
  .ant-table-content
  table
  tbody
  tr:last-child
  td:last-child{border-radius: 0 !important;}
.refund-process .refund-process-box .container .refund-top-details{margin-bottom: 60px;}
.refund-process
  .refund-process-box
  .container
  .refund-card-box
  .refund-top-details{margin: 0;}
.refund-process
  .refund-process-box
  .container
  .refund-card-box
  .refund-top-details
  label{margin-top: 15px; font-weight: 700;}
.refund-process
  .refund-process-box
  .container
  .refund-card-box
  .refund-top-details
  input[type="text"]{width: 100%; text-align: left; height: 40px; border-radius: 20px;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  input[type="text"]{border: #707070 1px solid; height: 30px; width: 90%; padding: 0 10px; font-size: 14px; text-align: right; margin-top: 3px; background: #fff;}
.refund-process .refund-process-box .container .refund-top-details .col-6{width: auto; display: flex; flex-direction: column; font-size: 18px; width: 18%;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6.price-box{align-items: flex-end; font-weight: 700; /* padding-right: 6%; */
}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6:first-child{width: 5%; margin-top: 4px;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6:nth-child(6){width: 23%; align-items: flex-end; padding-left: 4%;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6
  strong{font-weight: 500;}
.refund-process .refund-process-box .refund-card-box{padding-left: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn{margin-top: -10px; width: 100%; display: flex; justify-content: flex-end; align-items: center; padding: 0 20px 20px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  button{min-width: inherit; font-size: 14px; text-transform: capitalize; padding: 10px 20px; line-height: 1;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  .btn-link{background: none; color: #000;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  .btn-link:hover{color: var(--businessprimary-color);}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card{position: relative;}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card label{width: 100%; padding: 10px 25px; text-align: center; position: relative; color: var(--white-color); font-size: 20px; line-height: 1; font-weight: 500; background: transparent linear-gradient(180deg, var(--businessprimary-color) 0%, var(--businesssecondary-color) 100% ) 0% 0% no-repeat padding-box; border-radius: 8px 8px 0 0; margin: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown{position: absolute; right: 0; top: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-toggle{min-width: inherit; padding: 0; position: absolute; right: 20px; z-index: 1; top: 11px; background: none; color: var(--white-color);}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-toggle:after{display: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu{background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 5px 5px 20px #00000038; border: 2px solid var(--businesssecondary-color); border-radius: 10px; padding: 0; width: 140px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item{padding: 7px 10px; font-size: 14px; line-height: 1; border-bottom: var(--businesssecondary-color) 2px solid; display: block;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item:last-child{border: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item:hover{color: var(--businessprimary-color); background: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .fa-ellipsis-v{cursor: pointer; position: absolute; right: 20px; font-size: 18px; color: var(--businessprimary-color); z-index: 1;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .right-block{margin-left: auto;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content{padding: 20px; display: flex; flex-flow: wrap; /* min-height: 161px; */
}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content 
  .expand-options{text-align: end; width: auto; right: 20px; position: absolute;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content 
  .expand-options
  span{cursor: pointer;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p{width: 100%; margin-bottom: 7px; font-size: 14px; font-weight: normal; color: #000; display: flex;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .left-block
  p:last-child{margin: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p
  strong{font-weight: 500; color: var(--businessprimary-color); min-width: 55px; padding-right: 10px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p
  b
  span{padding-left: 10px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.remittance{color: var(--businessprimary-color); font-weight: 600; margin-top: 20px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.remittance
  strong{font-weight: 600;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.name{flex-direction: column;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .right-block
  .rightalign{display: flex; justify-content: flex-end;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker{width: calc(100% - 140px);}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control{border-radius: 10px; min-height: 30px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control
  *{font-size: 14px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control
  .chartofselect__indicator{padding: 0 5px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__menu
  *{font-size: 14px;}
.refund-process .refund-process-box .refund-card-box .matching-btn-section{text-align: center; padding-top: 50px;}
.refund-process .refund-process-box .refund-card-box .matching-btn{background: #d4d4d4; border: none; font-size: 14px; text-transform: none; color: #000; font-weight: 500; line-height: 1; min-width: 100px; min-height: 30px; padding: 0;}
.refund-process .refund-process-box .refund-card-box .matching-btn:hover{background: var(--businessprimary-color); color: #fff !important;}
.refund-process .refund-process-box .refund-card-box .refund-card{background: #fff; border: #eee 1px solid; border-radius: 10px;}
.refund-process .refund-process-box .refund-card-box .refund-card select{background-color: #fff; border: 1px solid #d9d9d9; border-radius: 10px; padding: 0 11px; height: 32px; position: relative; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
.refund-process .refund-process-box .refund-card-box .refund-card h6{font-size: 14px; color: var(--businessprimary-color);}
.firm-details .firm-summary h4{font-size: 24px;}
.content-details.trial-balance-report-details{width: 75%;}
.content-details .table-top-btn .table-btn .dropdown-menu{overflow: visible;}
.content-details .table-top-btn .table-btn .dropdown-menu a{display: block; padding: 5px 10px;}
.content-details .table-top-btn .table-btn .dropdown-menu a button{background: none; padding: 0; border: none; height: auto; line-height: normal; text-transform: none; color: var(--text_color);}
.content-details .table-top-btn .table-btn .dropdown-menu a:hover button{background: none; padding: 0; border: none; height: auto; line-height: normal; text-transform: none; color: var(--white-color);}
.content-details .table-top-btn .table-btn .dropdown-menu a:hover{color: var(--white-color); background: var(--businessprimary-color); text-decoration: none;}
body .full-content-block .page-title{width: calc(100% - 30px);}
body .full-content-block .page-title div:last-child{margin-left: auto;}
body .full-content-block .page-title .selectbox__control{min-height: 30px; margin-right: 10px;}
.selectbox__control *, body .full-content-block .page-title .selectbox__control *{font-size: 14px;}
.selectbox__control .selectbox__indicator, body .full-content-block .page-title .selectbox__control .selectbox__indicator{padding: 0 5px;}
.selectbox__menu *, body .full-content-block .page-title .selectbox__menu *{font-size: 14px;}
.business-section .content-details .table-top-btn .table-btn button, .business-section .content-details .table-top-btn .table-btn .button{font-size: 14px; text-transform: uppercase; height: 33px; line-height: 1;}
.firm-summary .account-wrapper h4{margin: 0 0 15px; width: 100%;}
.firm-summary .account-wrapper .bank-detail{min-height: 75px; display: flex; align-items: center; gap: 15px;}
.firm-summary .account-wrapper .bank-detail p{margin: 0; font-weight: 700;}
.firm-summary .account-wrapper .bank-detail p i{font-style: normal; display: block; font-weight: normal;}
.firm-summary .account-wrapper .bank-detail img{max-height: 75px;}
.firm-summary .account-wrapper .reconciliation-block{margin-top: 15px; border-left: #ccc 1px solid; padding: 0 0 0 15px; margin-top: 0;}
.firm-details .firm-summary .balance{margin: 0;}
.individual-card .row{margin: 0 -15px; display: block;}
.individual-card .recharts-wrapper{margin: 0 auto;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6:first-child{width: 5%; margin-top: 4px;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6:nth-child(6){width: 23%; align-items: flex-end; padding-left: 4%;}
.refund-process
  .refund-process-box
  .container
  .refund-top-details
  .col-6
  strong{font-weight: 500;}
.refund-process .refund-process-box .refund-card-box{padding-left: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn{margin-top: -10px; width: 100%; display: flex; justify-content: flex-end; align-items: center; padding: 0 20px 20px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  button{min-width: inherit; font-size: 14px; text-transform: capitalize; padding: 10px 20px; line-height: 1;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  .btn-link{background: none; color: #000;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .table-btn
  .btn-link:hover{color: var(--businessprimary-color);}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card{position: relative;}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card label{width: 100%; padding: 10px 25px; text-align: center; position: relative; color: var(--white-color); font-size: 16px; line-height: 1; font-weight: 500; background: transparent linear-gradient(180deg, var(--businessprimary-color) 0%, var(--businesssecondary-color) 100% ) 0% 0% no-repeat padding-box; border-radius: 8px 8px 0 0; margin: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown{position: absolute; right: 0; top: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-toggle{min-width: inherit; padding: 0; position: absolute; right: 20px; z-index: 1; top: 11px; background: none; color: var(--white-color);}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-toggle:after{display: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu{background: #ffffff 0% 0% no-repeat padding-box; box-shadow: 5px 5px 20px #00000038; border: 2px solid var(--businesssecondary-color); border-radius: 10px; padding: 0; width: 140px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item{padding: 7px 10px; font-size: 14px; line-height: 1; border-bottom: var(--businesssecondary-color) 2px solid; display: block;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item:last-child{border: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  > .dropdown
  .dropdown-menu
  .dropdown-item:hover{color: var(--businessprimary-color); background: none;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .fa-ellipsis-v{cursor: pointer; position: absolute; right: 20px; font-size: 18px; color: var(--businessprimary-color); z-index: 1;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .right-block{margin-left: auto;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content{padding: 20px; display: flex; flex-flow: wrap; /* min-height: 161px; */
}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p{width: 100%; margin-bottom: 7px; font-size: 14px; font-weight: normal; color: #000; display: flex;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .left-block
  p:last-child{margin: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p
  strong{font-weight: 500; color: var(--businessprimary-color); min-width: 55px; padding-right: 10px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p
  b
  span{padding-left: 10px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.remittance{color: var(--businessprimary-color); font-weight: 600; margin-top: 20px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.remittance
  strong{font-weight: 600;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.name{flex-direction: column;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  p.rightalign{justify-content: flex-end;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker{width: calc(100% - 110px);}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control{border-radius: 5px; min-height: 30px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control
  *{font-size: 12px; margin: 0;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__control
  .chartofselect__indicator{padding: 0 5px;}
.refund-process
  .refund-process-box
  .refund-card-box
  .col-6
  .refund-card
  .refund-card-content
  .selectpicker
  .chartofselect__menu
  *{font-size: 14px;}
.refund-process .refund-process-box .refund-card-box .matching-btn-section{text-align: center; padding-top: 50px;}
.refund-process .refund-process-box .refund-card-box .matching-btn{background: #d4d4d4; border: none; font-size: 14px; text-transform: none; color: #000; font-weight: 500; line-height: 1; min-width: 100px; min-height: 30px; padding: 0;}
.refund-process .refund-process-box .refund-card-box .matching-btn:hover{background: var(--businessprimary-color); color: #fff !important;}
.refund-process .refund-process-box .refund-card-box .refund-card{background: #fff; border: #eee 1px solid; border-radius: 10px;}
.refund-process .refund-process-box .refund-card-box .refund-card select{background-color: #fff; border: 1px solid #d9d9d9; border-radius: 10px; padding: 0 11px; height: 32px; position: relative; transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);}
.refund-process .refund-process-box .refund-card-box .refund-card h6{font-size: 14px; color: var(--businessprimary-color);}
.firm-details .firm-summary h4{font-size: 18px;}
.content-details .table-top-btn .table-btn .dropdown-menu{overflow: visible;}
.content-details .table-top-btn .table-btn .dropdown-menu a{display: block; padding: 5px 10px;}
.content-details .table-top-btn .table-btn .dropdown-menu a button{background: none; padding: 0; border: none; height: auto; line-height: normal; text-transform: none; color: var(--text_color);}
.content-details .table-top-btn .table-btn .dropdown-menu a:hover button{background: none; padding: 0; border: none; height: auto; line-height: normal; text-transform: none; color: var(--white-color) !important;}
.content-details .table-top-btn .table-btn .dropdown-menu a:hover{color: var(--white-color); background: var(--businessprimary-color); text-decoration: none;}
body .full-content-block .page-title{width: calc(100% - 30px);}
body .full-content-block .page-title div:last-child{margin-left: auto;}
body .full-content-block .page-title .selectbox__control{min-height: 30px; margin-right: 10px;}
.selectbox__control *, body .full-content-block .page-title .selectbox__control *{font-size: 12px;}
.selectbox__control .selectbox__indicator, body .full-content-block .page-title .selectbox__control .selectbox__indicator{padding: 0 5px;}
.selectbox__menu *, body .full-content-block .page-title .selectbox__menu *{font-size: 12px;}
.business-section .content-details .table-top-btn .table-btn button, .business-section .content-details .table-top-btn .table-btn .button{font-size: 14px; text-transform: uppercase; height: 33px; line-height: 1;}
.firm-summary .account-wrapper h4{margin: 0 0 15px; width: 100%;}
.firm-summary .account-wrapper .bank-detail{min-height: 75px; display: flex; align-items: center; gap: 15px;}
.firm-summary .account-wrapper .bank-detail p{margin: 0; font-weight: 700;}
.firm-summary .account-wrapper .bank-detail p i{font-style: normal; display: block; font-weight: normal;}
.firm-summary .account-wrapper .bank-detail img{max-height: 75px; max-width: 160px;}
.firm-summary .account-wrapper .reconciliation-block{margin-top: 15px; border-left: #ccc 1px solid; padding: 0 0 0 15px; margin-top: 0;}
.firm-details .firm-summary .balance{margin: 0;}
.individual-card .row{margin: 0 -15px; display: block;}
.individual-card .recharts-wrapper{margin: 0 auto;}
/* .first-chart .recharts-wrapper{width: 100% !important;}
 .first-chart .recharts-wrapper .recharts-legend-wrapper{width: 210px !important; margin: auto 0 0 auto; position: relative !important;}
.first-chart .recharts-wrapper .recharts-legend-wrapper ul{text-align: left !important;}
.first-chart .recharts-wrapper .recharts-legend-wrapper ul li{width: 100%;}*/
.first-chart .recharts-legend-wrapper li{display: flex; align-items: center; margin: 5px 0 0;}
.first-chart .recharts-legend-wrapper li span{color: #000 !important; font-size: 15px;}
.first-chart .recharts-legend-wrapper li .recharts-surface, .first-chart .recharts-legend-wrapper li .recharts-surface path{width: 12px; height: 12px;}
.dashboard-container .card .card-content .first-chart .card-header{gap: 10px;}
.dashboard-container .card .card-content .first-chart .card-header h5{margin-right: 10px;}
.login-form-block .content-details{margin: 0;}
.login-form-block .nav.nav-tabs li a{border: none;}
.login-form-block .content-details .action-box{display: flex; align-items: center; gap: 15px; flex-direction: column;}
.login-form-block .content-details .action-box p{margin: 0; font-size: 14px;}
.login-form-block .content-details .action-box p a:hover{text-decoration: none; cursor: pointer;}
.login-form-block .content-details i{color: var(--black-color);}
.coa-charts{display: flex; align-items: flex-end;}
.coa-charts .chart-legends{margin: 0 0 0 auto; padding: 0; /* max-width: 175px; */
}
.coa-charts .recharts-wrapper{margin: 0 auto;}
.coa-charts .chart-legends p{margin: 15px 0 0; display: flex; align-items: flex-start; gap: 7px; line-height: 1; font-size: 14px;}
.coa-header .select-box__indicator{padding: 0 !important;}
.coa-header .select-box__input-container{margin: 0 !important; padding: 0 !important;}
.coa-header *{font-size: 14px !important;}
.coa-header .select-box__control{min-height: 27px !important;}
.pricetabs{padding-top: 30px;}
.pricetab-block{flex-direction: column; align-items: center;}
.pricetab-block .price-tabs-block{display: flex; margin-bottom: 10px;}
.pricetab-block .pricetab-table, .pricetab-block .pricetab-table > div{width: 100%; display: flex; justify-content: center; margin-bottom: 10px;}
.payment-popup .action-box{align-items: center; justify-content: center;}
.payment-popup .modal-body .action-box button{margin: 0 auto; font-size: 14px; font-weight: 500; text-transform: uppercase !important; margin-top: 15px;}
body .payment-popup .modal-body .content-details{padding: 0;}
.login-form-block .selectbox__menu *{color: #333;}
.login-pages .ant-row .ant-col, body .login-form-block{height: 100%;}
.login-form-block .content-details .entry-card{width: 100%;}
body .login-form.firm-form{margin-top: 30px; margin-bottom: 30px;}
.payment-popup .payment-details p{font-size: 15px; color: var(--black-color);}
.payment-popup .payment-details p input[type="text"]{width: 60px; border: #e5e2e2 1px solid; border-radius: 5px; padding: 0 5px;}
.payment-popup .payment-details .promo-features p{background: #f0f0f0; padding: 10px 15px; margin: 0 -15px; width: calc(100% + 30px);}
.payment-popup .payment-cards{border-top: #e5e2e2 1px solid; padding-top: 15px;}
.payment-popup .payment-cards .table-top-btn .nav.nav-tabs{margin: 0;}
.payment-popup .payment-cards p{font-size: 15px; color: var(--black-color); margin-bottom: 0px;}
.payment-popup .payment-cards input{border: #e5e2e2 1px solid; border-radius: 5px; padding: 7px 5px; width: 100%;}
.payment-popup .payment-cards .StripeElement{border: #e5e2e2 1px solid; border-radius: 5px; padding: 7px 5px;}
.payment-popup .payment-cards .table-top-btn{gap: 15px;}
.payment-popup .payment-cards .table-top-btn > div{display: flex; align-items: center;}
.payment-popup .payment-cards .table-top-btn > div label{font-weight: normal; margin: 0 0 0 5px; white-space: nowrap; color: #000; font-size: 14px;}
.payment-popup .payment-cards .table-top-btn > div input{margin: 0;}
.payment-popup .promo-field{margin: 0 0 10px;}
.payment-popup .promo-field .promocode{cursor: pointer; background: #0077c770; color: #fff; font-size: 14px; text-decoration: none; padding: 7px 12px; border-radius: 5px; line-height: 1;}
.payment-popup .promo-field > form{padding: 0; display: flex; align-items: center; width: 100%;}
.payment-popup .promo-field > form input{border: #e5e2e2 1px solid; border-radius: 25px; padding: 7px 75px 7px 15px;}
.payment-popup .promo-field > form button{width: 95px; padding: 11px !important; margin: 0 0 0 10px;}
.payment-popup .promo-field > form .cancel-btn{position: absolute; margin: 0; right: 123px; padding: 5px 7px !important; width: auto; min-width: inherit !important; font-size: 12px !important; background: #000 !important;}
.payment-popup{width: 400px;}
.form-block .my-collapsible{position: fixed; bottom: 0; width: 100%; z-index: 1;}
.form-block .my-collapsible .my-collapsible__content{background: rgba(0, 0, 0, 0.85); color: #fff; padding: 10px; text-align: center;}
.form-block .my-collapsible .my-collapsible__toggle{background: #0078c8; color: #fff; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center; font-weight: 400; margin-left: auto; border-radius: 5px 0 0 0;}
.modal-footer button[type="submit"] + button.reset{min-height: inherit; color: #000; position: static;}
body.business-section .modal-footer button[type="submit"]:hover, body.business-section .modal-footer button[type="submit"] + button.reset:hover{color: #fff !important; background: var(--businessprimary-color) !important;}
body .modal form .form-box .address-box{border-radius: 10px; margin-bottom: 0;}
body .modal form .form-box{margin-bottom: 15px;}
body .modal .modal-body .modal-footer{padding-bottom: 15px;}
.dashboard-table
  .react-bootstrap-table
  table
  tbody
  tr:last-child
  td:first-child{border-bottom-left-radius: 15px;}
.dashboard-table
  .react-bootstrap-table
  table
  tbody
  tr:last-child
  td:last-child{border-bottom-right-radius: 15px;}
.content-details .table-top-btn + div.tab-table .subscription-plan{background: none;}
.content-details .table-top-btn + div.tab-table .subscription-plan .pricetabs{padding: 0;}
.content-details
  .table-top-btn
  + div.tab-table
  .subscription-plan
  .pricetab-block
  .price-tabs-block{border: var(--primary-color) 1px solid; overflow: hidden; border-radius: 50px; padding: 5px;}
.content-details
  .table-top-btn
  + div.tab-table
  .subscription-plan
  .pricetab-block
  .price-tabs-block
  h6{color: var(--primary-color);}
.content-details
  .table-top-btn
  + div.tab-table
  .subscription-plan
  .pricetab-block
  .price-tabs-block
  h6.active{background: var(--primary-color); color: var(--white-color);}
.content-details .table-top-btn + div.tab-table .current-subscription label{font-weight: 400;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-status{font-size: 14px; font-weight: 500;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-status i{text-transform: capitalize; background: #0078C8; font-size: 14px; border-radius: 5px; text-shadow: #000 1px 1px 1px; color: #FFF; padding: 5px 10px; font-weight: normal; font-style: normal;}
.content-details .table-top-btn + div.tab-table .current-subscription .btn-active{background: transparent linear-gradient(180deg, var(--lightblue_color) 0%, var(--blue_color) 100%) 0% 0% no-repeat padding-box; font-size: 14px; color: var(--white_color); text-transform: uppercase; padding: 9px 20px; border-radius: 10px; height: auto; line-height: 1; margin-left: 0; font-weight: 500; display: inline-block; min-width: inherit; border: none;}
.content-details .table-top-btn + div.tab-table .current-subscription{background: var(--white_color); margin: 0px; height: auto; padding: 30px; width: 100%; max-height: inherit; border-top-left-radius: 0px; border-top-right-radius: 0px; font-size: 14px;}
.content-details .table-top-btn + div.tab-table .current-subscription .subcription-period p{margin: 0px;}
.content-details .table-top-btn + div.tab-table .current-subscription h6{margin-top: 0px; margin-bottom: 15px;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-cancel{display: flex; align-items: center;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-cancel .subscription-lastday p{margin: 0px;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-cancel .subscription-lastday label{margin-bottom: 0px;}
.content-details .table-top-btn + div.tab-table .current-subscription .subscription-header{display: flex; align-items: center; justify-content: space-between;}
.content-details .table-top-btn + div.tab-table .current-subscription .subcription-period{display: flex; align-items: center; gap: 20px;}
.content-details .table-top-btn + div.tab-table .current-subscription .btn-cancel{background: transparent; font-size: 14px; color: var(--primary-color); text-transform: uppercase; padding: 9px 20px; border-radius: 10px; height: auto; line-height: 1; margin-left: 20px; font-weight: 500; display: inline-block; min-width: inherit; border: 1px solid var(--primary-color);}
.content-details
  .table-top-btn
  + div.tab-table
  .custom-tabs
  h6.monthly-tab::before{display: none;}
div[data-ui-name="MonthRangePicker.Popper"] button{padding: 0; background: none; color: var(--businessprimary-color);}
div[data-ui-name="MonthRangePicker.Popper"] button:hover{border: none; color: #191b23; background: #e0e1e9; border-radius: 6px;  
}
div[data-ui-name="MonthRangePicker.Popper"] button[type="button"]{background: none; color: var(--black-color); border: var(--businessprimary-color) 1px solid; align-items: center; justify-content: center; text-align: center; margin-bottom: 0; height: 30px; line-height: 1; min-width: 30px;}
div[data-ui-name="MonthPicker.Popper"] button[type="button"]:hover{background: var(--businessprimary-color); color: var(--white-color);}
div[data-ui-name="MonthPicker.Popper"] button[type="button"]{background: none; color: var(--black-color); border: var(--businessprimary-color) 1px solid; align-items: center; justify-content: center; text-align: center; margin-bottom: 5px; height: 30px; line-height: 1; padding: 0px; /* min-width: 30px; */
}
div[data-ui-name="MonthPicker.Calendar"] button{background: none; color: var(--businessprimary-color);}
div[data-ui-name="MonthPicker.Calendar"] button:hover{border: none; color: #191b23; background: #e0e1e9; border-radius: 6px;  
}

div[data-ui-name="MonthPicker.Popper"] button[type="button"]:hover{background: var(--businessprimary-color); color: var(--white-color) !important;}

.profile.full .profile-photo{min-height: 114px; position: relative;}
.profile.full .profile-photo .guide_leb{width: 114px; height: 114px; background: #06488b; border-radius: 50%; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 13px;}
.profile-photo input[type="file"]{position: absolute; width: 114px; height: 114px; border-radius: 50%; left: 0; border: none; -webkit-appearance: none; appearance: none; -moz-appearance: none; padding: 0; border: solid transparent 2px; opacity: 0; cursor: pointer;}
.profile-photo .remove-image{margin-top: 10px; background: var(--businesssupporting-color4); border-radius: 5px; font: normal normal normal 12px/27px Poppins; border: none; text-transform: uppercase; padding: 5px 10px; min-width: inherit; color: #fff; cursor: pointer; line-height: 1; display: flex; gap: 5px;}
.rs-calendar-month-dropdown{background-color: #fff; border-top: 1px solid #e5e5ea; display: none; margin-top: 0; overflow: hidden; position: absolute; top: 44px; width: 100%; z-index: 1;}
.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown{display: block;}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll{height: 227px;}
.rs-calendar-month-dropdown-row{padding: 5px 12px 5px 52px; position: relative;}
.rs-calendar-month-dropdown-row:not(:last-child){border-bottom: 1px dotted #e5e5ea;}
.rs-calendar-month-dropdown-year{left: 12px; position: absolute; top: calc(50% - 0.5em);}
.rs-calendar-month-dropdown-cell{display: inline-block; margin: 1px; text-align: center; vertical-align: middle; width: calc(16.66667% - 2px); font-size: 12px;}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content, .rs-picker-menu .rs-calendar .rs-calendar-table-cell-content{height: 25px; width: 25px; display: flex; align-items: center; justify-content: center;}
.rs-calendar-month-dropdown-cell-content{border-radius: 6px; cursor: pointer; display: inline-block; font-size: 14px; line-height: 1.42857143; padding: 5px;}
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover{background-color: var(--businesssecondary-color); color: #fff;}
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content{background-color: var(--businessprimary-color); -webkit-box-shadow: none; box-shadow: none; color: #fff;}
.refund-process .refund-process-box .refund-card-box .matching-btn.split-btn{bottom: 10px; right: 10px; position: absolute; min-width: 30px; min-height: 30px;}
.split-btn:hover img{filter: invert(1);}
.business-section .border-btn .download-template{padding: 0 20px !important; color: #fff !important; text-decoration: none !important;}
.business-section .border-btn .download-template:hover{color: var(--businessprimary-color) !important;}
.business-section .border-btn .info-icon .dropdown-toggle{padding: 0 !important; border: none !important; margin: 0 0px 0 10px !important;}
.business-section
  .content-details
  .table-top-btn
  .table-btn
  .border-btn
  .info-icon
  .dropdown-menu{background: #fff; color: #000; margin-left: -15px;}
.business-section
  .content-details
  .table-top-btn
  .table-btn
  .border-btn
  .info-icon
  .dropdown-menu::before{color: var(--businessprimary-color);}
.business-section
  .content-details
  .table-top-btn
  .table-btn
  .border-btn
  .info-icon
  .dropdown-menu
  a{color: #000;}
.upload-div .modal-body .upload-file{position: relative; border: #ccc dashed 1px; padding: 10px; border-radius: 10px; margin-bottom: 10px;}
.upload-div .modal-body .upload-file input[type="file"]{height: 100%; position: absolute; padding: 0; cursor: pointer; opacity: 0; border: none; width: 100%;}
@media (max-width: 1500px){.firm-summary .account-wrapper .bank-detail{width: 50%;}
  .firm-summary .account-wrapper .reconciliation-block{width: calc(50% - 16px);}
  .content-details .summary-filter{margin-top: -50px !important; margin-bottom: 0 !important; float: left;}
}
@media (max-width: 1400px){.firm-summary .account-wrapper .bank-detail{flex-direction: column; justify-content: flex-start; align-items: flex-start;}
  .firm-summary .account-wrapper .bank-detail img{max-height: 36px; max-width: 128px;}
  .firm-details .firm-summary h4{font-size: 20px;}
  .firm-details .firm-summary h5{font-size: 20px;}
  .firm-summary .account-wrapper .bank-detail{width: 40%;}
  .firm-summary .account-wrapper .reconciliation-block{width: calc(60% - 16px);}
}
@media (max-width: 1070px){body .modal-dialog.large-popup{width: calc(100% - 30px); margin: 15px;}
  .nav.nav-tabs li a{min-width: inherit; font-size: 14px; white-space: nowrap; border-radius: 15px 15px 0 0;}
  .content-details .table-top-btn + div.tab-table{padding: 15px;}
  .content-details{margin: 15px; padding: 15px;}
  .react-bootstrap-table table thead tr th:first-child{border-top-left-radius: 15px;}
  .react-bootstrap-table table thead tr th:last-child{border-top-right-radius: 15px;}
  .react-bootstrap-table table tbody tr:last-child td:first-child{border-bottom-left-radius: 15px;}
  .react-bootstrap-table table tbody tr:last-child td:last-child{border-bottom-right-radius: 15px;}
  .content-details .table-top-btn + div.tab-table{border-radius: 0 15px 15px 15px;}
  .firm-summary .account-wrapper .bank-detail{width: 100%; gap: 10px; flex-direction: row; flex-flow: wrap; align-items: center;}
  .firm-summary .account-wrapper .reconciliation-block{width: 100%; margin: 10px 0 0; padding: 10px 0 0; border: none; border-top: #ccc 1px solid;}
}
@media (max-width: 991px){body header{position: fixed;}
  .navbar .navbar-toggler{display: block;}
  header .navbar-collapse.collapse{position: fixed; top: 57px; background: #06488b; right: 0; left: 0; width: 100vw; height: calc(100vh - 57px) !important; padding: 0;}
  .navbar .navbar-collapse.collapse{display: none !important;}
  .navbar .navbar-collapse.collapse.in{display: block !important;}
  .navbar .navbar-collapse.collapse.in:before{background: #ffffff50; content: ""; left: 0; height: 100%; position: fixed; width: 20%;}
  .navbar-nav{flex-direction: column; margin: 0;}
  body header .header-nav li .dropdown-toggle, body header .header-nav li a{border-bottom: #fff 1px solid; padding: 10px 15px; display: block; line-height: 1;}
  .content-details .entry-card{width: 100%;}
  .react-bootstrap-table table tbody tr td, .react-bootstrap-table table thead th{padding: 10px; font-size: 14px; width: auto !important;}
  .react-bootstrap-table table tbody tr td:last-child{white-space: nowrap;}
  .dashboard-container .card{width: 100%;}
  .dashboard-container .col-md-4.active-cards{width: 100%;}
  .dashboard-container .col-md-4.active-cards .row{gap: 20px;}
  .dashboard-container .col-md-4.active-cards .card{width: calc(25% - 15px);}
  .active-cards .active-date{font-size: 12px;}
  .active-cards .card-header h6{font-size: 17px;}
  body .header-bottom-block{min-height: 115px;}
  .integration-card .card{width: calc(33.33% - 20px);}
  .firm-summary .account-wrapper h4{font-size: 18px;}
  .header-nav{order: 2; margin: 0; padding: 0;}
  .header-nav .navbar{margin: 0; border: none; min-height: inherit;}
  .header-nav .navbar button{background: none; margin: 0 0 0 15px; padding: 0; border: none;}
  .header-nav .navbar button .navbar-toggler-icon{width: 32px; height: 2px; background: var(--white-color); display: block; position: relative;}
  .header-nav .navbar button .navbar-toggler-icon:before{width: 32px; height: 2px; background: var(--white-color); display: block; position: absolute; content: ""; top: -8px;}
  .header-nav .navbar button .navbar-toggler-icon:after{width: 32px; height: 2px; background: var(--white-color); display: block; position: absolute; content: ""; bottom: -8px;}
  .header-nav .navbar button[aria-expanded="true"] .navbar-toggler-icon{background: none;}
  .header-nav .navbar button[aria-expanded="true"] .navbar-toggler-icon:before{transform: rotate(45deg); transform-origin: left top; top: -13px; left: 5px;}
  .header-nav .navbar button[aria-expanded="true"] .navbar-toggler-icon:after{transform: rotate(-45deg); transform-origin: right top; bottom: auto; top: -13px; right: 6px;}
  .login-form-block{justify-content: flex-start;}
  header .navbar-collapse.collapsing{display: none;}
  header .navbar-collapse.collapse{width: 50%; left: auto; top: 65px; background: rgba(0, 0, 0, 0.95); border: none;}
  header .navbar-collapse.collapse ul{margin: 0; padding: 0; list-style: none; width: 100%;}
  header .navbar-collapse.collapse ul li{width: 100%;}
  header .navbar-collapse.collapse ul li a{border-color: #ffffff50; color: var(--white-color); text-transform: uppercase; text-decoration: none;}
  header .navbar-collapse.collapse ul li a.dropdown-toggle:after{content: "\f105"; font-family: "FontAwesome"; position: absolute; right: 0; border-left: #ffffff50 1px solid; width: 37px; height: 37px; top: 0; display: flex; align-items: center; justify-content: center;}
  header .navbar-collapse.collapse ul li.open a.dropdown-toggle:after{content: "\f107";}
  header .navbar-collapse.collapse ul li .dropdown-menu{position: static; width: 100%; border-radius: 0; border: none; background: none; padding: 0;}
  header .navbar-collapse.collapse ul li .dropdown-menu a{text-transform: none; padding: 10px 0 10px 25px;}
  .dashboard-container, .refund-process .refund-process-box{padding: 15px; width: 100%;}
  .dashboard-container{width: calc(100% + 30px);}
  .refund-process .refund-process-box .refund-card-box{display: flex;}
  .refund-process .refund-process-box .refund-card-box .refund-card .refund-card-content > div{flex-direction: column !important; align-items: flex-start !important;}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content{padding: 15px; flex-direction: column;}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .selectpicker, .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content input{width: 100% !important;}
  .refund-process .refund-process-box .refund-card-box .col-md-5{width: 41.6666%;}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .right-block .rightalign{display: flex; justify-content: flex-start;}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .right-block{margin: 0; position: relative !important; right: auto !important;}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p{margin: 0; justify-content: space-between;}
  .refund-process .refund-process-box .refund-card-box .refund-card .refund-card-content > div.left-block p:last-child{display: block; margin-bottom: 10px !important;}
  .refund-process .refund-process-box .refund-card-box .refund-card .refund-card-content > div.left-block p:last-child strong{display: block; width: 100%;}
  .content-details .table-top-btn + div.tab-table .form-box{padding: 15px !important; margin-bottom: 15px !important;}
  .welcome-block .sitelogo img{max-width: 340px !important;}
  .nav.nav-tabs li a{height: 40px;}
  .react-bootstrap-table table tbody tr td.reset-expansion-style .content-details .react-bootstrap-table table thead th{padding: 10px 15px;}
  .react-bootstrap-table{overflow: auto;}
  .react-bootstrap-table::-webkit-scrollbar{height: 5px;}
  /* .content-details .table-top-btn .table-btn button, .content-details .table-top-btn .table-btn .button{padding: 0 15px;} */
}
@media (max-width: 767px){.content-details .entry-card, .add-business-client .form-box .form-box-half{width: 100%;}
  body .modal-body .modal-content .modal-content-details .modal-content-left, body .modal-body .modal-content .modal-content-details .modal-content-right{width: 100%;}
  body .modal-body .modal-content-details p{width: 100%; font-size: 14px; flex-flow: wrap;}
  body .modal-body .modal-content .modal-content-details{padding: 15px;}
  .dashboard-container .col-md-4.active-cards .card{width: calc(50% - 10px);}
  .react-bootstrap-table table tbody tr td, .react-bootstrap-table table thead th{font-size: 12px;}
  .integration-card .card{width: calc(50% - 15px);}
  .refund-process .refund-process-box .refund-card-box .col-md-5{width: 100%;}
  .refund-process .refund-process-box .refund-card-box{flex-flow: wrap;}
  .refund-process .refund-process-box .refund-card-box .matching-btn-section{padding: 0; margin: 15px auto; flex-direction: row !important;}
  .action-box button.reset, .action-box button{min-width: inherit !important; min-height: inherit !important; padding: 10px 20px !important; font-size: 14px; line-height: 1; text-transform: uppercase;}
  .refund-process .form-check{margin: 0;}
  .login-screen .welcome-block{width: 100%; height: auto;}
  .welcome-block .sitelogo img{max-width: 250px !important; margin: 15px 0;}
  .login-screen .form-block{width: 100%;}
  body .footer-link{color: #000 !important; bottom: 0;}
  .login-screen .form-block{padding: 30px 15px 0;}
  .login-screen .form-block .footer-link{position: static;}
  .login-screen .form-block .footer-link p a{line-height: 1;}
  .welcome-block .sitelogo span{margin: -20px 0 15px;}
  .welcome-block .footer-link{display: none;}
  .coa-charts{flex-direction: column; align-items: center;}
  .coa-charts .recharts-responsive-container{width: 100% !important;}
  .coa-charts .chart-legends{margin: 0; max-width: 100%; width: 100%; display: flex; column-gap: 15px; flex-flow: wrap; align-items: center; justify-content: center;}
  body .full-content-block .page-title{font-size: 14px;}
  header .navbar-collapse.collapse{width: 80%;}
  .business-section .content-details .table-top-btn .table-btn button, .business-section .content-details .table-top-btn .table-btn .button{font-size: 12px;}
  .table-pagination .pager ul > li > button, .table-pagination .pager button, .table-pagination .limiter select, .table-pagination .limiter{font-size: 12px;}
  .table-pagination{margin-top: 10px;}
}
@media (max-width: 680px){.content-details .summary-filter{margin: 0 0 10px !important; float: none; background: none;}
}
@media (max-width: 640px){/* .content-details .table-top-btn{flex-direction: column;} */
  .content-details .table-top-btn > div{width: 100%;}
  .content-details .search-section form input{min-width: 70%;}
  .content-details .table-top-btn > div.search-section + .table-btn{width: auto;}
  .content-details .search-section form{width: 95%;}
  .table-pagination{flex-direction: column; row-gap: 10px;}
}
@media (max-width: 540px){.content-details .entry-card .form-box .half-width{width: 100%;}
  .content-details{padding: 15px;}
  .integration-card .card{width: 100%;}
  body header .userprofile .btn{font-size: 0; min-width: inherit; padding: 0;}
  body header .userprofile .btn span{margin: 0;}
}
@media (max-height: 768px){.login-form .sitelogo{margin-top: 15px;}
}
@media (min-width: 768px){.form-block .my-collapsible{display: none;}
}
.refund-process .matching-btn{min-width: fit-content;}
span.bas-code{background: #ccc; padding: 5px; min-width: 40px !important; border-radius: 5px; display: inline-block; margin-right: 10px; text-align: center;}
hr{width: 100%;}
img.small-favicon{width: 16px;}
img.small-white-favicon{width: 12px;}
/* Added By Nirav */
.table-with-footer .react-bootstrap-table table tbody tr td:first-child{border-bottom-left-radius: 0; border-left: #ececec 1px solid; border-bottom: #ececec 1px solid;}
.table-with-footer .react-bootstrap-table table tbody tr td:last-child{border-bottom-right-radius: 0; border-right: #ececec 1px solid; border-bottom: #ececec 1px solid;}
.table-with-footer .react-bootstrap-table table tfoot tr th{border-bottom: #ececec 1px solid; font-size: 14px; font-weight: 600; padding: 16px 20px;}
.table-with-footer .react-bootstrap-table table tfoot tr th:first-child{border-bottom-left-radius: 10px; border-left: #ececec 1px solid; border-bottom: #ececec 1px solid;}
.table-with-footer .react-bootstrap-table table tfoot tr th:last-child{border-bottom-right-radius: 10px; border-right: #ececec 1px solid; border-bottom: #ececec 1px solid;}
.page-title-right img{border-radius: 50%;}
.ac-branding-logo{border-left: 1px solid; padding-left: 5px;}
.ac-branding-logo img.ac-firm-logo{filter: brightness(); border-radius: 50%;}
.split{width: calc(50% - 60px) !important;}
.refund-card-content hr{margin-top: 10px; margin-bottom: 10px;}
.business-section .content-details .table-top-btn .table-btn .border-btn .download-template{background: none; border: none; color: var(--businessprimary-color) !important; padding: 0 !important; margin: 0 10px !important; text-decoration: underline !important;}
.client-section .content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu{width: 340px; left: 0; border: none; background: var(--businessprimary-color);}
.client-section .content-details .table-top-btn .table-btn .border-btn .info-icon .dropdown-menu::before{color: var(--businessprimary-color);}
.client-section .content-details .table-top-btn .table-btn .border-btn .download-template{background: none; border: none; color: var(--businessprimary-color) !important; padding: 0 !important; margin: 0 10px !important; text-decoration: underline !important;}
/* Added By Saujan*/
.content-details.connect-bank{display: flex; align-items: center; flex-direction: column; height: 80vh; justify-content: center; gap: 15px;}
.content-details.connect-bank .bank-block{display: flex; flex-direction: column; gap: 15px; background: #fff; box-shadow: 0px -2px 30px #00000029; border-radius: 10px; padding: 30px;}

.matching-btn .memorise-btn{width: calc(30% - 75px) !important;}
.rs-stack{display: flex; align-items: center; gap: 5px;}
.upload-invoice{right: 108px !important;}
.upload-no-invoice{right: 50px !important;}
.download-invoice{right: 64px !important;}
/* .upload-invoice{background: #D4D4D4; border: none; font-size: 14px; text-transform: none; color: #000; font-weight: 500; line-height: 1; min-width: 100px; min-height: 30px; padding: 0;} */
.content-details.connect-bank{display: flex; align-items: center; flex-direction: column; height: 80vh; justify-content: center; gap: 15px;}
.content-details.connect-bank .bank-block{display: flex; flex-direction: column; gap: 15px; background: #fff; box-shadow: 0px -2px 30px #00000029; border-radius: 10px; padding: 30px;}

.matching-btn .memorise-btn{width: calc(30% - 75px) !important;}
.rs-stack{display: flex; align-items: center; gap: 5px; min-width: 204px;}
.chartofselect__control.css-x19aeh-control{border-radius: 10px; min-height: 30px;}
.input.reconcile-transaction-description-list{padding: 8px 15px;}
.reconcile-item-list-data{word-break: break-word;}
.reconcile-item-list-data h6{font-size: 14px;}
.reconcile-item-list-split{cursor: pointer;}
.disabled-font-awesome{color: grey; cursor: not-allowed !important;}
.list-view{margin-bottom: 0; display: inline-flex; align-items: center; gap: 0; background: #fff; border: var(--businessprimary-color) 1px solid; border-radius: 5px; padding: 0; position: relative;}
.list-view svg{width: 16px; height: 16px; cursor: pointer;}
.list-view:after{background: var(--businessprimary-color); left: 50%; top: 50%; transform: translate(-50%, -50%); content: ""; width: 1px; height: 16px; position: absolute; display: none;}
.table-action-btn{display: flex; align-items: center;}
.table-action-btn .fa-money-bill-transfer{background: url(../newimages/reconcile.png) no-repeat; background-size: 16px; font-size: 0; width: 16px; height: 16px;}
.table-action-btn .fa-memory{background: url(../newimages/memorize.png) no-repeat; background-size: 16px; font-size: 0; width: 16px; height: 16px;}
.table-action-btn .fa-paper-plane{background: url(../newimages/review.png) no-repeat; background-size: 16px; font-size: 0; width: 16px; height: 16px;}
.table-action-btn .fa-money-bill-transfer path, .table-action-btn .fa-money-bill-transfer title, .table-action-btn .fa-memory path, .table-action-btn .fa-memory title, .table-action-btn .fa-paper-plane path, .table-action-btn .fa-paper-plane title{display: none;}
.table-action-btn .disabled-font-awesome{opacity: 0.7;}
th.table-header{display: none;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .expand-reconcile-transaction
  .react-bootstrap-table{padding: 4px; background: #ebebeb !important; margin: 0; border-radius: 0;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .expand-reconcile-transaction
  .react-bootstrap-table
  .table{border-radius: 0px; margin-bottom: 0px;}
.css-14el2xx-placeholder{color: hsl(0, 0%, 50%); grid-area: 1/1/2/3; margin-left: 2px; margin-right: 2px; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; box-sizing: border-box;}
.reconcile-transactions-list .table-action-btn{line-height: 0 !important; flex-flow: wrap; align-items: center; gap: 9px; justify-content: center;}
.reconcile-transactions-list .table-action-btn span{padding: 0px 5px;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style{white-space: inherit; padding: 0;}
.reconcile-transactions-list .react-bootstrap-table table tbody tr td{padding: 10px;}
.reconcile-transactions-list .react-bootstrap-table table thead tr th{padding: 5px 10px;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  thead{display: none;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td{border-radius: 0;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .expand-reconcile-transaction
  .react-bootstrap-table
  .table{border: none; padding: 0; table-layout: fixed;}
.react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  .expand-reconcile-transaction
  .react-bootstrap-table{border: none; padding: 0;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td.expand-cell{display: none;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:last-child{border-right: none;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:first-child{border-left: none;}
/* .reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:first-child{width: 29px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(2){width: 120px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(3){width: 153px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(4){width: 92px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(5){width: 90px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(6){width: 223px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(7){width: 179px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(8){width: 221px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(9){width: 224px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(10){width: 165px !important;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(11){width: 203px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(2){width: 131px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(3){width: 124px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(4){width: 61px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(5){width: 67px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(6){width: 192px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(7){width: 192px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(8){width: 173px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(9){width: 191px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(10){width: 194px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(11){width: 151px !important;}
.reconcile-transactions-list.under-reviewed
  .react-bootstrap-table
  table
  tbody
  tr
  td.reset-expansion-style
  tbody
  tr
  td:nth-child(12){width: 142px !important;} */

.modal-open .modal .small-review button.btn-close{color: #FFF !important;}
.business-section .bas-report > .row{border-bottom: 1px solid #eee; margin: 0; padding-bottom: 10px;}
.business-section .bas-report > .row > .col-md-12{padding: 0;}
/* .business-section .bas-report > .row .row{margin: 0;} */
.business-section .bas-report > .row h4{font-size: 21px; margin-top: 15px;}
.business-section .bas-report > .row h6{font-size: 14px; margin: 5px 0;}
.balance-block{padding-bottom: 20px; display: flex; flex-wrap: wrap; row-gap: 30px;}
.balance-block > div{display: flex; align-items: center; flex-wrap: wrap; gap: 5px;}
.business-section .bas-report > .row h6{font-size: 16px; margin: 5px 0;}
/* .balance-block{padding-bottom: 20px; display: flex; flex-wrap: wrap; row-gap: 30px;} */
/* .balance-block > div{display: flex; align-items: center; flex-wrap: wrap; gap: 5px;} */
.balance-block > div label{margin: 0;}
.balance-block > div label + div{width: 100%;}
.balance-block > div .btn{float: right; font-family: var(--bodyfont); margin-left: auto; background: var(--businessprimary-color); border: var(--businessprimary-color) 1px solid;}
.balance-block > div .btn:hover{background: var(--white-color); color: var(--businessprimary-color);}
/* .balance-block::before{background: #EEE; width: calc(100% - 30px); margin: 0 auto; content: ''; height: 1px; margin-bottom: 15px;} */

/* Client Section */
.client-section .modal-footer button[type="submit"] + button.reset{color: var(--businessprimary-color) !important; background: var(--white-color);}
body.client-section header .userprofile .dropdown-menu{background: hsla(272, 40%, 25%, 1); border-color: hsla(272, 40%, 25%, 1);}
body.client-section header{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
body.client-section .full-content-block .page-title p{color: hsla(204, 97%, 15%, 1);}
body.client-section button, body.client-section .btn, body.client-section .ant-btn.ant-btn-primary, .client-section .content-details .table-top-btn .table-btn button, .client-section .content-details .table-top-btn .table-btn .button{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
body.client-section button:hover, body.client-section .btn:hover, body.client-section .ant-btn.ant-btn-primary:hover, .client-section .content-details .table-top-btn .table-btn button:hover, .client-section .content-details .table-top-btn .table-btn .button:hover{color: #FFF !important; background: hsla(204, 97%, 15%, 1); background: linear-gradient(180deg, hsla(204, 97%, 15%, 1)hsla(272, 40%, 25%, 1) 0%,  100%); background: -moz-linear-gradient(180deg, hsla(204, 97%, 15%, 1) 0%, hsla(272, 40%, 25%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(204, 97%, 15%, 1) 0%, hsla(272, 40%, 25%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#012F4E", endColorstr="#42275A", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#012F4E", endColorstr="#42275A", GradientType=1 ); }
.client-section .content-details .entry-card .form-box .half-width.profile-photo .guide_leb{background: hsla(204, 97%, 15%, 1);}
.client-section .modal-footer button[type="submit"]:hover, .client-section .modal-footer button[type="submit"] + button.reset:hover{background: hsla(204, 97%, 15%, 1) !important; color: #fff !important;}
.client-section .profile.full .profile-photo .guide_leb{background: hsla(204, 97%, 15%, 1);}
.client-section .react-bootstrap-table table thead th{color: hsla(204, 97%, 15%, 1);}
.client-section .content-details .search-section form .btn{padding: 0; min-width: inherit; font-size: 12px; background: url(../images/search-black.png) no-repeat center; /* background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAARCAYAAAACCvahAAAABHNCSVQICAgIfAhkiAAAAXFJREFUOE+lUz1LxEAUnNX6asFetNBSi4ug2Ahi4UdnY6FgI1h4FxuttIp3iNYKFrZ+gCDYiAdZCwUrEfUXmFbru3U2u0n2zEU4DGwg783Mm/f2ReAfj8hxK3IKPRjO4uoWwfh7pxoZWZMEDnkcoqUoNCDU2m8RQ/Yf5gB1maorvBD8AQiPsT7HxZArIOCH/QQ9W1CEFpZQ8+5Sgh8uM38af2vRfW8kyQlU5Tat7saBJsZQ955y/bkCEPMIylcao8n3fE9Q9oKWFguH78tP406dELdqyL5U1tIOLe0Vk8NzwhdovUHcZEI2igp1BiuF5A4O9cCOqbhCUkRLA7T03aHnQWLebHwDgXdkKm/KUfTi0SSyfpxpl6DEtZlLjEmvy95zWl1ntYMtNMUrN22apHUzqPhpc5eQS0wcWPuFbdvB6gUq6/badzvetNasIxJxkGc8N3Qwo5t0BfI/xl91q7LmCnRH1sKZQNQ9WQvEN6S+fgCxB4Tq+23mqwAAAABJRU5ErkJggg==) no-repeat center; */
  font-size: 0; width: 20px; position: absolute; right: 10px; z-index: 1; height: 33px; filter: brightness(0); top: 0;}
.client-section .dashboard-container .active-cards .card:hover, .client-section .nav.nav-tabs li:hover a, .client-section .nav.nav-tabs li.active a{background: hsla(272, 40%, 25%, 1); border-color: hsla(272, 40%, 25%, 1);}
body.client-section header{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
body.client-section  header .header-nav li .dropdown-menu{background: hsla(272, 40%, 25%, 1);}
.client-section .subscription-plan .col-lg-6, .client-section .content-details .table-top-btn + div.tab-table .subscription-plan .pricetab-block .price-tabs-block{border-color: hsla(272, 40%, 25%, 1);}
.client-section .content-details .table-top-btn + div.tab-table .subscription-plan .pricetab-block .price-tabs-block h6{color: hsla(272, 40%, 25%, 1);}
.client-section .content-details .table-top-btn + div.tab-table .subscription-plan .pricetab-block .price-tabs-block h6.active{color: #FFF; background: hsla(272, 40%, 25%, 1);}
.content-details.pl-report-details{margin-left: 0px; border-radius: 0;}
.content-details.pl-report-details .report-header-row{margin-bottom: 10px;}
.content-details.pl-report-details .report-period-name{margin-left: 0px !important;}
.business-section .date-picker-refresh{display: flex; align-items: center; gap: 5px; text-align: left;}
.modal-open .rs-picker-menu{z-index: 2000 !important;}
.business-section .table-wrapper.trial-balance-table{margin: 0 auto;}
.business-section .table-wrapper.trial-balance-table table tr:last-child{font-weight: bold;}
.business-section .custom-table.trial-balance-expand-table table tr:last-child{font-weight: normal;}
.business-section .custom-table.trial-balance-expand-table table tr:last-child td{border-right: 1px solid #ececec !important;}
.business-section .table-wrapper.trial-balance-table h5.trial-balance-heading{text-align: center; margin-top: 25px;}
.business-section .table-wrapper.trial-balance-table h6.trial-balance-heading{text-align: center; margin-top: 25px;}
.business-section .table-wrapper.trial-balance-table table tr:last-child td{border-right: none;}
/* .business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2){font-weight: bold;

} */

/* .business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2) td{border-right: none;} */

/* .business-section .custom-table.trial-balance-expand-table table tr:nth-last-child(2){font-weight: normal;} */

.business-section .custom-table.trial-balance-expand-table table tr:nth-last-child(2) td{border-right: 1px solid #ececec !important;}
/* .business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2) .trial-balance-debit-cell,  
.business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2) .trial-balance-credit-cell, .business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2) .trial-balance-account-cell
{border-right: 1px solid #ececec !important;} */

.business-section .table-wrapper.trial-balance-table table tr:last-child .trial-balance-debit-cell,  
.business-section .table-wrapper.trial-balance-table table tr:last-child .trial-balance-credit-cell, .business-section .table-wrapper.trial-balance-table table tr:last-child .trial-balance-account-cell
{border-right: 1px solid #ececec !important;}
.business-section .table-wrapper.trial-balance-table table tr:last-child .expand-cell b{display: none;}
/* .business-section .table-wrapper.trial-balance-table table tr:nth-last-child(2) .expand-cell b{display: none;} */

body.client-section .modal-body .modal-content-details .profile>span{background: hsla(204, 97%, 15%, 1);}
body.client-section .modal-body .modal-content-details .profile h5{color: hsla(204, 97%, 15%, 1);}
body.client-section .modal-body .modal-content-details p strong, body.client-section .modal-body .modal-content-details p.deceased:first-letter{color: hsla(204, 97%, 15%, 1);}
.business-section .content-details .table-top-btn + div.tab-table .current-subscription .btn-active{background: var(--businessprimary-color);}
.client-section .content-details .table-top-btn + div.tab-table .current-subscription .btn-active{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
.client-section .content-details .table-top-btn + div.tab-table .current-subscription .subscription-status i{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%) !important; background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
.client-section .content-details .table-top-btn + div.tab-table .current-subscription .btn-cancel{color: hsla(272, 40%, 25%, 1) !important; border-color: hsla(272, 40%, 25%, 1) !important;}
.payment-cards .stripe-radio-btn.list-group-item{padding: 0; border: none;}
.payment-cards .stripe-radio-btn.list-group-item .me-auto{padding: 10px; text-align: center; border-radius: 0; width: 100%; display: flex; align-items: center; border: none; border-bottom: #ccc 1px solid;}
.payment-cards .list-group .stripe-radio-btn.list-group-item:last-child .me-auto{border-bottom: none;}
.payment-cards .stripe-radio-btn.list-group-item.expired{pointer-events: none;}
.payment-cards .stripe-radio-btn.list-group-item.expired .me-auto{border-color: rgba(255, 0, 0, 0.1) !important; background: rgba(255, 0, 0, 0.1) !important; color: #00000070 !important;}
.payment-cards .stripe-radio-btn.list-group-item .me-auto strong{margin: 0 5px 0 auto;}
.payment-cards .stripe-radio-btn.list-group-item input[type='radio']:checked + .me-auto{background: #f0f0f0;}
.payment-cards .stripe-radio-btn input[type='radio']{width: 100%; height: 100%; position: absolute; left: 0; top: 0; opacity: 0; cursor: pointer;}
.payment-cards .stripe-radio-btn input[type='radio'] + label{border: #40a9ff 1px solid; padding: 10px; text-align: center; border-radius: 10px;}
.payment-cards .stripe-radio-btn input[type='radio']:checked + label{background: #40a9ff; color: #fff;}
.business-section .rs-calendar-table-cell-selected.rs-calendar-table-cell-disabled{opacity: 0.5; pointer-events: none;}
.business-section .rs-btn-disabled{pointer-events: none; cursor: not-allowed;}
.business-section .rs-calendar-table-cell.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{border-radius: 5px;}
.business-section .new-transaction-modal .rs-btn.rs-picker-toggle{width: 100%; display: block; color: hsl(0, 0%, 20%); background: none; border: 1px solid #d9d9d9; border-radius: 5px; font-weight: 400;}
.business-section .new-transaction-modal .rs-picker-toggle-placeholder{color: hsl(0, 0%, 50%);}
.business-section .new-transaction-modal .rs-stack{justify-content: space-between; min-width: auto;}
.business-section .new-transaction-modal .rs-stack .rs-stack-item{flex-grow: 0 !important;}
.business-section .new-transaction-modal .rs-stack .rs-stack-item svg{color: hsl(0, 0%, 50%);}
.selectpicker + svg{/* margin-left: -62px; */
  position: absolute; right: 38px; top: 9px; /* margin-right: 40px; */
  cursor: pointer; color: hsl(0, 0%, 50%);}
.invoice-table tr:last-child td:first-child{border-bottom-left-radius: 10px !important;}
.invoice-table tr:last-child td:last-child{border-bottom-right-radius: 10px !important;}
.invoice-preview-template-button{cursor: pointer; position: absolute; /* top: 26%; right: -88px; */
  top: 0; right: 15px;}
.invoice-payment-body{display: flex; align-items: center; margin-bottom: 15px !important;}
.invoice-date-body{/* display: flex; */
  /* align-items: center; */
  /* gap: 15px; */
}
.invoice-date-body .rs-picker{display: flex;}
.invoice-modal .preview-toggle-switch{position: absolute; right: 60px;}
.invoice-modal .preview-toggle-switch .preview-mode-active:hover{background: var(--businessprimary-color);}
.invoice-modal .preview-toggle-switch .preview-mode-active:hover svg{color: white;}
.invoice-modal .preview-toggle-switch .preview-mode-not-active{background: none; border: 1px solid var(--businessprimary-color);}
.invoice-modal .preview-toggle-switch .preview-mode-not-active svg{color: var(--businessprimary-color);}
.invoice-modal .preview-toggle-switch .preview-mode-active:focus{background-color: var(--businessprimary-color); border: 1px solid var(--businessprimary-color);}
.invoice-modal .preview-toggle-switch .preview-mode-active:focus svg{color: white;}
.invoice-modal .DevicePreview-device.DevicePreview--mobile{background-color: transparent; display: flex; flex-direction: column; flex-grow: 1; border-color: #80858f; border-radius: 30px; border-style: solid; border-width: 60px 10px; margin: 40px auto; max-width: 320px; min-height: 600px; overflow: hidden;}
.invoice-modal .DocumentPreviewModal-frame{border: none; height: 600px; width: 100%; background-color: rgb(255, 255, 255); overflow-x: auto; font-size: 13px;}
.invoice-modal .DocumentPreviewModal-frame .head-invoice{display: flex; align-items: center;}
.invoice-modal .DocumentPreviewModal-frame .invoice-discount{text-align: left; font-size: 15px; display: flex; justify-content: space-between;}
.invoice-modal .DocumentPreviewModal-frame .invoice-gst-title{text-align: left; font-size: 14px; margin: 5px 0px; display: flex; justify-content: space-between;}
.invoice-modal .DocumentPreviewModal-frame .invoice-payable-title{text-align: left; font-size: 15px; display: flex; justify-content: space-between;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-description{font-weight: 600;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-amount{font-weight: 600;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table{width: 100%;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table th{word-wrap: break-word; max-width: 230px; padding-bottom: 15px; padding-right: 15px;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table .invoice-mobile-amount-heading{padding-right: 0px; text-align: right;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table thead{border-bottom: 1px solid #eee;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table tr{border-bottom: 1px solid #eee;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table{margin-bottom: 15px;}
.invoice-modal .DocumentPreviewModal-frame .invoice-mobile-table td{padding: 15px 0px;}
.invoice-modal .preview-mode-desktop{margin: 0px; border-radius: 10px 0px 0px 10px;}
.invoice-modal .preview-mode-mobile{margin: 0px; border-radius: 0px 10px 10px 0px;}
.invoice-discount-area .menu-icon{position: relative; right: 25px; cursor: pointer;}
.invoice-detail-body input::-webkit-inner-spin-button{-webkit-appearance: none; margin: 0;}
.invoice-detail-body input[type=number]{-moz-appearance: textfield;}

.invoice-detail-body .view-discount{/* margin-left: 15px; */
  /* padding: 4px 7px; */
  margin-left: 8px; padding: 2px 5px; border-radius: 5px; background: var(--businessprimary-color); cursor: pointer; border: 1px solid var(--businessprimary-color);}
.invoice-detail-body .view-discount i{color: white;}
.invoice-detail-body .view-discount:hover{background: transparent; border: 1px solid var(--businessprimary-color);}
.invoice-detail-body .view-discount:hover i{color: var(--businessprimary-color);}
.business-section .invoice-desktop-table{border-left: 1px solid black; border-top: 1px solid black;}
.business-section .invoice-desktop-table tr{border-bottom: 1px solid black;}
.business-section .invoice-desktop-table tbody tr{border: none;}
.business-section .invoice-desktop-table tbody tr td{padding-bottom: 24px;}
.business-section .invoice-desktop-table tbody tr:last-child{border-bottom: 1px solid black;}
.business-section .invoice-desktop-table thead{border-bottom: 1px solid black;}
.business-section .invoice-desktop-table td{padding: 8px; border-right: 1px solid black;}
.business-section .invoice-desktop-table tr td:nth-child(2){text-align: right;}
.business-section .invoice-desktop-table thead th:nth-child(2){text-align: right;}
.business-section .invoice-desktop-table th{padding: 8px; border-right: 1px solid black;}
.business-section .invoice-desktop-table th:first-child{width: 80%;}
.business-section .desktop-preview{font-size: 14px;}
.business-section .desktop-preview .invoice-discount{margin-top: 15px;}
.business-section .desktop-preview .invoice-address-title-box .invoice-address-box{border: 1px solid black; padding: 20px; height: 115px; display: flex; flex-direction: column; justify-content: space-evenly;}
.business-section .desktop-preview .invoice-address-title-box  .invoice-title-box{border: 1px solid black; padding: 15px; text-align: center; height: 115px;}
.business-section .desktop-preview .invoice-address-title-box p{margin: 0px;}
.business-section .desktop-preview .invoice-address-title-box  .invoice-title-box h6{margin: 0px; font-size: 16px;} 

.business-section .desktop-preview .invoice-address-title-box .invoice-title-box .invoice-info{margin-bottom: 10px;}
.business-section .desktop-preview .invoice-address-title-box .invoice-title-box .invoice-info p{font-weight: bold;}
.business-section .desktop-preview .invoice-desktop-preview-header hr{margin-top: 8px; margin-bottom: 25px; border-top: 1px solid black;}
.business-section .desktop-preview .invoice-desktop-table tfoot td:first-child{border: none;}
.business-section .desktop-preview .invoice-desktop-table tfoot td:last-child{text-align: right; font-weight: bold;}
.business-section .desktop-preview .invoice-desktop-table .invoice-table-footer{display: flex; justify-content: space-between; font-weight: bold;}
.business-section .desktop-preview .invoice-preview-detach-box{font-style: italic; text-align: center; margin: 20px 0px;}
.business-section .desktop-preview .invoice-preview-detach-box hr{border-top: 1px solid grey;}
.business-section .desktop-preview .detach-row-first{font-size: 14px; display: flex;}
.business-section .desktop-preview .detach-row-first .logo-biller{display: flex; align-items: center; justify-content: space-between;}
.business-section .desktop-preview .detach-row-first .biller-code-box{border: 1px solid; padding: 5px; margin: 7px; font-size: 13px; width: 140px;}
.business-section .desktop-preview .detach-row-first .pay-desc{font-size: 12px;}
.business-section .desktop-preview .invoice-preview-detach-portion-box{padding: 10px; border: 1px solid black;}
.business-section .desktop-preview .detach-row-first .invoice-detach-method-type .invoice-square-box{height: 15px; width: 15px; border: 1px solid black;}
.business-section .desktop-preview .detach-row-first .invoice-detach-method-type{display: flex; gap: 20px; justify-content: center;}
.business-section .desktop-preview .detach-row-first .detach-method-box{height: 100%; flex-direction: column; display: flex; justify-content: space-between; text-align: center;}
.business-section .desktop-preview .detach-title{font-weight: bold; text-align: center;}
.business-section .desktop-preview .detach-row-first .invoice-number-details{margin-top: 10px; display: flex; flex-direction: column; align-items: flex-end;}
.business-section .desktop-preview .detach-row-first .invoice-info-details{display: flex; flex-direction: column; justify-content: space-evenly; align-items: flex-end; height: 100%;}
.business-section .desktop-preview .detach-row-second{margin-top: 10px;}
.business-section .desktop-preview .detach-row-second .pay-desc{font-size: 12px;}
.business-section .desktop-preview .detach-row-second .invoice-square-box{height: 24px; width: 24px; border: 1px solid black;}
.business-section .desktop-preview .detach-row-second .invoice-detach-checkbox{display: flex;}
.business-section .desktop-preview .detach-row-second .invoice-detach-card-number{display: flex; align-items: center; gap: 18px;}
.business-section .desktop-preview .detach-row-second .detach-card-box{display: flex;}
.business-section .desktop-preview .detach-row-third{margin-top: 10px;}
.business-section .desktop-preview .detach-row-third .invoice-dotted-row{width: 150px; border-bottom: 4px dotted black; margin-bottom: 4px;}
.business-section .desktop-preview .detach-row-third .invoice-text-filled-area{display: flex; align-items: flex-end; gap: 12px;}
.invoice-payment-body .invoice-title{margin-right: 8px;}
.business-section .invoice-desktop-preview-header .invoice-business-address{text-align: right;}
.business-section .invoice-desktop-preview-header .invoice-business-address p{margin: 0px;}
.business-section .pl-report-body{width: 100%; box-sizing: border-box; overflow: hidden;}
.business-section .pl-chart .chart-renvenue-expense-legends{margin-top: -4%; float: right; text-align: center;}
.business-section .pl-chart .pl-exp-chart{margin-top: 20px;}
.business-section .pl-chart .chart-renvenue-expense-legends .chart-legends{display: flex; justify-content: space-between; align-items: center; gap: 10px;}
.business-section .pl-chart .pl-exp-chart .chart-legends{margin: 0px;}
.business-section .pl-chart .pl-exp-chart .chart-legends p{display: flex; align-items: center; gap: 10px;}
.business-section .pl-chart .chart-net-profit-label{margin-top: -4%; float: left; width: 100%; padding-left: 30px;}
.business-section .pl-report-table table tbody tr td.expand-cell:has(.not-expaned-cell-pl-report){border-right: none;}
.business-section .bank-transaction-page .search-section .account_select_bank__control{border-radius: 5px; min-height: 33px;}
.business-section .bank-transaction-page .search-section .account_select_bank__control *{font-size: 14px;}
.business-section .bank-transaction-page .search-section .account_select_bank__menu *{font-size: 14px;}
.business-section .bank-transaction-page .search-section .account_select_bank__indicator{padding: 0 5px;}
.business-section .bank-transaction-page .search-section .bank-account-dropdown{margin-left: 20px; width: 220px;}
.business-section .pl-chart .card-content .card-header{margin-bottom: 35px;}
.business-section .pl-chart .card-content .card-header h5{margin-top: 0px;}
/* Styling for MultiSelect DropDown Start */

.business-section .pl-report-body .multiSelect-body-pl-report{display: flex; align-items: center;}
.business-section .pl-report-body .multiSelect-pl-report .p-multiselect.p-inputwrapper{width: 120px; font-size: 12px; margin-left: 12px; margin-right: 12px;}
.business-section .p-multiselect{background: #ffffff; border: 1px solid #ced4da; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; border-radius: 6px;}
.business-section .p-multiselect .p-multiselect-label.p-placeholder{color: #6c757d;}
.business-section .p-multiselect .p-multiselect-label{padding: 0.45rem 0.75rem; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;}
.business-section .p-multiselect .p-multiselect-trigger{background: transparent; color: #6c757d; width: 3rem; border-top-right-radius: 6px; border-bottom-right-radius: 6px;}
.business-section .p-icon{width: 1rem; height: 1rem;}
.business-section .p-checkbox{width: 18px; height: 20px;}
.business-section .p-multiselect-panel{background: #ffffff; color: #495057; border: 0 none; border-radius: 6px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);}
.business-section .p-multiselect-panel .p-multiselect-header{padding: 0.75rem 1.25rem; border-bottom: 1px solid #dee2e6; color: #343a40; background: #f8f9fa; margin: 0; border-top-right-radius: 6px; border-top-left-radius: 6px;}
.business-section .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext{padding-right: 1.75rem;}
.business-section .p-inputtext{font-family: inherit; font-feature-settings: inherit; font-size: 12px; color: #495057; background: #ffffff; padding: 5px 10px; border: 1px solid #ced4da; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; appearance: none; border-radius: 5px;}
.business-section .p-checkbox .p-checkbox-box{border: 2px solid #ced4da; background: #ffffff; width: 18px; height: 18px; color: #495057; border-radius: 5px; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;}
.business-section .p-multiselect-panel .p-multiselect-items{padding: 0.75rem 0; margin-bottom: 0px;}
.business-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox{margin-right: 5px;}
.business-section .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container{margin: 0 0.5rem;}
.business-section .p-checkbox .p-checkbox-box .p-checkbox-icon{transition-duration: 0.2s; color: #ffffff; font-size: 14px;}
.business-section .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{right: 0.75rem; color: #6c757d;}
.business-section .p-multiselect-close{padding: 6px;}
.business-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item{margin: 0; padding: 5px 10px; border: 0 none; color: #495057; background: transparent; transition: box-shadow 0.2s; border-radius: 0; align-items: center;}
.business-section .p-multiselect-items-wrapper .p-multiselect-item span{font-size: 12px;}
.business-section .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{border-color: var(--businessprimary-color);}
.business-section .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{background-color: var(--businessprimary-color);}
.business-section .p-checkbox .p-checkbox-box.p-highlight{border-color: var(--businessprimary-color); background-color: var(--businessprimary-color);}
.business-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{background-color: var(--offwhite-color); color: var(--black-color);}
.business-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{box-shadow: inset 0 0 0 0.15rem var(--offwhite-color);}
.business-section .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{background-color: var(--offwhite-color);}
.business-section svg.p-icon{pointer-events: unset;}
.business-section .p-multiselect-footer .multiSelect-button{font-size: 14px; text-transform: uppercase; height: 28px; line-height: 0; margin: 10px 6px; padding: 10px;}
.business-section .p-multiselect-footer hr{margin-top: 10px; margin-bottom: 5px;}
.business-section .multiSelect-pl-report.multiSelect-dashboard{display: flex; align-items: center; gap: 8px; margin-left: 10px;}
.business-section .multiSelect-pl-report.multiSelect-dashboard .p-multiselect-label, .business-section .multiSelect-pl-report.multiSelect-dashboard .p-multiselect-label.p-placeholder{padding: 5px 10px !important; font-size: 12px;}
.business-section .multiSelect-pl-report.multiSelect-dashboard label{margin-bottom: 0px;}

/* Styling for MultiSelect DropDown End */
.business-section .reconcile-bank-account .account_select_bank__control{border-radius: 10px;}
.business-section .reconcile-bank-account .account_select_bank__control *{font-size: 12px;}
.business-section .reconcile-bank-account .account_select_bank__menu *{font-size: 12px;}
.business-section .reconcile-bank-account .bank-account-dropdown{display: flex; align-items: center; gap: 12px;}
.business-section .reconcile-bank-account .bank-account-dropdown .account_select_bank__control{width: 220px; min-height: 30px; border-radius: 5px;}
.business-section .reconcile-bank-account .bank-account-dropdown .account_select_bank__control *{margin: 0; padding-top: 0; padding-bottom: 0;}
.business-section .reconcile-bank-account .bank-account-dropdown p{margin: 0px;}
.business-section .standard-paragrah-list-desc .editor-class.rdw-editor-main{margin: 0px;}
body .modal-dialog.small-popup.job-modal .modal-body{text-align: left;}
body .modal-dialog.small-popup.task-modal .modal-body{text-align: left;}
.business-section .standard-paragrah-table tr th:first-child{width: 20%;}
.business-section .paragrah-editor .rdw-editor-wrapper{position: relative; z-index: 0;}
.business-section .invoice-description{position: relative;}
.business-section .invoice-description .invoice-paragraph{position: absolute; right: 10px; top: 5px;}
.business-section .invoice-description .editor-class.rdw-editor-main{margin: 0px; border-radius: 5px; border: 1px solid rgb(217, 217, 217); height: 45px; padding: 0px 10px;}
.business-section .invoice-table table tr th:first-child{width: 10%;}
.business-section .invoice-table table tr th:nth-child(2){width: 10%;}
.business-section .invoice-table table tr th:nth-child(3){width: 15%;}
.business-section .desktop-preview .invoice-modal{height: 100%; padding: 10px;}
.business-section button.slick-arrow{padding: 0; width: 36px; height: 36px; z-index: 1; background-color: transparent; border: var(--businessprimary-color) 1px solid; background: var(--white-color); font-weight: 700;}
.business-section button.slick-arrow::before{font-family: inherit; color: var(--businessprimary-color); opacity: 1; line-height: 0.5;}
.business-section button.slick-arrow.slick-prev{left: -10px;}
.business-section button.slick-arrow.slick-next{right: -10px;}
.business-section button.slick-arrow:hover{background: var(--businessprimary-color);}
.business-section button.slick-arrow:hover::before{color: var(--white-color);}
.business-section .archived-transactions-date-range{justify-content: flex-end;}
.business-section .standard-paragrah-table thead tr th:first-child{width: 14%;}
.business-section .standard-paragrah-table thead tr th:nth-child(3){width: 7%;}
.business-section .refund-process .reconcile-select-filter-body{display: flex; gap: 10px; margin-bottom: 5px; align-items: center;}
.business-section .refund-process .reconcile-select-filter-body .reconcile-filters{display: flex; gap: 15px; margin-left: auto;}
.business-section .invoice-settings-form .number-option-body{display: flex; gap: 20px; padding: 10px 0px;}
.business-section .invoice-settings-form .number-option-body label{display: flex; align-items: center; gap: 5px; font-weight: normal;}
.business-section .invoice-settings-form .number-option-body label input{margin: 0px;}
.business-section .invoice-settings-form .settings-heading{font-weight: 500; font-size: 18px;}
.business-section .invoice-settings-form .settings-box{border: 1px solid rgb(217, 217, 217); padding: 10px; border-radius: 10px;}
.business-section .invoice-settings-form .payment-option-body{display: flex; padding: 10px 0px; flex-wrap: wrap; column-gap: 12px; row-gap: 2px;}
.business-section .invoice-settings-form .payment-option-body label{display: flex; align-items: center; gap: 5px; font-weight: normal;}
.business-section .invoice-settings-form .payment-option-body label input{margin: 0px;}
.business-section .invoice-settings-form .email-option-body .email-box-body{padding: 10px;}
.business-section .invoice-settings-form .email-option-body .email-form-box{display: flex; flex-direction: column; gap: 15px; margin-top: 10px;}
.business-section .invoice-settings-form .email-option-body .editor-class.rdw-editor-main{margin: 0px;}
.business-section .invoice-settings-form .payment-option-box{margin-top: 20px;}
.business-section .invoice-settings-form .settings-button-body{margin-top: 20px; display: flex; gap: 20px;}
.business-section .invoice-layout-page.content-details .table-top-btn + div.tab-table{background: var(--white-color); border-radius: 30px; padding: 30px; border: 1px solid #f1f1f1; float: left; width: 100%; margin-top: -1px;}
.business-section .invoice-settings-form .main-setting-heading{font-size: 25px; font-weight: 500;}
.business-section .invoice-settings-form .invoice-layout-detail-setings{display: flex; flex-direction: column; gap: 20px; font-size: 14px;}
.business-section .invoice-settings-form .invoice-layout-detail-setings .payment-option-box{margin-top: 0px;}
.business-section .invoice-settings-form .client-option-body{display: flex; padding: 10px 0px; align-items: center; flex-wrap: wrap; column-gap: 12px; row-gap: 2px;}
.business-section .invoice-settings-form .client-option-body label{display: flex; align-items: center; gap: 5px; font-weight: normal;}
.business-section .invoice-settings-form .client-option-body label input{margin: 0px;}
.business-section .invoice-settings-form .invoice-detail-option-body{display: flex; padding: 10px 0px; align-items: center; gap: 15px;}
.business-section .invoice-settings-form .invoice-detail-option-body label{display: flex; align-items: center; gap: 5px; font-weight: normal;}
.business-section .invoice-settings-form .invoice-detail-option-body label input{margin: 0px;}
.business-section .invoice-preview-layout-body{padding: 15px; border: 1px solid #f1f1f1; border-radius: 10px; font-size: 14px; font-family: "Poppins";}
.business-section .invoice-settings-form .table-top-btn + div.tab-table{border-radius: 0px 30px 30px 30px !important;}
.business-section .invoice-settings-form .form-detail-footer-body{display: flex; flex-direction: column;}
.content-details .entry-card .form-box .three-col-width{width: calc(33.33% - 6.66px);}
.business-section .amount-filter-field-set{width: 100%; padding: 0.35em 0.625em 0.75em; margin: 0 2px; border: 1px solid #ced4da; border-radius: 10px;}
.business-section .amount-filter-field-set legend{border: none; width: fit-content; font-size: inherit; margin: 0px; padding: 5px;}
.business-section .amount-form-box{display: flex; gap: 15px; padding: 5px;}
.business-section .memorization-form-box{gap: 0px;}
.business-status-graph .recharts-responsive-container{position: relative;}
.business-status-tooltip label{font-weight: 500; margin-bottom: 2px;}
.business-status-graph{position: relative;}
.business-status-graph .business-status-filter{display: flex; justify-content: space-between; align-items: center; margin-bottom: 14px; gap: 15px;}
.business-status-graph .recharts-tooltip-wrapper{z-index: 1;}
.business-status-filter div[data-ui-name="MonthRangePicker.Trigger"]{height: 36px;}
div[data-ui-name="MonthRangePicker.Calendar"] button{padding: 0; background: none; color: var(--businessprimary-color);}
div[data-ui-name="MonthRangePicker.Calendar"] button:hover{border: none; color: #191b23; background: #e0e1e9; border-radius: 6px;}
div[data-ui-name="MonthRangePicker.Period"] button:hover{padding: 0; background: none; color: var(--businessprimary-color);}
button[data-ui-name="MonthRangePicker.Apply"]:hover{padding: 0; background: none !important; color: var(--businessprimary-color) !important;}
button[data-ui-name="MonthRangePicker.Reset"]:hover{padding: 0; background: none !important; color: var(--businessprimary-color) !important;}
.list-business-table tr th:nth-last-child(3){width: 500px;}
.business-status-filter .account_select_graph__control{border-radius: 5px; min-height: 30px;}
.business-status-filter .account_select_graph__control *{font-size: 12px; margin: 0; padding: 0;}
.business-status-filter .account_select_graph__control .account_select_graph__single-value{padding: 0 10px;}
.business-status-filter .account_select_graph__menu{width: 220px;}
.business-status-filter .account_select_graph__menu *{font-size: 12px; width: 220px; overflow-x: hidden;}
.business-status-filter .account_select_graph__control .account_select_graph__indicator{padding: 0 5px;}
.business-status-filter .bank-account-dropdown{flex: 1;}
.business-section .standard-paragrah-table thead tr th:nth-child(3){
  width: 7%;
}

.business-section .refund-process .reconcile-select-filter-body{
  display: flex;
  justify-content: space-between;
}

.business-section .refund-process .reconcile-select-filter-body .reconcile-filters{
  display: flex;
  gap: 15px;
}

.business-section .invoice-settings-form .number-option-body{
  display: flex;
  gap: 20px;
  padding: 10px 0px;
}

.business-section .invoice-settings-form .number-option-body label{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
}

.business-section .invoice-settings-form .number-option-body label input{
  margin: 0px;
}

.business-section .invoice-settings-form .settings-heading{
  font-weight: 500;
  font-size: 18px;
}

.business-section .invoice-settings-form .settings-box{
  border: 1px solid rgb(217, 217, 217);
  padding: 10px;
  border-radius: 10px;
}

.business-section .invoice-settings-form .payment-option-body {
  display: flex;  
  padding: 10px 0px;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 2px;
}

.business-section .invoice-settings-form .payment-option-body label{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
}

.business-section .invoice-settings-form .payment-option-body label input{
  margin: 0px;
}

.business-section .invoice-settings-form .email-option-body .email-box-body{
  padding: 10px;
}

.business-section .invoice-settings-form .email-option-body .email-form-box{
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.business-section .invoice-settings-form .email-option-body .editor-class.rdw-editor-main{
  margin: 0px;
}

/* .business-section .invoice-settings-form .payment-option-box{
  margin-top: 20px;
} */

.business-section .invoice-settings-form .settings-button-body{
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.business-section .invoice-layout-page.content-details .table-top-btn + div.tab-table {
  background: var(--white-color);
  border-radius: 30px;
  padding: 30px;
  border: 1px solid #f1f1f1;
  float: left;
  width: 100%;
  margin-top: -1px;
}

.business-section .invoice-settings-form .main-setting-heading{
  font-size: 25px;
  font-weight: 500;
}

.business-section .invoice-settings-form .invoice-layout-detail-setings{
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 14px;
}

.business-section .invoice-settings-form .invoice-layout-detail-setings .payment-option-box{
  margin-top: 0px;
}

.business-section .invoice-settings-form .client-option-body {
  display: flex;  
  padding: 10px 0px;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 2px;
}

.business-section .invoice-settings-form .client-option-body label{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
}

.business-section .invoice-settings-form .client-option-body label input{
  margin: 0px;
}

.business-section .invoice-settings-form .invoice-detail-option-body {
  display: flex;  
  padding: 10px 0px;
  align-items: center;
  gap: 15px;
}

.business-section .invoice-settings-form .invoice-detail-option-body label{
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: normal;
}

.business-section .invoice-settings-form .invoice-detail-option-body label input{
  margin: 0px;
}

.business-section .invoice-preview-layout-body{
  padding: 15px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  font-size: 14px;
  font-family: "Poppins";
}

.business-section .invoice-settings-form .table-top-btn + div.tab-table{
  border-radius: 0px 30px 30px 30px !important;
}

.business-section .invoice-settings-form .form-detail-footer-body{
  display: flex;
  flex-direction: column;
}

.content-details .entry-card .form-box .three-col-width {
  width: 32%;
}

.business-section .amount-filter-field-set{
  width: 100%;
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.business-section .amount-filter-field-set legend{
  border: none;
  width: fit-content;
  font-size: inherit;
  margin: 0px;
  padding: 5px;
}

.business-section .amount-form-box {
  display: flex;
  gap: 15px;
  padding: 5px;
}

.business-section .memorization-form-box{
  gap: 0px;
  /* justify-content: space-between; */
}

.business-status-graph .recharts-responsive-container {
  position: relative;
}

.business-status-tooltip label{
  font-weight: 500;
  margin-bottom: 2px;
}

.business-status-graph{
  /* display: flex; */
  position: relative;
  /* justify-content: space-between; */
}

.business-status-graph .business-status-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  gap: 15px;
}

.business-status-graph .recharts-tooltip-wrapper {
  z-index: 1;
}

.business-status-filter div[data-ui-name="MonthRangePicker.Trigger"] {
  height: 36px;
}

div[data-ui-name="MonthRangePicker.Calendar"] button {
  padding: 0;
  background: none;
  color: var(--businessprimary-color);
}

div[data-ui-name="MonthRangePicker.Calendar"] button:hover {
    border: none;
    color: #191b23;
    background: #e0e1e9;
    border-radius: 6px;
}

div[data-ui-name="MonthRangePicker.Period"] button:hover {
  padding: 0;
  background: none;
  color: var(--businessprimary-color);
}

button[data-ui-name="MonthRangePicker.Apply"]:hover {
  padding: 0;
  background: none !important;
  color: var(--businessprimary-color) !important;
}

button[data-ui-name="MonthRangePicker.Reset"]:hover {
  padding: 0;
  background: none !important;
  color: var(--businessprimary-color) !important;
}

.list-business-table tr th:nth-last-child(3){
  width: 500px;
}

.business-status-filter .account_select_graph__control{
  border-radius: 5px;
}

.business-status-filter .account_select_graph__control *{
  font-size: 14px;
}
.business-status-filter .account_select_graph__menu{
  width: 220px;
}
.business-status-filter .account_select_graph__menu *{
  font-size: 14px;
  width: 220px;
  overflow-x: hidden;
}

.business-status-filter .bank-account-dropdown{
  flex: 1;
}

.client-check-input{
  /* display: flex; */
  /* align-items: center; */
  display: block;}
.client-check-input label{width: 100%;}
.client-check-input .client-checkbox-body{min-height: 38px; display: flex; align-items: center; gap: 8px;}
.head-client-check-box-list{display: flex; align-items: center; justify-content: center;}
.head-client-check-box-list input, .head-client-check-box-list label{margin: 0px;}
.business-section .dashboard-container .active-cards .row .card{width: calc(25% - 11.25px); box-sizing: border-box;}
.business-section .accountSelect-dashboard{display: flex; gap: 8px; align-items: center; margin-left: 10px; width: 100%;}
.business-section .accountSelect-dashboard label{margin: 0px;}
.business-section .accountSelect-dashboard .account_select_graph__control{width: 220px;}
@media (max-width: 991px){.business-section .accountSelect-dashboard .account_select_graph__control{width: 100%;}
  .business-status-filter .account_select_graph__menu *{width: 100%;}
  .business-status-filter .account_select_graph__menu{width: 100%; overflow: auto;}
  .business-status-filter .account_select_graph__menu *{width: 100%; /* overflow: auto; */ overflow: visible;}
}
.business-section .dashboard-container .summary-filter{display: flex; align-items: center;}
.business-section .dashboard-container .summary-filter *{font-size: 12px;}
.modal-open .modal .small-review .modal-body{max-height: 75vh; overflow: auto;}
.modal-open .modal .small-review .modal-content{padding-bottom: 20px; 
}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .reconcilation-clear-select-body .selectpicker{width: 100%;}
.business-section .reconcilation-clear-select-body{width: calc(100% - 110px); position: relative;}
@media (max-width: 991px){.business-section .reconcilation-clear-select-body{width: 100% !important;}}
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .reconcilation-clear-select-body .selectpicker{width: 100% !important;}

.client-check-input .client-checkbox-body{
  min-height: 38px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.head-client-check-box-list{
  display: flex;
  align-items: center;
  justify-content: center;
}

.head-client-check-box-list input, .head-client-check-box-list label{
  margin: 0px;
}

.business-section .dashboard-container .active-cards .row .card{
  width: calc(25% - 11.25px);
  box-sizing: border-box;
}

.business-section .accountSelect-dashboard{
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 10px;
  width: 100%;
}

.business-section .accountSelect-dashboard label{
  margin: 0px;
}

.business-section .accountSelect-dashboard .account_select_graph__control{
  width: 220px;
}

@media (max-width: 991px) {
  .business-section .accountSelect-dashboard .account_select_graph__control{
    width: 100%;
  }
  .business-status-filter .account_select_graph__menu *{
    width: 100%;
  }
  .business-status-filter .account_select_graph__menu{
    width: 100%;
    overflow: auto;
  }
  .business-status-filter .account_select_graph__menu *{
    width: 100%;
    /* overflow: auto; */
    overflow: visible;
  }
}

.business-section .dashboard-container .summary-filter{
  display: flex;
  align-items: center;
}

.modal-open .modal .small-review .modal-body{
  max-height: 75vh;
  overflow: auto;
}

.modal-open .modal .small-review .modal-content {
  padding-bottom: 20px; 
}

.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .reconcilation-clear-select-body .selectpicker{
  width: 100%;
}

.business-section .reconcilation-clear-select-body{
  width: calc(100% - 110px);
  position: relative;
}

@media (max-width: 991px) {
  .business-section .reconcilation-clear-select-body{
    width: 100% !important;
  }
  .refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .reconcilation-clear-select-body .selectpicker{
    width: 100% !important;
  }
}

.expand-cell-header {
  pointer-events: none;
}

.reconciliation-status-header {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  position: relative;
}

.reconciliation-status-header .recharts-legend-wrapper{
  position: relative !important;
}

.entry-section .myprofile-card .auth-form{
  width: 100%;
  border: #dedede 1px solid;
  border-radius: 20px;
  padding: 30px;
  background: #f1f1f1;
  margin-top: 15px;
}

.entry-section .myprofile-card .auth-form h2{
  margin: 0px;
}

.entry-section .myprofile-card .auth-form h4 {
  margin: 5px 0 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
}

.entry-section .myprofile-card .auth-form .backup-email-body{
  display: flex;
  /* align-items: center; */
  align-items: flex-end;
  gap: 15px;
}

.entry-section .myprofile-card .auth-form .authentication-method-body{
  margin-left: 15px;
}

.entry-section .myprofile-card .auth-form .auth-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.tfa-content .ant-image-mask {
  width: 200px;
  border-radius: 0px;
  /* height: 200px; */
  height: inherit;
}

.login-screen form .auth-text {
  background: url(../images/auth-mobile.png) no-repeat left center;
  min-height: 63px;
  font-size: 16px;
  text-align: center;
  padding: 6px 0 6px 45px;
  margin: 0 auto 10px;
  width: 396px;
}

/* .rs-picker-daterange-menu .rs-stack .rs-stack-item .rs-picker-daterange-predefined.rs-stack {
  gap: 5px !important;
  padding: 10px;
}

.rs-picker-daterange-menu .rs-stack .rs-stack-item:has(.rs-picker-daterange-predefined.rs-stack) {
  height: 380px;
  max-width: 100%;
  border-right: 1px solid #dedede;
} */

.rs-picker-daterange-menu .rs-stack .rs-stack-item .rs-picker-toolbar-ranges.rs-stack {
  gap: 5px !important;
  display: inline-flex;
  width: 425px;
}
.expand-cell-header{pointer-events: none;}
.reconciliation-status-header{display: flex; gap: 15px; justify-content: space-between; position: relative;}
.reconciliation-status-header .recharts-legend-wrapper{position: relative !important;}
.entry-section .myprofile-card .auth-form{width: 100%; border: #dedede 1px solid; border-radius: 20px; padding: 30px; background: #f1f1f1; margin-top: 15px;}
.entry-section .myprofile-card .auth-form h2{margin: 0px;}
.entry-section .myprofile-card .auth-form h4{margin: 5px 0 0; text-transform: uppercase; font-weight: 700; font-size: 20px;}
.entry-section .myprofile-card .auth-form .backup-email-body{display: flex; /* align-items: center; */
  align-items: flex-end; gap: 15px;}
.entry-section .myprofile-card .auth-form .authentication-method-body{margin-left: 15px;}
.entry-section .myprofile-card .auth-form .auth-title{display: flex; align-items: center; justify-content: space-between; width: 100%; margin-bottom: 15px;}
.tfa-content .ant-image-mask{width: 200px; border-radius: 0px; height: 200px;}
.login-screen form .auth-text{background: url(../images/auth-mobile.png) no-repeat left center; min-height: 63px; font-size: 14px; text-align: center; padding: 6px 0 6px 45px; margin: 0 auto 10px; width: 396px;}
/* .rs-picker-daterange-menu .rs-stack .rs-stack-item .rs-picker-daterange-predefined.rs-stack{gap: 5px !important; padding: 10px;}
.rs-picker-daterange-menu .rs-stack .rs-stack-item:has(.rs-picker-daterange-predefined.rs-stack){height: 380px; max-width: 100%; border-right: 1px solid #dedede;} */

.rs-picker-daterange-menu .rs-stack .rs-stack-item .rs-picker-toolbar-ranges.rs-stack{gap: 5px !important; display: inline-flex; width: 300px;}
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-ranges .rs-stack-item button:hover, 
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-ranges .rs-stack-item button:active, 
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-ranges .rs-stack-item button:focus{text-decoration: none; background-color: var(--businesssecondary-color); color: white !important; transition: all 0.3s;}
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-right  button:hover, 
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-right  button:active, 
.rs-picker-daterange-menu .rs-picker-toolbar .rs-picker-toolbar-right  button:focus{background-color: var(--businesssecondary-color); color: white !important; transition: all 0.3s;}
.modal-dialog.full-popup{width: calc(100% - 30px) !important;}
.modal-open .modal-dialog.full-popup button.btn-close{width: 30px; height: 30px; background: var(--black-color); padding: 0; position: absolute; right: 15px; color: var(--white-color);}
.modal-open .modal .modal-dialog.full-popup{left: 50%; top: 50%; transform: translate(-50%, -50%); margin: 0;}
.modal-open .modal .full-popup .excel-table-list .react-bootstrap-table{max-height: 650px; overflow: auto;}
.excel-table-list td.edit-cell-excel-class{width: inherit; height: inherit;}
.excel-table-list td{padding: 10px !important; font-size: 13px !important;}
.excel-table-list th{padding: 10px !important; font-size: 13px !important;}
.excel-table-list .react-bootstrap-table table tbody tr td.edit-cell-excel-class *{font-size: inherit; padding: 0; margin: 0; border: none; line-height: 1; width: auto; height: auto;}
.excel-table-list .react-bootstrap-table table tbody tr td.edit-cell-excel-class input{text-indent: 0; padding: 0; margin: 0; border: none; text-align: left; width: 100%; max-width: 100%; min-width: inherit; border: #CCC 1px solid; border-radius: 3px; padding: 0 3px;}
.excel-table-list .react-bootstrap-table table tbody tr td.edit-cell-excel-class select{border: 1px solid; padding: 2px 10px; border-radius: 3px;}
.excel-table-list .reset-expansion-style tbody tr:last-child{border-bottom-left-radius: 15px !important; border-bottom-right-radius: 15px !important;}
.excel-table-list .reset-expansion-style thead tr{border-top-left-radius: 15px !important; border-top-right-radius: 15px !important;}

.excel-table-list .reset-expansion-style td, .excel-table-list .reset-expansion-style th{border-radius: 0px !important;}
.excel-table-list #pageDropDown{background: transparent;}
.excel-table-list #pageDropDown:hover{color: #000 !important;}
.excel-table-list .page-item a{border: none; color: #999; padding: 0px 8px;}
.excel-table-list .page-item:hover a{background: transparent; color: #000 !important;}
.excel-table-list .page-item a:hover, .excel-table-list .page-item a:focus, .excel-table-list .page-item a:active{background: transparent; color: #000 !important;}
.excel-table-list .page-item.active a{border: none; color: #000; background: none; padding: 0px 8px;}
.excel-table-list .pagination{margin: 0px !important;}
.excel-table-list .react-bootstrap-table-pagination{display: flex; align-items: center; justify-content: space-between; margin-bottom: 20px;}
.excel-table-list .react-bootstrap-table .error-cell{color: red;}
.excel-table-list .react-bootstrap-table table tbody tr td.edit-cell-excel-class .alert-danger strong{font-weight: 500;}
.excel-table-list .react-bootstrap-table table tbody tr td.edit-cell-excel-class .alert-danger{background: transparent;}
.coa-table .selection-cell input[type="checkbox"]{margin: 0px;}
.coa-table .selection-cell{width: 10px;}
.reconcilation-column-group-input .reconcilation-clear-job-task-body{position: relative;}
.reconcilation-column-group-input .reconcilation-clear-job-task-body  .selectpicker + svg{position: absolute; top: 30%; right: 26%; cursor: pointer; color: hsl(0, 0%, 50%);}
.bank-transaction-page .table-top-btn .search-section p{margin-bottom: 5px;}
.bank-transaction-page .table-top-btn{align-items: flex-end;}
.bank-transaction-page .table-top-btn .table-btn{align-items: flex-end;}
.excel-table-list .react-bootstrap-table .error-highlight-cell{color: red; border: 1px solid red;}
.payment-cardtabs p label{margin-top: 12px;}
.payment-cardtabs p:first-child label{margin-top: 0px;}
.client-section .content-details .search-section form .btn:hover{background: url(../images/search-black.png) no-repeat center;}
.business-section .content-details .search-section form .btn-close{padding: 0; min-width: inherit; font-size: 12px; background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times.svg) no-repeat center; font-size: 0; width: 9px; position: absolute; right: 12px; z-index: 1; height: 33px; filter: brightness(0); top: 0;}
.client-section .content-details .search-section form .btn-close{padding: 0; min-width: inherit; font-size: 12px; background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times.svg) no-repeat center; font-size: 0; width: 9px; position: absolute; right: 12px; z-index: 1; height: 33px; filter: brightness(0); top: 0;}
.client-section .content-details .search-section form .btn-close:hover{background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times.svg) no-repeat center;}
.content-details .search-section form .btn-close{padding: 0; min-width: inherit; font-size: 12px; background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times.svg) no-repeat center; font-size: 0; width: 9px; position: absolute; right: 12px; z-index: 1; height: 33px; filter: brightness(0); top: 0;}
.client-section div[data-ui-name="MonthRangePicker.Popper"] button[type="button"]:hover{color: black !important;}
.excel-table-list button{padding: 9px 20px;}
.excel-table-list .excel-list-footer{display: flex; align-items: center; justify-content: space-between; font-size: 15px;}
.excel-table-list thead{position: sticky; top: -1px; 
}
.excel-table-list thead th{background-color: #333; resize: horizontal; overflow: auto; min-width: auto;}
.excel-table-list thead th.selection-cell-header{resize: none;}
/* .excel-table-list .table thead{position: relative;} */
.excel-table-list .table thead::before{position: absolute; width: 30px; height: 100%; content: ''; left: 0; top: -1px; background: #FFF;}
.excel-table-list .table thead::after{position: absolute; width: 30px; height: 100%; content: ''; right: 0; top: -1px; background: #FFF;}
.excel-table-list .table thead tr{position: relative; z-index: 1;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .chartofselect__control, .reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .reconcilation-column-group-input .taskselect__control, 
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .reconcilation-column-group-input .jobselect__control{margin-bottom: 0; border-radius: 5px; min-height: 30px;}
.react-bootstrap-table
  table
  tbody tr td .reconcilation-column-group-input{display: flex; flex-direction: column; row-gap: 7px;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .taskselect__control{margin-bottom: 0;}
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .chartofselect__control *, .reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .reconcilation-column-group-input .taskselect__control *, 
.reconcile-transactions-list
  .react-bootstrap-table
  table
  tbody tr td .reconcilation-column-group-input .jobselect__control *{margin: 0; padding-top: 0; padding-bottom: 0;}
.list-business-table .launch-payroll{font-size: 12px; padding: 9px 12px;}
.react-bootstrap-table table tbody tr 
td.reset-expansion-style .content-details 
.list-business-table .react-bootstrap-table table thead th{padding: 12px;}
.custom-table .table-pagination .pager .pages{align-items: center;}
.excel-table-list .handle{cursor: move;}
.pl-report-table .react-bootstrap-table table .expand-cell-header{width: 50px !important;}
.pl-report-table .react-bootstrap-table table{table-layout: fixed;}
.pl-report-table .trial-balance-expand-table .react-bootstrap-table table{table-layout: auto; /* table-layout: fixed; */
}
.pl-report-table .trial-balance-expand-table .react-bootstrap-table table tbody tr td:nth-child(2){white-space: normal;}

.pl-report-table .react-bootstrap-table table tr td.reset-expansion-style .row-expansion-style .content-details{overflow: auto;}
.monthly-bank-reconciliation{padding: 15px; font-size: 14px; padding-top: 0px;}
.monthly-bank-reconciliation > .row:first-child > div{border-radius: 10px 10px 0 0;}
.monthly-bank-reconciliation > .row:last-child > div:first-child{border-radius: 0 0 0 10px;}
.monthly-bank-reconciliation > .row:last-child > div:last-child{border-radius: 0 0 10px 0;}
.monthly-bank-reconciliation .center-align{text-align: center;}
.monthly-bank-reconciliation .right-align{text-align: right;}
.monthly-bank-reconciliation .left-align{text-align: left;}
.monthly-bank-reconciliation .dark-row{background: transparent
  linear-gradient( 180deg, var(--businessprimary-color) 0%, var(--businesssecondary-color) 100%
  )
  0% 0% no-repeat padding-box; /* background: var(--businesssecondary-color); */
  color: white; padding: 10px;}
.monthly-bank-reconciliation .white-row{background: white;}
.monthly-bank-reconciliation .gray-row{background: rgb(167 167 167);}
.monthly-bank-reconciliation .light-theam-row{background: #734b6db5;}
.monthly-bank-reconciliation .border{border: 1px solid #CCC; padding: 5px 10px;}
.monthly-bank-reconciliation-btn{display: grid;}
.monthly-bank-reconciliation-btn > div{border: none; background: var(--businesssecondary-color); display: flex; align-items: center; gap: 10px; font-weight: 500; color: var(--white-color); background-color: var(--businesssecondary-color) !important;}
.monthly-bank-reconciliation-btn > div:hover{border: none; background: var(--businesssecondary-color); display: flex; align-items: center; gap: 10px; font-weight: 500; color: var(--white-color);}
.full-content-block.monthly-bank-reconciliation{height: auto;}
.business-section .monthly-bank-reconciliation .light-theam-row{color: white; font-weight: 500;}
@media(min-width: 992px){
  .monthly-bank-reconciliation .row{display: flex; flex-wrap: wrap;}
}
.journal-entry-status{border-radius: 20px; box-shadow: #00000009 0 20px 34px; padding: 20px; background: #FFF; border: #F1F1F1 1px solid; gap: 20px; display: flex; margin-bottom: 30px; align-items: center; flex-flow: wrap;}
.journal-entry-status p{margin: 0; font-size: 18px;}
.journal-entry-status p i{font-size: 26px; font-weight: 700; font-style: normal; display: flex; flex-direction: column; justify-content: center; align-items: center;}
.journal-table input[type=text]{border: 1px solid #d9d9d9; border-radius: 6px; width: 100%; padding: 8px;}
.journal-table .selectbox__control.css-1s2u09g-control{border-radius: 10px;}
.journal-entry-status form{margin-right: auto;}
.journal-entry-status form .rs-picker-toggle{justify-content: center;}
.journal-table .selectbox__placeholder{white-space: nowrap;}
.journal-entry{display: flex; gap: 20px;}
.journal-table .selectbox__control{min-width: 150px; border-radius: 10px;}
.journal-table textarea{border-radius: 10px; box-sizing: border-box; margin: 0; font-variant: tabular-nums; list-style: none; font-feature-settings: "tnum"; position: relative; display: inline-block; width: 100%; min-width: 0; padding: 4px 11px; color: var(--black-color); font-size: 14px; line-height: 1.5715; background-color: #fff; background-image: none; border: 1px solid #d9d9d9; transition: all .3s; resize: none; overflow: auto;}
@media(max-width: 1800px){
  .journal-table .contactname-select__control{min-width: inherit; max-width: 100%;}
  .react-bootstrap-table table tbody tr td{padding: 5px 10px;}
  .react-bootstrap-table table thead th{padding: 5px 10px;}
  .jounal-entry-card{padding: 15px;}
  .content-details, .header-content-details{padding: 0;}
}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .right-block .rightalign .amount-green{color: #0da500; font-weight: 500;}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content .right-block .rightalign .amount-red{color: #f00; font-weight: 500;}
body form .form-group .amount-color-input.rag-green{color: #0DA500;}
body form .form-group .amount-color-input.rag-red{color: #f00;}
.business-section .audit-statement table td span a{border-bottom: 1px dashed;}
.refund-process .refund-process-box .refund-card-box .matching-btn.memorise-btn.tick-btn{position: absolute; right: 5px; top: 5px; min-width: 20px; min-height: 20px; font-size: 12px;}
.fetch-modal p{margin-bottom: 5px;}
.fetch-modal .account_select_bank__control{border-radius: 10px;}
.fetch-modal .account_select_bank__control *{font-size: 14px;}
.fetch-modal .account_select_bank__menu *{font-size: 14px;}
.fetch-modal .account_select_bank__control{width: 220px;}
.modal-open .modal.fetch-modal .small-review .modal-body{max-height: inherit; overflow: visible;}
.integration-card .card-body .yodlee-startTransaction-date{margin-top: 15px; display: flex; flex-direction: column;}
.integration-card .card-body .yodlee-startTransaction-date p{margin-bottom: 5px;}
.integration-card .card-body .yodlee-startTransaction-date .rs-btn .rs-stack{width: 100%; text-align: left;}
.modal-open .modal.cash-transaction-modal .small-review .modal-body{max-height: inherit; overflow: visible;}
.business-section .monthly-bank-reconciliation-page .bank-account-dropdown{margin-left: 20px; display: flex; align-items: center; gap: 12px;}
.business-section .monthly-bank-reconciliation-page .bank-account-dropdown p{margin: 0px;}
.business-section .monthly-bank-reconciliation-page .account_select_bank__control{border-radius: 5px; min-height: 33px;}
.business-section .monthly-bank-reconciliation-page .account_select_bank__control *{font-size: 14px;}
.business-section .monthly-bank-reconciliation-page .account_select_bank__control .account_select_bank__indicator{padding: 0 5px;}
.business-section .monthly-bank-reconciliation-page .account_select_bank__menu *{font-size: 14px;}
.wip-label{position: fixed !important; bottom: 0; background: #ffa456; color: #FFF; z-index: 3; left: 0; padding: 10px; width: 100%; text-align: center; font-size: 25px;}
.business-section .monthly-expand-table{padding: 0px;}
.business-section .monthly-expand-table .react-bootstrap-table table thead tr th:first-child{border-top-left-radius: 0px;}
.business-section .monthly-expand-table .react-bootstrap-table table thead tr th:last-child{border-top-right-radius: 0px;}
.business-section .monthly-expand-table .react-bootstrap-table table tbody tr:last-child td:first-child{border-bottom-left-radius: 0px;}
.business-section .monthly-expand-table .react-bootstrap-table table tbody tr:last-child td:last-child{border-bottom-right-radius: 0px;}
.business-section .monthly-expand-table .react-bootstrap-table table{margin-bottom: 5px;}
.business-section .monthly-expand-table .table-pagination{padding: 10px 20px;}

/* Styling for MultiSelection in Accounting Firm Start */
.pl-report-body .multiSelect-body-pl-report{display: flex; align-items: center;}
.pl-report-body .multiSelect-pl-report .p-multiselect.p-inputwrapper{width: 120px; font-size: 12px; margin-left: 12px; margin-right: 12px;}
.p-multiselect{background: #ffffff; border: 1px solid #ced4da; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; border-radius: 6px; box-sizing: border-box; font-size: 14px; list-style: none; margin: 0; min-width: 0; padding: 0 !important; position: relative; width: 100%; border-radius: 5px;}
.p-multiselect .p-multiselect-label.p-placeholder{color: #6c757d;}
.p-multiselect .p-multiselect-label{padding: 0.45rem 0.75rem; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;}
.p-multiselect .p-multiselect-trigger{background: transparent; color: #6c757d; width: 3rem; border-top-right-radius: 6px; border-bottom-right-radius: 6px;}
.p-icon{width: 1rem; height: 1rem;}
.p-checkbox{width: 22px; height: 22px;}
.p-multiselect-panel{z-index: 2000 !important; background: #ffffff; color: #495057; border: 0 none; border-radius: 6px; box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);}
.p-multiselect-panel .p-multiselect-header{padding: 0.75rem 1.25rem; border-bottom: 1px solid #dee2e6; color: #343a40; background: #f8f9fa; margin: 0; border-top-right-radius: 6px; border-top-left-radius: 6px;}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext{padding-right: 1.75rem;}
.p-inputtext{font-family: inherit; font-feature-settings: inherit; font-size: 12px; color: #495057; background: #ffffff; padding: 0.75rem 0.75rem; border: 1px solid #ced4da; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s; appearance: none !important; border-radius: 6px;}
.p-checkbox .p-checkbox-box{border: 2px solid #ced4da; background: #ffffff; width: 22px; height: 22px; color: #495057; border-radius: 6px; transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;}
.p-multiselect-panel .p-multiselect-items{padding: 0.75rem 0;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox{margin-right: 0.5rem;}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container{margin: 0 0.5rem;}
.p-checkbox .p-checkbox-box .p-checkbox-icon{transition-duration: 0.2s; color: #ffffff; font-size: 14px;}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon{right: 0.75rem; color: #6c757d;}
.p-multiselect-close{padding: 6px; color: white;}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item{margin: 0; padding: 0.75rem 1.25rem; border: 0 none; color: #495057; background: transparent; transition: box-shadow 0.2s; border-radius: 0;}
.p-multiselect-items-wrapper .p-multiselect-item span{font-size: 12px;}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{border-color: var(--primary-color);}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{background-color: var(--primary-color);}
.p-checkbox .p-checkbox-box.p-highlight{border-color: var(--primary-color); background-color: var(--primary-color);}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{background-color: var(--offwhite-color); color: var(--black-color);}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus{box-shadow: inset 0 0 0 0.15rem var(--offwhite-color);}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{background-color: var(--offwhite-color);}
svg.p-icon{pointer-events: unset;}
.p-multiselect-footer .multiSelect-button{font-size: 14px; text-transform: uppercase; height: 33px; line-height: 0; margin: 10px 6px;}
.p-multiselect-footer hr{margin-top: 10px; margin-bottom: 5px;}
.multiSelect-pl-report.multiSelect-dashboard{display: flex; align-items: center; gap: 8px; margin-left: 10px;}
.multiSelect-pl-report.multiSelect-dashboard .p-multiselect-label, .multiSelect-pl-report.multiSelect-dashboard .p-multiselect-label.p-placeholder{padding: 3px !important;}
.multiSelect-pl-report.multiSelect-dashboard label{margin-bottom: 0px;}
.client-section .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover{border-color: hsla(272, 40%, 25%, 1);}
.client-section .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover{background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
.client-section .p-checkbox .p-checkbox-box.p-highlight{border-color: hsla(272, 40%, 25%, 1); background: hsla(272, 40%, 25%, 1); background: linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -moz-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); background: -webkit-linear-gradient(180deg, hsla(272, 40%, 25%, 1) 0%, hsla(204, 97%, 15%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 ); -webkit-filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#42275A", endColorstr="#012F4E", GradientType=1 );}
/* Styling for MultiSelection in Accounting Firm Start */
.business-section .reconcile-items-table .selection-cell-header{width: 30px;}
.modal-open .modal.reconcile-fix-modal .small-review .modal-body{max-height: inherit; overflow: visible; text-align: left;}
.custom-table.client-list-table table thead th.expand-cell-header{width: 50px; font-size: 14px; color: black; pointer-events: all; padding: 11px 20px;}

/* Minimize the Software font and space */
.reconcilation-screen{margin: 0; padding: 15px 0; background: none; border-radius: 0;}
.reconcilation-screen .firm-details{width: 100%;}
.reconcilation-screen .firm-details .firm-summary{padding: 10px; margin-bottom: 15px;}
.reconcilation-screen.content-details .table-top-btn + div.tab-table{padding: 0; border-top-left-radius: 0;}
.reconcilation-screen.content-details .content-details.refund-process{border-top-left-radius: 0; padding: 0;}
.refund-process .refund-process-box{border-radius: 10px; padding: 10px; margin-bottom: 10px;}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content{padding: 10px;}
.refund-process .refund-process-box .refund-card-box .col-6 .refund-card .refund-card-content p, .refund-process .refund-process-box .refund-card-box .refund-card h6{font-size: 14px;}
body form .form-control{border-radius: 10px;}
.firm-details .firm-summary .account-wrapper{padding: 10px;}
.firm-details .firm-summary h5, .firm-details .firm-summary h6{font-size: 20px;}
body .full-content-block .page-title{font-size: 16px;}
.subscription-notfound{display: flex; align-items: center; flex-direction: column; height: 80vh; justify-content: center;}
.subscription-notfound h3{background: url(../images/lock.gif) no-repeat center top; padding: 170px 0 0;}
.subscription-notfound.client-subscription-notfound h3{background: url(../images/business-lock.gif) no-repeat center top; padding: 170px 0 0;}
.business-section .content-details.bas-content .summary-filter .bas-export-btn{background: none; height: 40px;}
.business-section .content-details.bas-content .summary-filter .bas-export-btn button{padding: 9px 20px; margin-right: 10px;}
@media (max-width: 1500px){
  .content-details.bas-content .summary-filter {
    margin-bottom: -30px !important;
    margin-top: 0px !important;
    float: none !important;
  }
}

.business-section .reallocate-btn{
  padding: 6px 16px !important;
  margin-left: 20px !important;
  background: var(--white-color) !important;
  color: var(--businessprimary-color) !important;
  border-color: var(--businessprimary-color) !important;
}

.business-section .reallocate-btn:hover{
  padding: 6px 16px !important;
  margin-left: 20px !important;
  color: var(--white-color) !important;
  background: var(--businessprimary-color) !important;
}

.business-section .list-subHeading {
  color: var(--black-color) !important;
  font-weight: 600;
  font-size: 14px;
}

.content-details.global-search{
  width: auto;
  background: none;
  padding: 0px;
  border-radius: 0px;
  /* margin: 0 10px 0 auto !important; */
}
.content-details.global-search .ant-select-selector{border-radius: 5px !important;}
.content-details.global-search .search-section{
  gap: 5px;
  background: rgb(207 203 203 / 50%);
  padding: 5px;
  border-radius: 5px;
}

.content-details.global-search .search-section form input{
  /* min-width: 460px !important; */
  min-width: auto !important;
}

.content-details.global-search .search-section .search-bar .ant-select .ant-select-selector,
.content-details.global-search .search-section .search-bar .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 10px !important;
}

.content-details.global-search .search-section .rs-picker-toggle{
  padding: 5px 10px !important;
}

/* .content-details.global-search .search-section{
  margin-right: 70px;
} */

.page-title.common-page-title{
  justify-content: space-between;
}

body .full-content-block .page-title.common-page-title div:last-child {
  margin-left: 0px;
}

.business-section .reconcile-view{
  padding: 10px;
  display: flex;
}

.business-section .reconcile-view.view-active{
  background: var(--businesssecondary-color);
  color: var(--white-color);
}

.business-section .journal-table label, .business-section .new-transaction-modal label{
  font-weight: 500;
}

.business-section .journal-date.new-transaction-modal{
  height: 72px;
}

.business-section .journal-date.new-transaction-modal label{
  font-weight: 500;
} 
.business-section .journal-date.new-transaction-modal .rs-picker-toggle{
  padding: 11px;
}
.business-section .reallocate-btn{padding: 6px 16px !important; margin-left: 20px !important; background: var(--white-color) !important; color: var(--businessprimary-color) !important; border-color: var(--businessprimary-color) !important;}
.business-section .reallocate-btn:hover{padding: 6px 16px !important; margin-left: 20px !important; color: var(--white-color) !important; background: var(--businessprimary-color) !important;}
.business-section .list-subHeading{color: var(--black-color) !important; font-weight: 600; font-size: 14px;}
.business-section .reconcile-view{padding: 5px; display: flex;}
.business-section .reconcile-view.view-active{background: var(--businessprimary-color); color: var(--white-color);}
.business-section .journal-table label, .business-section .new-transaction-modal label{font-weight: 500;}
.business-section .journal-date.new-transaction-modal{height: 72px;}
.business-section .journal-date.new-transaction-modal label{font-weight: 500;} 
.business-section .journal-date.new-transaction-modal .rs-picker-toggle{padding: 11px;}
.journal-table thead tr th:last-child, .journal-table tbody tr td:last-child{border-right: none;}
.journal-table thead tr th:first-child, .journal-table tbody tr td:first-child{border-left: none !important;}
.journal-table tbody tr:last-child td{border-bottom: none;}
.rs-picker-toggle-clean.rs-btn-close{padding: 0 !important; border: none !important; background: none !important; height: auto !important;}
.business-section .journal-modal{width: 80%;}
.business-section .journal-modal .journal-header-modal-content{display: flex; align-items: center; gap: 20px; padding: 0px 10px;}
.business-section .journal-modal .journal-header-modal-content p{font-size: 16px;}
.business-section .journal-modal .journal-header-modal-content p span{font-weight: 500;}
.business-section .journal-modal .edit-box{clear: both; display: flex; align-items: flex-end; justify-content: flex-end;}
@media(min-width: 992px){
  body header .header-nav li .dropdown-toggle:after{transform: translateY(-50%);}
}
.invoice-date-body .rs-stack, .invoice-title-body .rs-stack{min-width: auto;}
.number-disable:disabled{background-color: rgba(239, 239, 239, 0.3); background: rgba(239, 239, 239, 0.3);}
.invoice-detail-body input:disabled{background-color: rgba(239, 239, 239, 0.3); background: rgba(239, 239, 239, 0.3);}
.invoice-detail-body .selectbox--is-disabled .selectbox__control{background-color: rgba(239, 239, 239, 0.3); background: rgba(239, 239, 239, 0.3);}
.invoice-detail-body .public-DraftEditor-content:has(.public-DraftEditor-content[contenteditable='false']) .editor-class.rdw-editor-main{background-color: rgba(239, 239, 239, 0.3); background: rgba(239, 239, 239, 0.3);}
.invoice-detail-body .selectbox__single-value--is-disabled{color: rgba(0, 0, 0, 0.85);}
.invoice-detail-body .selectbox__menu{z-index: 3000;}
.standard-paragrah-modal table tr td:last-child{white-space: normal !important;}
.standard-paragrah-modal table tr td:first-child{width: 10%;}
.business-section .invoice-detail-body .global-gst-unit-price{font-size: 12px; margin: 0px;}
.business-section .invoice-detail-body .DraftEditor-root{height: auto;}
.invoice-detail-body .receipt-table .form-group .selectbox__control{min-width: 225px;}
.login-form-block .content-details .entry-card{padding: 15px; border-radius: 10px; background: #FFF;}
.login-form-block .content-details .entry-card h3{border: none; border-bottom: var(--primary-color) 2px solid; padding: 0 0 5px; color: var(--primary-color);}
.login-form-block .content-details .entry-card .form-box .half-width{width: calc(50% - 5px);}
.login-form-block .table-top-btn{display: none;}

.excel-journalentry-modal .full-popup .excel-table-list .react-bootstrap-table{
  max-height: 500px !important;
}

.excel-journalentry-modal .full-popup .excel-table-list .react-bootstrap-table tbody td:last-child,
.excel-journalentry-modal .full-popup .excel-table-list .react-bootstrap-table thead th:last-child {
  text-align: center;
}

.invoice-detail-body .selectbox__value-container{
  white-space: nowrap;
}

.business-section .form-group .p-multiselect{
  border-radius: 5px;
}

@media (min-width: 992px) {
  .credit-note-modal{
    width: 80%;
  }
}
.setting-color-picker {
  display: flex;
  align-items: center;
  gap: 10px;
}

.setting-color-picker label{
  margin-bottom: 0px;
}

.invoice-detail-body .form-group.row{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-group.color-picker-box .selectbox__control{
  max-width: 200px;
}

.color-picker-box label{
  flex-direction: column;
  align-items: normal !important;
  gap: 4px;
}

.color-picker-box label span{
  font-size: 12px;
  letter-spacing: 1px;
}

/* Switch Toggle CSS */
.switch-body{
  text-align: center;
}

.toggle-switch {
  position: relative;
  width: 60px;
  display: inline-block;
  text-align: left;
}
.switch-checkbox {
  display: none;
}
.switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  margin-bottom: 0px;
}
.switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.switch-inner:before,
.switch-inner:after {
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 25px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.switch-inner:before {
  content: "\f00c";
  padding-left: 10px;
  background-color: var(--primary-color);
  color: #fff;
  font-family: 'FontAwesome';
}
.switch-inner:after {
  content: "\f00d";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
  font-family: 'FontAwesome';
}
.switch {
  display: block;
  width: 20px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.switch-checkbox:checked + .switch-label .switch-inner {
  margin-left: 0;
}
.switch-checkbox:checked + .switch-label .switch {
  right: 0px;
}
.btn-reset{background: var(--white-color) !important; color: var(--black-color) !important;}
.___SCalendarUnit_13vuy_gg_, .___SWeekDay_13vuy_gg_{font-size: 12px !important;}
div[data-ui-name="MonthRangePicker.Popper"] *{font-size: 12px !important; grid-row-gap: 5px;}

.payment-gateway-card {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid grey;
  padding: 8px 15px;
}

.payment-gateway-body {
  display: flex;
    gap: 15px;
}

.payment-gateway-card img{
  box-shadow: 0px 0px 24px #00000029;
}

.search-source{
  padding: 5px 11px;
  background: rgba(0, 69, 116, 0.4);
  color: white;
}

.search-source.amount-credit{
  background: #0DA500;
}

.search-source.amount-debit{
  background: #f00;
}


.ant-select-item-option-content{display: flex; align-items: center;}
.ant-select-item-option-content span.search-desc{display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; -webkit-line-clamp: 1; display: inline-block; line-height: 1.4; padding-right: 0; text-overflow: ellipsis;}
.ant-select-item-option-content span.search-desc + .search-source{position: relative; right: 0; border-radius: 5px; line-height: 1; right: 0; margin-left: auto; box-sizing: border-box;}
.page-title + .content-details > .table-top-btn + .custom-table.tab-table{border-top: #F0F0F0 1px solid; padding: 10px 0 0 !important; margin: 0 0 0; border-radius: 0; background: #F0F0F0; border-radius: 0 10px 10px 10px; padding: 10px !important;}
/* .page-title + .content-details > .table-top-btn + .custom-table.tab-table .table-top-btn{margin-bottom: 0;} */
.page-title + .content-details > .table-top-btn + .custom-table.tab-table .table{border-radius: 0 10px 10px 10px; border: none;}
.reconcilation-screen.content-details .table-top-btn + div.tab-table{margin: 0; background: none;}
.business-section .reconcilation-screen.content-details .table-top-btn + div.tab-table .react-bootstrap-table table thead th{background: var(--businesssecondary-color); color: var(--white-color);}
.business-section .reconcilation-screen.content-details .table-top-btn + div.tab-table .react-bootstrap-table table thead th:first-child{border-radius: 0; border-left: none;}
.content-details .table-top-btn + div.tab-table .full-content-block{background: var(--white-color); border: #ECECEC 1px solid; margin: 0; padding: 10px; border-radius: 0 10px 10px 10px;}
.content-details .table-top-btn + div.tab-table .full-content-block .content-details{padding: 0; margin: 0;}

.content-details .table-top-btn:has(.nav.nav-tabs) {
  margin-bottom: 0px;
}

.business-status-graph g.recharts-layer.recharts-bar-rectangle {cursor: pointer;}